import React, { useState, useEffect } from "react";
import { Modal, Col, Card, Row, Form } from "react-bootstrap";
import CreateInspiration from "../../../components/dashboard/customer-dashboard/Inspiration/createinspiration";
import { toast } from "react-toastify";
import { CircularLoading } from "../../../components/loading/CircularLoading";
import {
  getUsersInspirationBoard,
  createInspirationBoard,
} from "../../../services/customer/Inspirationboard";
// import { EmptyBoard } from "../../../components/dashboard/customer-dashboard/Inspiration/EmptyBoard";
import { Header } from "../../../components/dashboard/customer-dashboard/Inspiration/Header";
import { PlusIcon } from "../../../components/dashboard/customer-dashboard/Inspiration/PlusIcon";
import { EditModalBoard } from "../../../components/dashboard/customer-dashboard/Inspiration/EditModalBoard";
import { DeleteModal } from "../../../components/dashboard/customer-dashboard/Inspiration/DeleteModal";
import { BaseContainer } from "../../../components/dashboard/Containers";

const InspirationBoard = (props) => {
  // const state = {
  //   name: "",
  //   // images: "",
  // };
  const [inspirationBoards, setInspirationBoards] = useState([]);
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [options] = useState({
    page: 1,
    limit: 0,
  });

  const [show, setShow] = useState(false);
  const initValues = {
    name: "",
    id: "",
    show: false,
  };
  const [edit, setEdit] = useState(initValues);
  const [toDelete, setDelete] = useState(initValues);

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!name) {
      toast.error("Please enter a name");
      setIsSubmitting(false);
      return;
    } else {
      try {
        const response = await createInspirationBoard(name);
        if (response?.data?.success) {
          setInspirationBoards([...inspirationBoards, response.data.data]);
          toast.success(response?.data?.message);
          setShow(false);
          setName("");
        } else {
          toast.error(response);
          setShow(false);
          setName("");
        }
      } catch (error) {
        toast.error(error);
      }
      setIsSubmitting(false);
    }
  };

  const getInspirations = async () => {
    // setLoading("loading");
    try {
      const response = await getUsersInspirationBoard(
        options.page,
        options.limit
      );
      // setLoading("success");
      const { results } = response.data?.data;
      setInspirationBoards(results);
    } catch (e) {
      // setLoading("failed");
      toast.error(e);
    }
  };

  useEffect(() => {
    getUsersInspirationBoard(options.page, options.limit)
      .then((response) => {
        const { results } = response.data?.data;
        setInspirationBoards(results);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e);
      });
  }, [options]);

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <Header title="Inspiration Boards" />
        <div className="VCDiv vh-85 y-scroll px-0 px-md-4">
          <CreateInspiration
            inspirations={inspirationBoards}
            edit={edit}
            setEdit={setEdit}
            toDelete={toDelete}
            setDelete={setDelete}
            isLoading={isLoading}
          />

          <PlusIcon onClick={handleShow} />

          {/* modal */}
          <Modal
            show={show}
            onHide={() => setShow(false)}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="small-modal-2"
          >
            <Card className="py-4 px-3 border-0">
              <Card.Body>
                <div className="text-center">
                  <h5 className="mb-3">
                    <b>Create New Board</b>
                  </h5>
                  <p className="text-muted text-small">
                    Name your board and save it to keep track of inspirations
                  </p>
                </div>
                <Form>
                  <Form.Group
                    className="mt-4"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      <b>Name</b>
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter Board Name"
                      aria-label="Name"
                      className="p-3 react-textarea"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Row>
                    <Col className="text-center" xs={6}>
                      <button
                        className="btn text-theme border-theme p-3 mt-4 w-100"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col className="text-center" xs={6}>
                      <button
                        className="btn btn-theme w-100 p-3 mt-4 font-weight-bold"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <CircularLoading /> : "Create Board"}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Modal>
          <EditModalBoard
            edit={edit}
            handleClose={(e) => {
              e.preventDefault();
              setEdit({ ...edit, ...initValues });
            }}
            handleClose2={(e) => setEdit({ ...edit, ...initValues })}
            load={getInspirations}
          />
          <DeleteModal
            toDelete={toDelete}
            handleClose={() => setDelete({ ...toDelete, ...initValues })}
            load={getInspirations}
          />
        </div>
      </Col>
    </BaseContainer>
  );
};

export default InspirationBoard;
