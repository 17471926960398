import React, { useEffect, useState } from "react";
import { Col, Row, Container, Dropdown, Card } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { MdFavorite } from "react-icons/md";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Api } from "../../../../axiosInstance";
import { fetchVendorsProfile } from "../../../../services/customer/Inspirationboard";
import { DefaultProfileImage } from "../../../common/DefaultProfileImage";
import { EnquiryModal } from "../Inspiration/Enquiry";
import { Header } from "../Inspiration/Header";
import { CarouselModal } from "./CarouselModal";
import { BaseContainer } from "../../Containers";

export const VendorProfile = () => {
  // const [loading, setLoading] = useState("idle");
  const [favorites, setFavorites] = useState([]);
  const [vendorsProfile, setVendorsProfile] = useState([]);
  const [show, setShow] = useState(false);
  const [carousel, setCarousel] = useState(false);
  const [projectToShow, setProjectToShow] = useState([]);
  // const [showProjects, setShowProjects] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const favoriteFn = async () => {
    const isFavorite = [...favorites].filter((f) => f._id === id).length > 0;
    const whichAction = isFavorite ? "unfavorite" : "favorite";
    const response = await Api.put(`/vendors/${whichAction}`, { vendorId: id });
    if (!response?.data) return;
    isFavorite
      ? setFavorites([...favorites.filter((f) => f._id !== id)])
      : setFavorites([...favorites, vendorsProfile.vendor]);
    // const { data } = response.data;
  };

  //useEffect to load favorites and vendorProfile
  useEffect(() => {
    //API to call already favorites endpoint
    const getFavoritesFn = async () => {
      const response = await Api.get("/vendors/favorites");
      if (!response?.data) return;
      const { results } = response.data.data;
      setFavorites(results);
    };

    //API to fetch vendorsProfile........//
    const getVendorProfile = async () => {
      // setLoading("loading");
      try {
        const response = await fetchVendorsProfile(id);
        // setLoading("success");
        const { data } = response.data;
        setVendorsProfile(data);
      } catch (e) {
        // setLoading("failed");
      }
    };
    // console.log(vendorsProfile);

    getVendorProfile();
    getFavoritesFn();
  }, [id]);

  const CustomToggle = React.forwardRef((props, ref) => (
    <b
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      className="float-right cursor-pointer weird-margin"
    >
      {props.children}
    </b>
  ));

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <Header title="Vendor Profile" className="d-none d-md-flex" />
        <div className="VCDiv vh-85 vh-xs-100 p-md-3 y-scroll p-0">
          <Container fluid>
            <Row className="g-4">
              <div className="rounded bg-white py-4 w-100 d-none d-md-block">
                <div className="d-flex justify-content-between">
                  <div className="d-block d-lg-flex">
                    <div
                      className="me-0 mb-3 mb-lg-0 me-lg-3"
                      style={{
                        height: "220px",
                        width: "220px",
                      }}
                    >
                      {!vendorsProfile?.vendor?.profileImage ? (
                        <DefaultProfileImage
                          fullName={vendorsProfile?.vendor?.fullName}
                        />
                      ) : (
                        <img
                          src={vendorsProfile?.vendor?.profileImage || ""}
                          className="rounded w-100 h-100 img-fluid"
                          alt="vendorProfile"
                        />
                      )}
                    </div>

                    <div className="px-0 px-lg-3 d-flex justify-content-center flex-column">
                      <h5 className="font-weight-bold poppins-font my-0">
                        {vendorsProfile?.vendor?.fullName}
                      </h5>{" "}
                      <div className="budgetLocation">
                        <p className="text3 my-1">
                          Location :{" "}
                          <span>{vendorsProfile?.vendor?.location}</span>{" "}
                        </p>{" "}
                        <p className="text3 my-1">
                          Min Budget:{" "}
                          <span>{vendorsProfile?.vendor?.budget}</span>{" "}
                        </p>{" "}
                      </div>{" "}
                      <div
                        className="border border-warning d-inline-block mt-2"
                        style={{
                          letterSpacing: "1px",
                          borderRadius: "15px",
                          width: "fit-content",
                        }}
                      >
                        <div
                          className="d-flex text-center px-2 align-items-center justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          <AiFillStar
                            style={{ color: "gold", verticalAlign: "initial" }}
                          />
                          &nbsp;
                          {vendorsProfile?.vendor?.rating
                            ? vendorsProfile?.vendor?.rating.averageRate
                            : "0.0"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{ height: "fit-content", marginTop: "2.5rem" }}
                  >
                    <button
                      className="btn btn-theme text-small font-weight-bold border-0"
                      onClick={() => setShow(true)}
                    >
                      Make Enquiry{" "}
                    </button>
                    <div
                      onClick={favoriteFn}
                      className="d-inline-flex justify-content-center align-items-center ms-2"
                      style={{
                        backgroundColor: "#F4F4F4",
                        height: "50px",
                        padding: "5px",
                        color: "#D5D5D5",
                        width: "50px",
                        cursor: "pointer",
                        borderRadius: "5px",
                        display: "inline-block",
                      }}
                    >
                      {[...favorites].filter((f) => f._id === id).length > 0 ? (
                        <MdFavorite size={30} color="#E00253" />
                      ) : (
                        <MdFavorite size={30} />
                      )}
                    </div>
                  </div>

                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                      split
                      className="cursor-pointer"
                    >
                      &#xFE19;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="1">
                        <Link to="/settings">Report User</Link>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="2">
                        <Link to={`/messages/${id}`}>Message Vendor</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "400px",
                }}
                className="d-md-none p-0"
              >
                {!vendorsProfile?.vendor?.profileImage ? (
                  <DefaultProfileImage
                    className="border-radius-0"
                    fullName={vendorsProfile?.vendor?.fullName}
                  />
                ) : (
                  <img
                    src={vendorsProfile?.vendor?.profileImage || ""}
                    className="w-100 h-100"
                    alt="vendorProfile"
                  />
                )}

                <div
                  className="d-flex justify-content-between align-items-center p-3 position-sticky w-100"
                  style={{ marginTop: "-400px" }}
                >
                  <i
                    className="iconly-Arrow-Left-Circle icli caret-holder cursor-pointer"
                    style={{ fontSize: "30px" }}
                    onClick={() => navigate(-1)}
                  ></i>

                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                      split
                      className="cursor-pointer"
                    >
                      &#xFE19;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="1">
                        <Link to="/settings">Report User</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div
                  className="d-flex text-center px-2 align-items-center justify-content-center border border-warning position-sticky"
                  style={{
                    fontSize: "12px",
                    letterSpacing: "1px",
                    borderRadius: "15px",
                    width: "fit-content",
                    top: "350px",
                    float: "right",
                    marginRight: "20px",
                  }}
                >
                  <AiFillStar
                    style={{ color: "gold", verticalAlign: "initial" }}
                  />
                  &nbsp;
                  {vendorsProfile?.vendor?.rating
                    ? vendorsProfile?.vendor?.rating.averageRate
                    : "0.0"}
                </div>
              </div>

              <div className="bg-white p-4 m-0 d-flex justify-content-between d-md-none">
                <div>
                  <h5 className="font-weight-bold poppins-font my-0">
                    {vendorsProfile?.vendor?.fullName}
                  </h5>
                  <div className="budgetLocation">
                    <p className="text3 my-1">
                      Location : <span>{vendorsProfile?.vendor?.location}</span>
                    </p>
                    <p className="text3 my-1">
                      Min Budget: <span>{vendorsProfile?.vendor?.budget}</span>
                    </p>
                  </div>
                </div>

                <div className="d-flex" style={{ height: "fit-content" }}>
                  <button
                    className="btn btn-theme text-small font-weight-bold border-0"
                    onClick={() => setShow(true)}
                  >
                    Make Enquiry
                  </button>
                  <div
                    onClick={favoriteFn}
                    className="d-inline-flex justify-content-center align-items-center ms-2"
                    style={{
                      backgroundColor: "#F4F4F4",
                      height: "50px",
                      padding: "5px",
                      color: "#D5D5D5",
                      width: "50px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      display: "inline-block",
                    }}
                  >
                    {[...favorites].filter((f) => f._id === id).length > 0 ? (
                      <MdFavorite size={30} color="#E00253" />
                    ) : (
                      <MdFavorite size={30} />
                    )}
                  </div>
                </div>
              </div>

              <Col className="rounded bg-white py-5 px-4" sm={12}>
                <h6 className="font-weight-bold mb-3">About</h6>
                <div className="lato-font">
                  {vendorsProfile?.vendor?.about || "No About specified"}
                </div>
              </Col>

              <div className="grid-2 grid-sm-1 p-0">
                <Card className="border-0 p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <b>Projects</b>
                    {/* <span className="text-danger cursor-pointer">View All</span> */}
                  </div>
                  <div className="grid-4 grid-md-2">
                    {vendorsProfile.projects
                      ? vendorsProfile.projects.map((project, index) => (
                          <div key={index}>
                            <img
                              src={
                                project.images.length !== 0
                                  ? project.images[0]
                                  : "https://via.placeholder.com/100"
                              }
                              alt="project"
                              width="100%"
                              height="100px"
                              className="rounded cursor-pointer small-project-image"
                              onClick={() => {
                                if (project.images.length !== 0) {
                                  setProjectToShow(project);
                                  setCarousel(true);
                                }
                              }}
                            />
                            <b className="text-small text-capitalize">
                              {project.name}
                            </b>
                          </div>
                        ))
                      : null}
                  </div>
                </Card>

                <Card className="border-0 p-4">
                  <b className="mb-4">Services Offered</b>
                  <div className="grid-4 grid-md-2">
                    {vendorsProfile.services
                      ? vendorsProfile.services.map((service, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center justify-content-center rounded small-project-image"
                            style={{
                              height: "100px",
                              width: "100%",
                              backgroundImage: `url(${
                                service.coverImage ||
                                "https://via.placeholder.com/100"
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center text-center rounded small-project-image"
                              style={{
                                height: "100px",
                                width: "100%",
                                backgroundColor: "rgba(0,0,0,0.5)",
                                color: "white",
                                fontWeight: "bold",
                                padding: "10px",
                              }}
                            >
                              {service.name}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
        <EnquiryModal
          show={show}
          id={id}
          handleClose={() => setShow(false)}
          vendorName={vendorsProfile?.vendor?.fullName}
        />
        <CarouselModal
          show={carousel}
          id={id}
          projectToShow={projectToShow}
          vendorName={vendorsProfile?.vendor?.fullName}
          handleClose={() => setCarousel(false)}
        />
      </Col>
    </BaseContainer>
  );
};
