import React from "react";

const InitialsColors = {
  abcde: "#83f555",
  fghijk: "#23ab23",
  lmnop: "#42524b",
  qrstu: "#f0524b",
  vwxyz: "#a3524b",
};

export const DefaultProfileImage = ({ fullName, className }) => {
  fullName += " ";
  const name = fullName.split(" ");
  const firstNameInitials = name[0][0] ? name[0][0].toUpperCase() : "";
  const lastNameInitials = name[1][0] ? name[1][0].toUpperCase() : "";
  const initials = firstNameInitials + lastNameInitials;
  // get the key that contains the initials(lowercase)
  const match = Object.keys(InitialsColors).find((element) =>
    element.includes(firstNameInitials?.toLowerCase() || "a")
  );
  // get the color from the key
  // use.
  return (
    <div
      className={
        className + " d-flex justify-content-center align-items-center rounded"
      }
      style={{
        backgroundColor: InitialsColors[match],
        width: "100%",
        height: "100%",
      }}
    >
      {fullName && (
        <h2 style={{ color: "white", margin: 0 }}>
          <b className="poppins-font">{initials}</b>
        </h2>
      )}
    </div>
  );
};
