import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import FirstImage from "../../../../assets/layout/pexels-avonne-stalling-3916019.png";
// import SecondImage from "../../../../assets/layout/decor.jpg";

export const HomeInspirationShow = ({ inspirationToShow }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      style={{ padding: "30px" }}
    >
      {inspirationToShow &&
        inspirationToShow.images?.map((projectImage) => (
          <Carousel.Item>
            <img
              src={projectImage}
              alt="FirstSlide"
              width="750"
              height="600"
              style={{ objectFit: "cover" }}
            />
            <Carousel.Caption>
              {/* <h3>{inspirationToShow.name}</h3> */}
              {/* <p>Alans Projects by vendors.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};
