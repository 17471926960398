import React from "react";
export const EmptyBoard = ({ header, footer }) => {
  return (
    <>
      <h6 className="text-center mt-5 fw-bold">{header}</h6>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        width="279.927"
        height="274.552"
        viewBox="0 0 279.927 274.552"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#ef9c9c" />
            <stop offset="1" stopColor="#df4f71" />
          </linearGradient>
        </defs>
        <g
          id="Group_13903"
          data-name="Group 13903"
          transform="translate(-31 -197)"
        >
          <g id="Group_17" data-name="Group 17" transform="translate(31 197)">
            <g id="Group_12" data-name="Group 12">
              <path
                id="Subtraction_1"
                data-name="Subtraction 1"
                d="M183.905,201.82H21.874a9.174,9.174,0,0,1,0-18.347h62.89a9.241,9.241,0,0,1-1.834-.184,9.175,9.175,0,0,0,0-17.98,9.24,9.24,0,0,1,1.837-.184H42.933a9.173,9.173,0,1,1,0-18.346H99.442a9.174,9.174,0,0,0,0-18.347H9.174a9.174,9.174,0,1,1,0-18.347H38.96a9.174,9.174,0,0,0,0-18.347H29.723a9.174,9.174,0,1,1,0-18.347h21.17a9.173,9.173,0,1,0,0-18.346H10.968a9.173,9.173,0,1,1,0-18.347H67.884a9.174,9.174,0,0,0,0-18.347H56.143A9.174,9.174,0,0,1,56.143,0H217.6a9.174,9.174,0,1,1,0,18.347H175.033a9.174,9.174,0,0,0,0,18.347h61.124a9.173,9.173,0,1,1,0,18.347H191.912a9.173,9.173,0,1,0,0,18.346h28.622a9.174,9.174,0,0,1,0,18.347h-20.55a9.245,9.245,0,0,1,1.835.184,9.176,9.176,0,0,0,0,17.98,9.244,9.244,0,0,1-1.835.184h23.485a9.174,9.174,0,0,1,0,18.347H191.912a9.174,9.174,0,0,0,0,18.347h5.871a9.173,9.173,0,1,1,0,18.346h-45.5a9.173,9.173,0,1,0,0,18.346h31.623a9.174,9.174,0,0,1,0,18.347Z"
                transform="translate(22.694 36.599)"
                fill="#f9f9f9"
              />
              <rect
                id="Rectangle_22"
                data-name="Rectangle 22"
                width="33.145"
                height="18.346"
                rx="9.173"
                transform="translate(115.561 256.206)"
                fill="#f9f9f9"
              />
              <rect
                id="Rectangle_24"
                data-name="Rectangle 24"
                width="66.291"
                height="18.346"
                rx="9.173"
                transform="translate(141.54)"
                fill="#f9f9f9"
              />
              <circle
                id="Ellipse_7"
                data-name="Ellipse 7"
                cx="9.173"
                cy="9.173"
                r="9.173"
                transform="translate(261.581 146.019)"
                fill="#f9f9f9"
              />
              <circle
                id="Ellipse_9"
                data-name="Ellipse 9"
                cx="9.173"
                cy="9.173"
                r="9.173"
                transform="translate(0 73.458)"
                fill="#f9f9f9"
              />
              <g
                id="Group_6"
                data-name="Group 6"
                transform="translate(237.264 102.946) rotate(45)"
              >
                <line
                  id="Line_1"
                  data-name="Line 1"
                  y2="8.789"
                  transform="translate(4.395 0)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_2"
                  data-name="Line 2"
                  y2="8.789"
                  transform="translate(8.789 4.395) rotate(90)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
              <g
                id="Group_11"
                data-name="Group 11"
                transform="translate(253.388 184.466) rotate(45)"
              >
                <line
                  id="Line_1-2"
                  data-name="Line 1"
                  y2="8.789"
                  transform="translate(4.395 0)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_2-2"
                  data-name="Line 2"
                  y2="8.789"
                  transform="translate(8.789 4.395) rotate(90)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
              <g
                id="Group_7"
                data-name="Group 7"
                transform="translate(102.89 30.384) rotate(45)"
              >
                <line
                  id="Line_1-3"
                  data-name="Line 1"
                  y2="8.789"
                  transform="translate(4.395 0)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_2-3"
                  data-name="Line 2"
                  y2="8.789"
                  transform="translate(8.789 4.395) rotate(90)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
              <g
                id="Group_8"
                data-name="Group 8"
                transform="translate(30.328 120.862) rotate(45)"
              >
                <line
                  id="Line_1-4"
                  data-name="Line 1"
                  y2="8.789"
                  transform="translate(4.395 0)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_2-4"
                  data-name="Line 2"
                  y2="8.789"
                  transform="translate(8.789 4.395) rotate(90)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
              <g
                id="Group_10"
                data-name="Group 10"
                transform="translate(176.348 241.798) rotate(45)"
              >
                <line
                  id="Line_1-5"
                  data-name="Line 1"
                  y2="8.789"
                  transform="translate(4.395 0)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_2-5"
                  data-name="Line 2"
                  y2="8.789"
                  transform="translate(8.789 4.395) rotate(90)"
                  fill="none"
                  stroke="#95d7f3"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
              <g
                id="Ellipse_2"
                data-name="Ellipse 2"
                transform="translate(179.165 17.021)"
                fill="none"
                stroke="#95d7f3"
                strokeWidth="2"
              >
                <circle cx="3.583" cy="3.583" r="3.583" stroke="none" />
                <circle cx="3.583" cy="3.583" r="2.583" fill="none" />
              </g>
              <g
                id="Ellipse_3"
                data-name="Ellipse 3"
                transform="translate(212.31 154.977)"
                fill="none"
                stroke="#95d7f3"
                strokeWidth="2"
              >
                <circle cx="3.583" cy="3.583" r="3.583" stroke="none" />
                <circle cx="3.583" cy="3.583" r="2.583" fill="none" />
              </g>
              <g
                id="Ellipse_6"
                data-name="Ellipse 6"
                transform="translate(39.416 64.499)"
                fill="none"
                stroke="#95d7f3"
                strokeWidth="2"
              >
                <circle cx="3.583" cy="3.583" r="3.583" stroke="none" />
                <circle cx="3.583" cy="3.583" r="2.583" fill="none" />
              </g>
              <g
                id="Ellipse_4"
                data-name="Ellipse 4"
                transform="translate(64.499 216.789)"
                fill="none"
                stroke="#95d7f3"
                strokeWidth="2"
              >
                <circle cx="3.583" cy="3.583" r="3.583" stroke="none" />
                <circle cx="3.583" cy="3.583" r="2.583" fill="none" />
              </g>
            </g>
            <g
              id="Group_14"
              data-name="Group 14"
              transform="translate(77.937 43)"
            >
              <g id="Group_13" data-name="Group 13">
                <g
                  id="Path_1"
                  data-name="Path 1"
                  transform="translate(0 8.958)"
                  fill="#f3f3f3"
                >
                  <path
                    d="M 120.9361953735352 170.9981384277344 L 4.47913122177124 170.9981384277344 C 2.560739994049072 170.9981384277344 1.000000834465027 169.4374237060547 1.000000834465027 167.5190277099609 L 1.000000834465027 4.47911262512207 C 1.000000834465027 2.560721397399902 2.560739994049072 1.000004053115845 4.47913122177124 1.000004053115845 L 120.9361953735352 1.000004053115845 C 122.8545913696289 1.000004053115845 124.4153060913086 2.560721397399902 124.4153060913086 4.47911262512207 L 124.4153060913086 167.5190277099609 C 124.4153060913086 169.4374237060547 122.8545913696289 170.9981384277344 120.9361953735352 170.9981384277344 Z"
                    stroke="none"
                  />
                  <path
                    d="M 4.479110717773438 2 C 3.112129211425781 2 1.999992370605469 3.112136840820312 1.999992370605469 4.479110717773438 L 1.999992370605469 167.5189971923828 C 1.999992370605469 168.8860015869141 3.112129211425781 169.9981384277344 4.479110717773438 169.9981384277344 L 120.9361724853516 169.9981384277344 C 122.3031768798828 169.9981384277344 123.4153137207031 168.8860015869141 123.4153137207031 167.5189971923828 L 123.4153137207031 4.479110717773438 C 123.4153137207031 3.112136840820312 122.3031768798828 2 120.9361724853516 2 L 4.479110717773438 2 M 4.479110717773438 0 L 120.9361724853516 0 C 123.4099502563477 0 125.4153137207031 2.00537109375 125.4153137207031 4.479110717773438 L 125.4153137207031 167.5189971923828 C 125.4153137207031 169.9927673339844 123.4099502563477 171.9981384277344 120.9361724853516 171.9981384277344 L 4.479110717773438 171.9981384277344 C 2.005355834960938 171.9981384277344 -7.62939453125e-06 169.9927673339844 -7.62939453125e-06 167.5189971923828 L -7.62939453125e-06 4.479110717773438 C -7.62939453125e-06 2.00537109375 2.005355834960938 0 4.479110717773438 0 Z"
                    stroke="none"
                    fill="#a4a4a4"
                  />
                </g>
                <path
                  id="Subtraction_3"
                  data-name="Subtraction 3"
                  d="M116.249,164.831H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H37.624V1.166a6.007,6.007,0,0,0,6,6h31.9a6.007,6.007,0,0,0,6-6V0h34.728a2,2,0,0,1,2,2V119.669a15.7,15.7,0,0,0,0,30.968v12.194A2,2,0,0,1,116.249,164.831Z"
                  transform="translate(3.583 12.541)"
                  fill="#fff"
                />
                <g
                  id="Union_1"
                  data-name="Union 1"
                  transform="translate(43 0)"
                  fill="#fff"
                >
                  <path
                    d="M 35.31240081787109 16.91629981994629 L 5.000400543212891 16.91629981994629 C 2.794570446014404 16.91629981994629 1.000000596046448 15.12212944030762 1.000000596046448 12.91679954528809 L 1.000000596046448 8.58329963684082 C 1.000000596046448 6.377470016479492 2.794570446014404 4.582900047302246 5.000400543212891 4.582900047302246 L 13.64080047607422 4.582900047302246 L 14.38835048675537 4.582900047302246 L 14.59992027282715 3.865909814834595 C 15.0978307723999 2.178509950637817 16.67586135864258 0.9999998807907104 18.43740081787109 0.9999998807907104 L 21.8745002746582 0.9999998807907104 C 23.63603019714355 0.9999998807907104 25.21405982971191 2.178509950637817 25.71197128295898 3.865919828414917 L 25.92354011535645 4.582900047302246 L 26.67109107971191 4.582900047302246 L 35.31240081787109 4.582900047302246 C 37.51773071289062 4.582900047302246 39.3119010925293 6.377470016479492 39.3119010925293 8.58329963684082 L 39.3119010925293 12.91679954528809 C 39.3119010925293 15.12212944030762 37.51773071289062 16.91629981994629 35.31240081787109 16.91629981994629 Z"
                    stroke="none"
                  />
                  <path
                    d="M 35.31240081787109 15.91629981994629 C 36.96633148193359 15.91629981994629 38.3119010925293 14.57073020935059 38.3119010925293 12.91679954528809 L 38.3119010925293 8.58329963684082 C 38.3119010925293 6.928869724273682 36.96633148193359 5.582900047302246 35.31240081787109 5.582900047302246 L 25.17599105834961 5.582900047302246 L 24.75286102294922 4.148930072784424 C 24.3795108795166 2.883669853210449 23.19588088989258 1.99999988079071 21.8745002746582 1.99999988079071 L 18.43740081787109 1.99999988079071 C 17.11600112915039 1.99999988079071 15.93238067626953 2.883669853210449 15.55903053283691 4.148920059204102 L 15.13590049743652 5.582900047302246 L 5.000400543212891 5.582900047302246 C 3.345970630645752 5.582900047302246 2.000000476837158 6.928869724273682 2.000000476837158 8.58329963684082 L 2.000000476837158 12.91679954528809 C 2.000000476837158 14.57073020935059 3.345970630645752 15.91629981994629 5.000400543212891 15.91629981994629 L 35.31240081787109 15.91629981994629 M 35.31240081787109 17.91629981994629 L 5.000400543212891 17.91629981994629 C 2.238300561904907 17.91629981994629 5.462646299747576e-07 15.67799949645996 5.462646299747576e-07 12.91679954528809 L 5.462646299747576e-07 8.58329963684082 C 5.462646299747576e-07 5.822099685668945 2.238300561904907 3.582899808883667 5.000400543212891 3.582899808883667 L 13.64080047607422 3.582899808883667 C 14.25202083587646 1.511489868164062 16.16847991943359 -9.002685885661776e-08 18.43740081787109 -9.002685885661776e-08 L 21.8745002746582 -9.002685885661776e-08 C 24.1434211730957 -9.002685885661776e-08 26.05986022949219 1.511489868164062 26.67109107971191 3.582899808883667 L 35.31240081787109 3.582899808883667 C 38.07360076904297 3.582899808883667 40.3119010925293 5.822099685668945 40.3119010925293 8.58329963684082 L 40.3119010925293 12.91679954528809 C 40.3119010925293 15.67799949645996 38.07360076904297 17.91629981994629 35.31240081787109 17.91629981994629 Z"
                    stroke="none"
                    fill="#a3a3a3"
                  />
                </g>
                <g
                  id="Group_2"
                  data-name="Group 2"
                  transform="translate(27.472 49.406)"
                >
                  <g
                    id="Group_1"
                    data-name="Group 1"
                    transform="translate(0 0)"
                  >
                    <rect
                      id="Rectangle_4"
                      data-name="Rectangle 4"
                      width="11.772"
                      height="11.772"
                      transform="translate(0 0)"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_5"
                      data-name="Rectangle 5"
                      width="55.917"
                      height="4.414"
                      transform="translate(15.451 0)"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_6"
                      data-name="Rectangle 6"
                      width="46.352"
                      height="4.414"
                      transform="translate(15.451 5.886)"
                      fill="#f3f3f3"
                    />
                  </g>
                  <g
                    id="Group_1-2"
                    data-name="Group 1"
                    transform="translate(0 22.072)"
                  >
                    <rect
                      id="Rectangle_4-2"
                      data-name="Rectangle 4"
                      width="11.772"
                      height="11.772"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_5-2"
                      data-name="Rectangle 5"
                      width="55.917"
                      height="4.414"
                      transform="translate(15.451)"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_6-2"
                      data-name="Rectangle 6"
                      width="46.352"
                      height="4.414"
                      transform="translate(15.451 5.886)"
                      fill="#f3f3f3"
                    />
                  </g>
                  <g
                    id="Group_1-3"
                    data-name="Group 1"
                    transform="translate(0 44.145)"
                  >
                    <rect
                      id="Rectangle_4-3"
                      data-name="Rectangle 4"
                      width="11.772"
                      height="11.772"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_5-3"
                      data-name="Rectangle 5"
                      width="55.917"
                      height="4.414"
                      transform="translate(15.451)"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_6-3"
                      data-name="Rectangle 6"
                      width="46.352"
                      height="4.414"
                      transform="translate(15.451 5.886)"
                      fill="#f3f3f3"
                    />
                  </g>
                  <g
                    id="Group_1-4"
                    data-name="Group 1"
                    transform="translate(0 66.217)"
                  >
                    <rect
                      id="Rectangle_4-4"
                      data-name="Rectangle 4"
                      width="11.772"
                      height="11.772"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_5-4"
                      data-name="Rectangle 5"
                      width="55.917"
                      height="4.414"
                      transform="translate(15.451)"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_6-4"
                      data-name="Rectangle 6"
                      width="46.352"
                      height="4.414"
                      transform="translate(15.451 5.886)"
                      fill="#f3f3f3"
                    />
                  </g>
                  <g
                    id="Group_1-5"
                    data-name="Group 1"
                    transform="translate(0 88.289)"
                  >
                    <rect
                      id="Rectangle_4-5"
                      data-name="Rectangle 4"
                      width="11.772"
                      height="11.772"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_5-5"
                      data-name="Rectangle 5"
                      width="55.917"
                      height="4.414"
                      transform="translate(15.451)"
                      fill="#f3f3f3"
                    />
                    <rect
                      id="Rectangle_6-5"
                      data-name="Rectangle 6"
                      width="46.352"
                      height="4.414"
                      transform="translate(15.451 5.886)"
                      fill="#f3f3f3"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Group_4"
                data-name="Group 4"
                transform="translate(104.741 128.033)"
              >
                <circle
                  id="Ellipse_1"
                  data-name="Ellipse 1"
                  cx="13.903"
                  cy="13.903"
                  r="13.903"
                  transform="translate(0 19.662) rotate(-45)"
                  fill="url(#linear-gradient)"
                />
                <g
                  id="Group_3"
                  data-name="Group 3"
                  transform="translate(19.649 13.434) rotate(45)"
                >
                  <line
                    id="Line_1-6"
                    data-name="Line 1"
                    y2="8.789"
                    transform="translate(4.395 0)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="3"
                  />
                  <line
                    id="Line_2-6"
                    data-name="Line 2"
                    y2="8.789"
                    transform="translate(8.789 4.395) rotate(90)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="3"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <p className="text-small text-center text-gray-500 mt-4">{footer}</p>
    </>
  );
};
