import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Modal,
  Carousel,
  Card,
  Row,
  Placeholder,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Api } from "../../../axiosInstance";
import { BaseContainer } from "../../../components/dashboard/Containers";
import { EmptyBoard } from "../../../components/dashboard/customer-dashboard/Inspiration/EmptyBoard";
import { Header } from "../../../components/dashboard/customer-dashboard/Inspiration/Header";
import { PlusIcon } from "../../../components/dashboard/customer-dashboard/Inspiration/PlusIcon";
import { ImCross } from "react-icons/im";
import { CircularLoading } from "../../../components/loading/CircularLoading";
import { removeFromInspirationBoard } from "../../../services/customer/Inspirationboard";

export const Board = () => {
  const { title, id } = useParams();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [index, setIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [inspirations, setInspirations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getInspirationsOnABoardFn = async (id) => {
    try {
      const response = await Api.get(`/boards/${id}`);
      setIsLoading(false);
      const {
        data: { images },
      } = response.data;
      setInspirations(images);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getInspirationsOnABoardFn(id);
  }, [id]);

  const handleClose = () => {
    setShow(false);
    setDeleteModal(false);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const removeImage = async () => {
    setIsSubmitting(true);
    const response = await removeFromInspirationBoard(id, selectedImage);
    if (response.status === 200) {
      setIsSubmitting(false);
      setInspirations(inspirations.filter((img) => img !== selectedImage));
    } else {
      setIsSubmitting(false);
      toast.error("Error deleting image");
    }

    setIsSubmitting(false);
    setDeleteModal(false);
  };

  const imageCarousel = [];
  const handler = () => navigate(`/board/${id}/add/${title}`);

  const placeholderArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const placeholder = placeholderArray.map((item) => {
    return (
      <Placeholder
        as={Card.Title}
        key={item}
        animation="glow"
        className="mb-0 w-100"
        style={{ height: "250px" }}
      >
        <Placeholder xs={12} className="h-100" />
      </Placeholder>
    );
  });

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <Header title={title} />
        <div className="VCDiv vh-85 px-0 px-md-4">
          <Container className="text-center">
            {isLoading ? (
              <div className="grid-5 grid-sm-2 grid-md-3">{placeholder}</div>
            ) : inspirations.length === 0 ? (
              <EmptyBoard
                header="The Board is Empty"
                footer="You haven't added any inspiration here, Add one now"
              />
            ) : (
              <div className="grid-5 grid-sm-2 grid-md-3">
                {inspirations.map((imageItem, index) => {
                  imageCarousel.push(
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100 vh-75 rounded"
                        src={imageItem}
                        alt="slide"
                      />
                    </Carousel.Item>
                  );

                  return (
                    <div className="img-holder cursor-pointer" key={index}>
                      <div className="floating-btn-4 cursor-pointer text-center rounded-circle">
                        <ImCross
                          className="h-100 rounded-circle"
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedImage(imageItem);
                          }}
                        />
                      </div>
                      <img
                        src={imageItem}
                        alt="happy-img"
                        width="100%"
                        height="250px"
                        className="mb-4 rounded"
                        onClick={() => {
                          setShow(true);
                          setIndex(index);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </Container>
          <PlusIcon className="mb-5 mb-md-0" onClick={handler} />
        </div>
      </Col>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex">
          <Modal.Title id="contained-modal-title-vcenter">
            <b className="fs-6">{title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
            {imageCarousel}
          </Carousel>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="small-modal-2"
      >
        <Card className="py-4 px-3 border-0">
          <Card.Body>
            <div className="text-center">
              <h5 className="mb-3">
                <b>
                  Are you sure you want to delete this image from your
                  inspiration board?
                </b>
              </h5>
              <div className="text-muted text-small mb-1">
                <img
                  src={selectedImage}
                  alt="to-delete"
                  height={100}
                  width={100}
                  className="rounded-circle"
                />
              </div>
              <p className="text-muted text-small">
                This action is irreversible
              </p>
            </div>
            <Row>
              <Col className="text-center" xs={6}>
                <button
                  className="btn text-theme border-theme w-100 p-3 mt-4"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </Col>
              <Col className="text-center" xs={6}>
                <button
                  className="btn btn-theme fw-bold w-100 p-3 mt-4"
                  onClick={removeImage}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <CircularLoading /> : "Delete Image"}
                </button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Modal>
    </BaseContainer>
  );
};
