import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import FirstImage from "../../../../assets/layout/pexels-avonne-stalling-3916019.png";
// import SecondImage from "../../../../assets/layout/decor.jpg";

export const InspirationsCarousel = ({ projectToShow }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      style={{ padding: "10px" }}
      variant="dark"
    >
      {projectToShow &&
        projectToShow.images.map((projectImage) => (
          <Carousel.Item>
            <img
              src={projectImage}
              alt="FirstSlide"
              className="rounded vh-75 w-100"
            />
            <Carousel.Caption>
              <h3>{projectToShow.name}</h3>
              {/* <p>Alans Projects by vendors.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};
