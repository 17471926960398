import { Api } from "../../../axiosInstance";
import Cookie from "universal-cookie";

const cookie = new Cookie();
const jwt = cookie.get("jwt");

export const getAllVendors = async (page, limit) => {
  const response = await Api.get(`/vendors?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const getFavoriteVendors = async (page, limit) => {
  const response = await Api.get(
    `/vendors/favorites?page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const getVendorsBySearch = async (search, page, limit) => {
  const response = await Api.get(
    `/vendors?search=${search}&page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const getVendorsByCategory = async (category, page, limit) => {
  const response = await Api.get(
    `/vendors?category=${category}&page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const getVendorsByFilter = async (filter, page, limit) => {
  const { categories, budget, country } = filter;
  const categoryFilter = categories ? `&category=${categories}` : "";
  const budgetFilter = budget ? `&budget=${budget}` : "";
  const countryFilter = country ? `&country=${country}` : "";
  const response = await Api.get(
    `/vendors?${categoryFilter}${budgetFilter}${countryFilter}&page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const makeEnquiry = async (vendorId, data) => {
  data.vendorId = vendorId;
  const response = await Api.post("/bookings", data, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const getProjectsWithCustomer = async (userId) => {
  const response = await Api.get(`/projects/get-accepted-bid/${userId}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response.data.data;
};

export const verifyPayment = async (reference) => {
  const res = await Api.get(`/payment/verify-payment/${reference}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return res;
};
