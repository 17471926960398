import React, { useState } from "react";
import { Container, Form, Row, Col, InputGroup } from "react-bootstrap";
import { CircularLoading } from "../../../loading/CircularLoading";
import { toast } from "react-toastify";
import { Api } from "../../../../axiosInstance";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!oldPassword || !newPassword)
      toast.error("Please fill in the required fields");
    else {
      const response = await Api.patch(`/user/change-password`, {
        oldPassword,
        newPassword,
      });
      if (response?.status === 200) {
        toast.success("Password changed successfully");
        setOldPassword("");
        setNewPassword("");
      } else toast.error(response);
    }
    setIsSubmitting(false);
  };

  return (
    <Form className="px-5 pt-5 mt-4 px-lg-3">
      <Container>
        <Row>
          <Col lg={6}>
            <Form.Label>
              <span>Old Password</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text className="bg-white border">
                <i className="iconlyBulk-Lock fs-22 border-end-0">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </InputGroup.Text>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                className="react-textarea text-small h-50px border border-left-0"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col lg={6}>
            <Form.Label>
              <span>New Password</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text className="bg-white border">
                <i className="iconlyBulk-Lock fs-22 border-end-0">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </InputGroup.Text>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                className="react-textarea text-small h-50px border border-left-0"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </InputGroup>
          </Col>
          <div className="text-left mt-3">
            <button
              className="btn-theme font-weight-bold btn py-3 px-5 w-auto"
              onClick={handleSubmit}
            >
              {isSubmitting ? <CircularLoading /> : "Update Password"}
            </button>
          </div>
        </Row>
      </Container>
    </Form>
  );
};
export default ChangePassword;
