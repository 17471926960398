import { Modal } from "react-bootstrap";
import React from "react";
import { HomeInspirationShow } from "./HomeInspirationShow";

export const HomeInspirationCarousel = ({
  show,
  handleClose,
  inspirationToShow,
}) => {
  //   const handleClose = () => setShow(false);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        // modal-dialog
        className="pt-4 "
      >
        <Modal.Body>
          <div style={{ position: "relative" }}>
            <HomeInspirationShow inspirationToShow={inspirationToShow} />
            <div
              styled={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
              closeButton
            ></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
