import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC-QU7LDgOJIwThm7cWgy9ErmZwTPonV-8",
  authDomain: "africweddings-c366d.firebaseapp.com",
  projectId: "africweddings-c366d",
  storageBucket: "africweddings-c366d.appspot.com",
  messagingSenderId: "1032232844030",
  appId: "1:1032232844030:web:1547e78f32140c127243af",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const notificationsPermission = () =>
  Notification.requestPermission()
    .then(() => {
      // get the token in the form of promise
      return getToken(messaging, {
        vapidKey:
          "BK9yoIQcft3adjPS830dArEVgTNQDheF23tie1zPXRcFLO0o38WX14yG2X5LlH5AN3GvlW6_Vem7WcFVttRn8t0",
      });
    })
    .then((token) => {
    //   console.log("token is ", token);
      return token;
    })
    .catch((err) => {
      console.log("Unable to get permission to notify.", err);
    });
