import React from "react";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { DefaultProfileImage } from "../../../common/DefaultProfileImage";
import { EmptyState } from "../../../empty_states/empty";

const All = ({ vendors }) => {
  // const [render, setRender] = useState("vendors");

  if (vendors === [] || vendors === null) return <EmptyState />;
  return (
    <div className="d-flex justify-content-center">
      <div className="w-90 grid-template-columns-2 w-sm-100">
        {vendors.map((vendor, index) => {
          const link = `/vendor/${vendor.vendor._id}`;
          return (
            <div className="VendorCard" key={index}>
              <div className=" w-100 h-100 bg-white rounded shadow-sm">
                <div className="d-flex justify-content-between px-3 py-4">
                  <div className="d-flex">
                    {vendor.vendor.profileImage ? (
                      <img
                        className="rounded me-3"
                        src={vendor.vendor.profileImage}
                        alt="vendor-img"
                        height="100px"
                        width="75px"
                      />
                    ) : (
                      <div
                        style={{ height: "100px", width: "75px" }}
                        className="me-3"
                      >
                        <DefaultProfileImage
                          fullName={vendor.vendor.fullName}
                        />
                      </div>
                    )}
                    <div className="d-flex justify-content-between flex-column">
                      <h6 className="font-weight-bold">
                        <Link to={link}>{vendor.vendor.fullName}</Link>
                      </h6>
                      <div className="budgetLocation lh-sm">
                        <p className="text3 mb-2 text-small">
                          Location:{" "}
                          <span>
                            {vendor.vendor.location
                              ? vendor.vendor.location
                              : "Unspecified"}
                          </span>
                        </p>
                        <p className="text3 mb-2 text-small">
                          Project Cost:{" "}
                          <span>
                            {vendor.projectCost === ""
                              ? "Unspecified"
                              : "N" + vendor.projectCost}
                          </span>
                        </p>
                        <p className="text3 mb-0 text-small">
                          Approved Amount:{" "}
                          <span>
                            {vendor.approvedAmount === ""
                              ? "Unspecified"
                              : "N" + vendor.approvedAmount}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="border border-warning d-inline-block mt-2"
                    style={{
                      letterSpacing: "1px",
                      borderRadius: "8px",
                      width: "fit-content",
                      height: "fit-content",
                    }}
                  >
                    <div
                      className="d-flex text-center px-2 align-items-center justify-content-center"
                      style={{ fontSize: "12px" }}
                    >
                      <AiFillStar
                        style={{ color: "#DDA830", verticalAlign: "initial" }}
                      />
                      &nbsp;
                      {vendor?.vendor.rating
                        ? vendor?.vendor.rating.averageRate
                        : "0.0"}
                    </div>
                  </div>
                </div>
                <div className="border-top grid-2 gap-0">
                  <div className="text-center py-2">
                    <Link to={`/vendor/${vendor.vendor._id}`}>
                      <div role="button" className="">
                        View Projects
                      </div>
                    </Link>
                  </div>
                  <div className="text-center py-2 border-left">
                  {/* <vr className="border-warning" /> */}
                  <Link to={`messages/${vendor?._id}`}>
                    Send Message
                  </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default All;
