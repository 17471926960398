import React, { useEffect, useState } from "react";
import { AiFillPlusCircle, AiFillCheckCircle } from "react-icons/ai";
import { Header } from "./Header";
import { Col, Container, Placeholder } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  addToInspirationBoard,
  fetchAllInspirationPosts,
} from "../../../../services/customer/Inspirationboard/index";
import { CircularLoading } from "../../../loading/CircularLoading";
import { useParams } from "react-router-dom";
import { BaseContainer } from "../../Containers";

const CustomerInspiration = () => {
  const [inspirations, setInspirations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedInspirations, setSelectedInspirations] = useState([]);

  const { id, name } = useParams();

  useEffect(() => {
    getInspirationPosts();
  }, []);

  const getInspirationPosts = async () => {
    try {
      const response = await fetchAllInspirationPosts();
      setInspirations(response.data.data.results);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const addSelectedToBoardFn = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const images = selectedInspirations.join(",");
      const response = await addToInspirationBoard(id, images);
      if (response?.data?.success) {
        window.location.href = `/board/view/${id}/${name}`;
        toast.success(response?.data?.message);
      } else {
        toast.error(response);
      }
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const addImage = (img, id) => {
    let malleableImages = selectedInspirations.slice();
    const board = document?.getElementById(id);

    if (!selectedInspirations.includes(img)) {
      malleableImages.push(img);
      board?.classList.add("selected");
    } else {
      board?.classList.remove("selected");
      malleableImages = malleableImages.filter((image) => image !== img);
    }

    setSelectedInspirations(malleableImages);
  };

  const placeholderArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const placeholder = placeholderArray.map((item) => {
    return (
      <Placeholder
        // as={Card.Title}
        key={item}
        animation="glow"
        className="mb-0 w-100"
        style={{ height: "250px" }}
      >
        <Placeholder xs={12} className="h-100" />
      </Placeholder>
    );
  });

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <Header title="Inspiration Board" />
        <div className="VCDiv vh-85 y-scroll px-0 px-md-4">
          <Container className="py-2">
            <div className="grid-5 grid-sm-2 grid-md-3">
              {isLoading
                ? placeholder
                : inspirations.map((image, index) => (
                    <div
                      className="img-holder cursor-pointer"
                      key={index}
                      onClick={() => addImage(image.imageUrl, image._id)}
                    >
                      <img
                        src={image.imageUrl}
                        alt="happy-img"
                        width="100%"
                        height="250px"
                        id={image._id}
                      />
                      <div className="floating-btn-2">
                        {selectedInspirations.includes(image.imageUrl) ? (
                          <AiFillCheckCircle className="customerinspirationIcon w-100 h-100 bg-white rounded-circle" />
                        ) : (
                          <AiFillPlusCircle className="customerinspirationIcon w-100 h-100 bg-white rounded-circle" />
                        )}
                      </div>
                    </div>
                  ))}
            </div>
            {selectedInspirations.length > 0 ? (
              <div className="fixed-small-card d-flex align-items-center p-3 justify-content-between">
                <div>
                  <img
                    src={selectedInspirations[selectedInspirations.length - 1]}
                    alt="happy-img"
                    width="40px"
                    height="55px"
                    className="me-3 rounded"
                  />
                  <b>{selectedInspirations.length} Images Selected</b>
                </div>
                <button
                  className="btn btn-theme font-weight-bold w-auto ms-md-5"
                  onClick={addSelectedToBoardFn}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <CircularLoading /> : "Add To Board"}
                </button>
              </div>
            ) : null}
          </Container>
        </div>
      </Col>
    </BaseContainer>
  );
};
export default CustomerInspiration;
