import { Modal, Card, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Api } from "../../../../axiosInstance";
import { CircularLoading } from "../../../loading/CircularLoading";

export const DeleteModal = ({ toDelete, handleClose, load }) => {
  const [loading, setLoading] = useState("");
  const handleSubmit = async () => {
    setLoading("loading");
    try {
      const response = await Api.delete(`/boards/${toDelete.id}`);
      toast.success(response?.data.message);
      load();
      handleClose();
    } catch (e) {
      toast.error(e);
    }
    setLoading("");
  };

  return (
    // <Modal
    //   show={toDelete.show}
    //   onHide={handleClose}
    //   centered
    //   className="pt-4 md-modal-dialog align-items-end "
    // >
    //   <form>
    //     <Modal.Body className="p-4">
    //       <h3 className="text-center">Delete?</h3>
    //       <p>
    //         You're about to delete <b>{toDelete.name || "this. Continue?"}</b>
    //       </p>

    //       <div className="d-flex">
    //         <div className="w-50">
    //           <CustomButton
    //             style={{
    //               color: "#e00253",
    //               borderColor: "#e00253",
    //               backgroundColor: "#ffff",
    //               // padding: "15px",
    //             }}
    //             // className="rounded"
    //             onClick={handleClose}
    //           >
    //             Cancel
    //           </CustomButton>
    //         </div>
    //         &nbsp; &nbsp;
    //         <div className="w-50">
    //           <CustomButton
    //             style={{
    //               color: "#ffff",
    //               borderColor: "#e00253",
    //               backgroundColor: "#e00253",
    //             }}
    //             type="submit"
    //             // className="rounded"
    //             disabled={loading === "loading"}
    //             onClick={handleSubmit}
    //           >
    //             {loading === "loading" ? <CircularLoading /> : "Confirm"}
    //           </CustomButton>
    //         </div>
    //       </div>
    //     </Modal.Body>
    //   </form>
    // </Modal>
    <Modal
      show={toDelete.show}
      onHide={handleClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="small-modal-2"
    >
      <Card className="py-4 px-3 border-0">
        <Card.Body>
          <div className="text-center">
            <h5 className="mb-3">
              <b>Delete Board</b>
            </h5>
            <p className="text-muted text-small mb-1">
              Are you sure you want to delete this inspiration board?
            </p>
            <p className="text-muted text-small">This action is irreversible</p>
          </div>
          <Row>
            <Col className="text-center" xs={6}>
              <button className="btn border-theme text-theme w-100 custom-btn-3 p-3 mt-4" onClick={handleClose}>
                Cancel
              </button>
            </Col>
            <Col className="text-center" xs={6}>
              <button
                className="btn btn-theme w-100 p-3 mt-4 font-weight-bold"
                onClick={handleSubmit}
                // disabled={loading}
              >
                {loading ? <CircularLoading /> : "Delete Board"}
                {/* Delete Board */}
              </button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Modal>
  );
};
