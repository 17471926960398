import { useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { Formik, Form } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { BsFacebook } from "react-icons/bs";
// import { FcGoogle } from "react-icons/fc";

import AuthContext from "../../../context/AuthProvider";
import Layout from "../../../components/common/layout";
import { TextInput } from "../../../components/common/input";
import CustomButton from "../../../components/custom-button/custom-button";
import { CircularLoading } from "../../../components/loading/CircularLoading.js";

import { notificationsPermission } from "../../../services/notifications_firebase";
import { Api } from "../../../axiosInstance";
import { Lock, Message } from "../../../components/iconly-icons";
const SIGNIN_URL = "/auth/login";

const SignIn = (props) => {
  const [fcmToken, setFcmToken] = useState("");

  useEffect(() => {
    (async () => {
      const token = await notificationsPermission();
      setFcmToken(token);
    })();
  }, []);

  // console.log("fcmToken", fcmToken);

  const search = useLocation().search;
  const role = new URLSearchParams(search).get("role");

  const authContext = useContext(AuthContext);
  const state = {
    email: "",
    password: "",
  };
  // const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    setSubmitting(true);
    values.email = values.email.toLowerCase();
    values.fcmToken = fcmToken;
    try {
      const response = await Api.post(SIGNIN_URL, values);
      console.log(response);
      if (response?.data?.success) {
        const {
          token: {
            access: { token },
          },
          userDetails,
        } = response?.data.data;

        const { role, _id, fullName, email } = userDetails;

        const encJson = CryptoJS.AES.encrypt(
          JSON.stringify(token),
          process.env.REACT_APP_DECRYPT_KEY
        ).toString();
        const encData = CryptoJS.enc.Base64.stringify(
          CryptoJS.enc.Utf8.parse(encJson)
        );

        if (role === "vendor")
          window.location.href = `${process.env.REACT_APP_VENDOR_REDIRECT_URL}/auth?token=${encData}`;
        else if (role === "customer") {
          LogRocket.init(process.env["REACT_APP_LOGROCKET_KEY"]);
          setupLogRocketReact(LogRocket);

          console.log(fullName, role, _id, email);

          LogRocket.identify(_id, {
            name: fullName,
            email,
            // Add your own custom user variables here, ie:
            userType: role,
          });

          authContext.setAuth(userDetails);
          sessionStorage.setItem("userId", userDetails._id);
          localStorage.setItem("fullName", userDetails.fullName);
          const image = userDetails.profileImage
            ? userDetails.profileImage
            : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg";
          localStorage.setItem("profileImage", image);

          const cookies = new Cookies();
          cookies.set("jwt", token, { path: "/" });

          toast.success(response?.data.message);
          resetForm();
          navigate("/dashboard");
        }
      } else {
        if (response === "User is not verified") {
          toast.error(response);
          navigate(`/verify?email=${values.email}&role=${role}`);
        } else toast.error(response);
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      toast.error(e);
    }
  };

  return (
    <>
      <Layout>
        <Formik
          initialValues={state}
          onSubmit={async (values, { setSubmitting, resetForm }) =>
            await handleSubmit(values, resetForm, setSubmitting)
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="py-4">
                <h2 className="fw-bolder">Welcome Back</h2>
                <p>Glad to see you back, Sign In to access your account.</p>
                {/* <div className="ContinueButtons">
                      <button type="submit" className="facebook">
                        <span className="w-24">
                          <BsFacebook className="mt-1" />
                        </span>
                        Continue with Facebook
                      </button>
                      <button type="submit" className="google">
                        <span className="w-24">
                          <FcGoogle className="mt-1" />
                        </span>
                        Continue with Google
                      </button>
                    </div>
                    <br />
                    <hr /> */}

                <TextInput
                  labelClass="PersonalText"
                  label={`Email Address`}
                  className="EachTextInput"
                  iconImage={<Message />}
                  type="email"
                  name="email"
                  placeholder="Enter Your Email Address"
                  required
                />

                <TextInput
                  labelClass="PersonalText"
                  label={`Password`}
                  className="EachTextInput"
                  iconImage={<Lock />}
                  type="password"
                  // type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  placeholder="Enter Your Password"
                  required
                />

                <div className="text-end">
                  <Link to="/forgotpassword" className="fs-14">
                    {" "}
                    Forgot Password?{" "}
                  </Link>
                </div>
              </div>

              <CustomButton
                type="submit"
                disabled={isSubmitting}
                style={{
                  backgroundColor: role === "vendor" ? "#6f0463" : "#e00253",
                }}
              >
                {isSubmitting ? <CircularLoading /> : "Login"}
              </CustomButton>

              <p className="mt-3 text-center fs-14">
                Don't have an account? &nbsp;
                <Link to="/">
                  <span
                    style={{ color: role === "vendor" ? "#6f0463" : "#e00253" }}
                  >
                    Sign Up
                  </span>
                </Link>
              </p>
            </Form>
          )}
        </Formik>
      </Layout>
    </>
  );
};

export default SignIn;
