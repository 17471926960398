import { initializeApp } from "firebase/app";
import {
  getFirestore,
  addDoc,
  updateDoc,
  collection,
  // Timestamp,
  doc,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";

const app = initializeApp({
  apiKey: "AIzaSyC-QU7LDgOJIwThm7cWgy9ErmZwTPonV-8",
  authDomain: "africweddings-c366d.firebaseapp.com",
  projectId: "africweddings-c366d",
  storageBucket: "africweddings-c366d.appspot.com",
  messagingSenderId: "1032232844030",
  appId: "1:1032232844030:web:1547e78f32140c127243af",
});

export const firestore = getFirestore(app);

export const sendMessage = (from, to, type, value) => {
  console.log(from, to, type, value);
  try {
    addDoc(collection(firestore, "messages"), {
      meta: {
        // set up user and user urk
      },
      chats: [
        {
          from_uid: from,
          to_uid: to,
          type: type,
          value: value,
          createdAt: Date.now(),
          // createdAt: serverTimestamp(),
        },
      ],
      members: [from, to],
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }).then((dc) => {
      console.log("Dae", dc);
    });
  } catch (ex) {
    console.log(ex);
  }
};

export const addToMessages = (from, to, type, value, id) => {
  console.log(from, to, type, value, id);
  try {
    const docRef = doc(firestore, "messages", id);

    updateDoc(docRef, {
      chats: arrayUnion({
        from_uid: from,
        to_uid: to,
        type: type,
        value: value,
        createdAt: Date.now(),
      }),
      updatedAt: serverTimestamp(),
    }).then((dc) => {
      console.log("Dae", dc);
    });
  } catch (ex) {
    console.log(ex);
  }
};

// TODO: Export Send message and Custom hook to get messages/ message list
