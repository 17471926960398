import React from "react";
import { Offcanvas, Card } from "react-bootstrap";

export const NotificationsCanvas = ({ show, handleClose }) => {
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      backdropClassName=""
      className="Back-drop h-100 p-0 p-md-3 y-scroll VCDiv w-sm-100"
      style={{ top: "unset", width: "400px" }}
    >
      <Offcanvas.Body>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <h6 className="fw-bold m-0">Notifications</h6>
          <button
            type="button"
            className="btn-close text-dark"
            onClick={handleClose}
          ></button>
        </div>

        <Card className="mt-4 border-0 text-small">
          <Card.Body className="rounded shadow-sm border-0">
            <p>7:00 PM</p>
            <p>
              Mark Anderson has marked your project as completed. Do you wish to
              approve their full payment. They’ll be paid automatically after 7
              days if you ignore this
            </p>
            <div className="d-flex">
              <p className="text-danger cursor-pointer fw-bold">APPROVE</p>
              <p className="text-danger cursor-pointer fw-bold ms-5">
                DECLINE & REPORT ISSUE
              </p>
            </div>
          </Card.Body>
        </Card>

        <Card className="mt-4 border-0 text-small">
          <Card.Body className="rounded shadow-sm border-0">
            <p>7:00 PM</p>
            <p>Mark Anderson requested a part payment of N60,000.</p>
            <div className="d-flex">
              <p className="text-danger cursor-pointer fw-bold">APPROVE</p>
              <p className="text-danger cursor-pointer fw-bold ms-5">DECLINE</p>
            </div>
          </Card.Body>
        </Card>

        <Card className="mt-4 border-0 text-small">
          <Card.Body className="rounded shadow-sm border-0">
            <p>7:00 PM</p>
            <p>
              Mark Anderson has marked your project as completed. Do you wish to
              approve their full payment. They’ll be paid automatically after 7
              days if you ignore this
            </p>
            <div className="d-flex">
              <p className="text-danger cursor-pointer fw-bold">APPROVE</p>
              <p className="text-danger cursor-pointer fw-bold ms-5">
                DECLINE & REPORT ISSUE
              </p>
            </div>
          </Card.Body>
        </Card>

        <Card className="mt-4 border-0 text-small">
          <Card.Body className="rounded shadow-sm border-0">
            <p>7:00 PM</p>
            <p>Mark Anderson requested a part payment of N60,000.</p>
            <div className="d-flex">
              <p className="text-danger cursor-pointer fw-bold">APPROVE</p>
              <p className="text-danger cursor-pointer fw-bold ms-5">DECLINE</p>
            </div>
          </Card.Body>
        </Card>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
