export const EmptyState = () => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <h2 className="fw-bold mb-5">There's nothing here</h2>
      <div className="d-none d-md-block">
        <svg
          width="610"
          height="259"
          viewBox="0 0 610 259"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M450.218 2.94635C452.163 2.40373 453.987 2.26808 455.933 2.26808C457.756 2.26808 459.701 2.40373 461.524 2.67504C463.349 2.94635 465.294 3.48895 467.117 4.43852C468.941 5.38808 470.764 6.7446 471.859 9.05069L469.792 8.50808L471.98 6.60895C472.832 6.06633 473.561 5.38808 474.291 4.84546C475.871 3.76025 477.573 2.81069 479.397 2.13243C481.22 1.45416 483.043 0.911563 484.867 0.640258C486.813 0.504606 488.636 0.504605 490.581 1.04721C490.946 1.18287 491.067 1.58982 490.946 1.86112C490.824 2.26808 490.581 2.40373 490.217 2.26808H490.095C488.515 1.86112 486.692 1.99678 484.988 2.26808C483.286 2.53939 481.584 3.21765 480.004 4.03156C478.424 4.84548 476.965 5.79503 475.506 6.88025C474.777 7.42286 474.047 8.10112 473.439 8.64373L471.494 10.5429C470.886 11.0855 470.157 10.9498 469.671 10.4072L469.55 10.5429L469.427 10.2716C468.941 8.77938 467.603 7.55852 466.144 6.7446C464.685 5.93069 462.983 5.25243 461.16 4.84546C459.458 4.43852 457.635 4.16722 455.811 4.03156C453.987 3.89591 452.163 3.89591 450.583 4.30286H450.461C450.097 4.43852 449.854 4.16722 449.732 3.76025C449.732 3.35329 449.854 2.94635 450.218 2.94635Z"
            fill="#AAB2C5"
          />
          <path
            d="M406.774 39.3961C408.233 39.0162 409.6 38.9214 411.059 38.9214C412.427 38.9214 413.886 39.0162 415.254 39.2063C416.622 39.3961 418.081 39.7759 419.449 40.4406C420.815 41.1053 422.183 42.0549 423.005 43.6691L421.455 43.2893L423.096 41.96C423.733 41.5801 424.281 41.1053 424.828 40.7255C426.014 39.9658 427.29 39.3011 428.658 38.8263C430.025 38.3516 431.393 37.9718 432.761 37.7819C434.22 37.6869 435.588 37.6869 437.047 38.0668C437.32 38.1617 437.411 38.4466 437.32 38.6365C437.229 38.9214 437.047 39.0162 436.773 38.9214H436.682C435.496 38.6365 434.129 38.7315 432.852 38.9214C431.575 39.1112 430.3 39.586 429.114 40.1558C427.928 40.7255 426.834 41.3902 425.74 42.1499C425.192 42.5296 424.646 43.0044 424.19 43.3843L422.731 44.7137C422.274 45.0935 421.728 44.9986 421.363 44.6187L421.272 44.5238L421.181 44.3338C420.816 43.2893 419.813 42.4348 418.719 41.865C417.624 41.2953 416.349 40.8205 414.981 40.5356C413.704 40.2507 412.336 40.0608 410.968 39.9658C409.6 39.8709 408.233 39.8709 407.048 40.1558H406.957C406.683 40.2507 406.5 40.0608 406.409 39.7759C406.409 39.681 406.591 39.3961 406.774 39.3961Z"
            fill="#AAB2C5"
          />
          <path
            d="M149.022 2.94635C150.967 2.40373 152.79 2.26808 154.737 2.26808C156.56 2.26808 158.505 2.40373 160.328 2.67503C162.152 2.94633 164.098 3.48895 165.921 4.43852C167.745 5.38808 169.568 6.74461 170.663 9.05069L168.596 8.50808L170.784 6.60895C171.634 6.06633 172.365 5.38808 173.093 4.84548C174.674 3.76025 176.377 2.81069 178.201 2.13243C180.024 1.45418 181.847 0.91156 183.671 0.640256C185.617 0.504605 187.44 0.504609 189.385 1.04722C189.75 1.18287 189.871 1.58982 189.75 1.86112C189.628 2.26808 189.385 2.40373 189.021 2.26808H188.899C187.319 1.86112 185.494 1.99678 183.792 2.26808C182.09 2.53939 180.388 3.21765 178.808 4.03156C177.227 4.84546 175.768 5.79503 174.309 6.88025C173.581 7.42286 172.85 8.10112 172.243 8.64373L170.298 10.5429C169.69 11.0855 168.961 10.9498 168.474 10.4072L168.352 10.2715L168.231 10.0003C167.745 8.50807 166.407 7.28721 164.948 6.47329C163.489 5.65939 161.787 4.98112 159.964 4.57416C158.262 4.16722 156.439 3.89591 154.614 3.76025C152.79 3.62461 150.967 3.6246 149.387 4.03156H149.265C148.901 4.16722 148.658 3.89591 148.536 3.48895C148.536 3.35329 148.779 2.94635 149.022 2.94635Z"
            fill="#AAB2C5"
          />
          <path
            d="M462.597 34.7106C464.541 34.2222 466.365 34.1001 468.311 34.1001C470.134 34.1001 472.079 34.2222 473.903 34.4664C475.726 34.7106 477.672 35.1989 479.496 36.0536C481.319 36.9082 483.142 38.129 484.237 40.2045L482.171 39.7162L484.359 38.0069C485.21 37.5185 485.939 36.9082 486.669 36.4198C488.25 35.4431 489.951 34.5885 491.775 33.978C493.598 33.3677 495.422 32.8793 497.245 32.6351C499.191 32.513 501.015 32.513 502.96 33.0014C503.324 33.1235 503.445 33.4898 503.324 33.7338C503.202 34.1001 502.96 34.2222 502.595 34.1001H502.474C500.893 33.7338 499.07 33.8559 497.367 34.1001C495.665 34.3443 493.963 34.9547 492.382 35.6873C490.802 36.4198 489.343 37.2745 487.884 38.2511C487.155 38.7394 486.425 39.3499 485.817 39.8382L483.873 41.5474C483.264 42.0358 482.535 41.9137 482.049 41.4253L481.928 41.3032L481.805 41.059C481.319 39.7162 479.982 38.6173 478.523 37.8848C477.064 37.1524 475.362 36.5419 473.538 36.1756C471.836 35.8094 470.013 35.5652 468.19 35.4431C466.365 35.321 464.541 35.321 462.961 35.6873H462.839C462.475 35.8094 462.232 35.5652 462.111 35.1989C462.111 35.0768 462.232 34.7106 462.597 34.7106Z"
            fill="#AAB2C5"
          />
          <path
            d="M347.853 60.655L430.338 124.895H448.64"
            stroke="#D6DDE8"
            stroke-width="6"
            stroke-miterlimit="10"
          />
          <path
            d="M346.533 186.879L430.338 124.895"
            stroke="#D6DDE8"
            stroke-width="6"
            stroke-miterlimit="10"
          />
          <g filter="url(#filter0_d_1_3)">
            <path
              d="M456.182 113.014L455.735 110.99C455.735 110.99 457.455 107.86 458.319 106.239C459.293 104.624 460.196 104.339 464.322 104.004C468.454 103.557 471.595 102.947 470.194 106.317C468.683 109.681 464.458 118.122 464.458 118.122L456.182 113.014Z"
              fill="url(#paint0_linear_1_3)"
            />
          </g>
          <g filter="url(#filter1_d_1_3)">
            <path
              d="M543.142 116.652L571.937 76.2977C572.538 75.4409 573.459 74.8232 574.473 74.544C576.384 74.0904 579.648 73.2645 582.784 72.765C587.487 72.0159 592.854 71.302 590.7 76.2985C588.54 81.4057 574.731 115.559 574.731 115.559L543.142 116.652Z"
              fill="url(#paint1_linear_1_3)"
            />
          </g>
          <g filter="url(#filter2_d_1_3)">
            <path
              d="M454.443 108.144C454.775 108.161 469.398 121.275 469.398 121.275L518.629 114.123L546.453 114.94C546.453 114.94 569.92 114.192 577.115 114.576C584.311 114.96 593.884 116.581 594.72 121.737C595.555 126.893 597.601 128.113 594.499 130.059C591.509 132.011 579.633 140.267 563.26 139.172C546.887 138.076 527.498 141.485 527.498 141.485C527.498 141.485 527.389 139.368 522.074 139.086C516.761 138.802 451.102 129.189 451.102 129.189C451.102 129.189 440.24 128.831 441.478 118.119C442.824 107.413 446.466 107.829 448.796 107.843C451.244 107.751 454.443 108.144 454.443 108.144Z"
              fill="url(#paint2_linear_1_3)"
            />
          </g>
          <g filter="url(#filter3_d_1_3)">
            <path
              d="M452.302 119.141L462.709 119.696C462.709 119.696 456.567 128.702 454.351 130.695C452.135 132.689 447.851 136.016 443.668 135.347C439.484 134.68 440.605 132.406 441.853 129.806C443.102 127.206 444.526 123.393 444.526 123.393L452.302 119.141Z"
              fill="url(#paint3_linear_1_3)"
            />
          </g>
          <g filter="url(#filter4_d_1_3)">
            <path
              d="M564.06 136.658C555.641 134.208 550.018 133.464 540.123 135.826C530.227 138.187 526.351 140.091 526.351 140.091L506.423 174.473C505.197 176.631 506.722 179.268 509.159 179.398C514.036 179.546 521.174 178.928 525.273 174.924C531.838 168.384 564.06 136.658 564.06 136.658Z"
              fill="url(#paint4_linear_1_3)"
            />
          </g>
          <path
            opacity="0.4"
            d="M606.797 124.27C606.762 124.935 606.727 125.6 606.696 126.154C605.471 138.755 598.74 148.396 591.1 147.989C585.455 147.688 580.767 141.883 578.874 133.67C578.16 130.41 577.789 126.945 577.989 123.178C577.995 123.067 578.007 122.846 578.013 122.735C578.449 114.536 581.158 107.458 585.047 103.22C587.525 100.464 590.609 98.8504 593.819 99.0216C594.041 99.0334 594.152 99.0394 594.374 99.0511C601.214 99.8605 606.406 108.693 607.033 119.838C606.846 121.273 606.88 122.719 606.797 124.27Z"
            fill="#D6DDE8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M561.419 121.855C562.035 118.916 563.723 115.417 566.849 113.997L567.395 115.2C564.834 116.364 563.29 119.356 562.712 122.131L562.709 122.141C561.588 127.112 562.696 132.35 564.954 136.936C565.338 137.699 565.819 138.45 566.291 138.976V139.113C565.82 139.292 565.23 139.232 564.805 139.232C564.42 138.708 564.067 138.11 563.772 137.526L563.771 137.522C561.421 132.752 560.216 127.202 561.419 121.855Z"
            fill="#8C96A6"
          />
          <path
            d="M517.51 114.285L516.774 117.691L525.236 123.475L541.582 123.014C543.027 122.98 544.199 121.82 544.27 120.491L544.359 118.829L546.453 114.94L543.89 113.026C542.929 112.308 541.84 111.916 540.622 111.851L526.521 111.877C525.967 111.847 525.408 111.929 524.953 112.127L517.51 114.285Z"
            fill="#69768C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M538.378 114.725L544.68 118.252L544.036 119.405L538.048 116.054L525.071 116.343L516.93 118.332L516.617 117.049L524.898 115.025L538.378 114.725Z"
            fill="#AAB2C5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M539.058 111.92L538.248 118.889L539.009 122.943L537.711 123.186L536.914 118.935L537.747 111.768L539.058 111.92Z"
            fill="#AAB2C5"
          />
          <path
            d="M525.236 123.475L524.518 118.215L524.816 114.675L525.18 112.028"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M525.474 114.748L525.835 112.117L524.526 111.938L524.16 114.602L523.854 118.232L524.583 123.565L525.891 123.386L525.181 118.198L525.474 114.748Z"
            fill="#AAB2C5"
          />
          <path
            opacity="0.6"
            d="M594.159 98.9286L593.167 117.543L591.506 117.455L590.304 119.168L584.937 103.215C587.416 100.458 590.499 98.8446 593.71 99.0158C593.826 98.9109 594.048 98.9227 594.159 98.9286Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M606.798 124.27C606.762 124.935 606.727 125.6 606.698 126.154L599.6 125.998L599.688 124.336L597.976 123.133L606.922 119.833C606.846 121.273 606.88 122.719 606.798 124.27Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M590.463 126.621L578.763 133.664C578.049 130.404 577.678 126.939 577.879 123.172L588.95 123.763L588.861 125.425L590.463 126.621Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M524.641 143.001C527.987 142.734 531.891 142.387 531.891 142.387L514.961 170.485L508.633 170.481"
            fill="#69768C"
          />
          <path
            opacity="0.3"
            d="M550.106 106.801L556.305 107.132L574.558 79.2154L570.019 78.9732"
            fill="#69768C"
          />
          <path
            d="M563.903 127.094C564.33 127.117 564.696 126.788 564.719 126.359C564.741 125.931 564.414 125.565 563.985 125.543C563.557 125.52 563.191 125.848 563.169 126.277C563.146 126.705 563.475 127.071 563.903 127.094Z"
            fill="#69768C"
          />
          <path
            d="M564.436 131.678C564.864 131.701 565.23 131.372 565.252 130.944C565.275 130.515 564.946 130.149 564.518 130.127C564.09 130.104 563.724 130.433 563.702 130.861C563.68 131.289 564.008 131.655 564.436 131.678Z"
            fill="#69768C"
          />
          <path
            d="M565.977 136.094C566.405 136.116 566.771 135.787 566.793 135.359C566.817 134.931 566.488 134.565 566.06 134.543C565.632 134.519 565.267 134.848 565.244 135.276C565.22 135.705 565.549 136.07 565.977 136.094Z"
            fill="#69768C"
          />
          <path
            d="M564.6 122.353C565.028 122.375 565.393 122.047 565.416 121.618C565.438 121.19 565.11 120.824 564.682 120.801C564.254 120.779 563.888 121.107 563.866 121.536C563.843 121.964 564.172 122.33 564.6 122.353Z"
            fill="#69768C"
          />
          <path
            d="M566.933 118.144C567.361 118.166 567.726 117.838 567.749 117.409C567.771 116.981 567.444 116.615 567.015 116.592C566.587 116.569 566.221 116.898 566.199 117.326C566.176 117.755 566.505 118.121 566.933 118.144Z"
            fill="#69768C"
          />
          <path
            opacity="0.6"
            d="M590.88 147.978L591.317 129.334L592.978 129.422L594.18 127.708L599.996 143.575C597.512 146.442 594.539 148.062 591.433 148.007C591.212 147.995 591.102 147.989 590.88 147.978Z"
            fill="white"
          />
          <path
            d="M591.506 117.455C590.34 118.503 585.983 125.271 591.281 132.11C596.976 129.413 600.969 127.404 600.808 124.173C600.763 120.837 591.506 117.455 591.506 117.455Z"
            fill="#69768C"
          />
          <g filter="url(#filter5_d_1_3)">
            <path
              d="M342.404 215.291H97.6989C93.3221 215.291 89.741 211.707 89.741 207.327V42.5964C89.741 38.216 93.3221 34.632 97.6989 34.632H342.404C346.781 34.632 350.362 38.216 350.362 42.5964V207.195C350.494 211.707 346.913 215.291 342.404 215.291Z"
              fill="url(#paint5_linear_1_3)"
            />
          </g>
          <g filter="url(#filter6_d_1_3)">
            <path
              d="M102.987 102.74C102.987 110.095 97.0437 116.044 89.6306 116.044C89.5028 116.044 88.4164 116.044 80.4281 116.044C74.8682 116.044 65.9854 116.044 51.7982 116.044H45.0242C36.1412 116.235 29.0476 109.136 29.0476 100.566C29.0476 91.9311 36.2051 84.7678 45.2159 85.2155C52.9485 61.0392 88.3525 64.429 91.3561 89.4368C98.0023 90.2682 102.987 95.8966 102.987 102.74Z"
              fill="url(#paint6_linear_1_3)"
            />
          </g>
          <path
            d="M89.6303 116.044C96.9795 116.044 102.987 110.095 102.987 102.74C102.987 95.3849 96.9795 89.4368 89.6303 89.4368C82.2811 89.4368 76.2739 95.3849 76.2739 102.74C76.2739 110.095 82.2811 116.044 89.6303 116.044Z"
            fill="url(#paint7_linear_1_3)"
          />
          <path
            d="M67.839 116.172C80.9398 116.172 91.6121 105.554 91.6121 92.4429C91.6121 79.3314 80.9398 68.7143 67.839 68.7143C54.7383 68.7143 44.0659 79.3314 44.0659 92.4429C44.0659 105.554 54.6744 116.172 67.839 116.172Z"
            fill="url(#paint8_linear_1_3)"
          />
          <g filter="url(#filter7_d_1_3)">
            <path
              d="M488.528 171.672C488.528 178.24 483.222 183.55 476.603 183.55C476.489 183.55 475.519 183.55 468.386 183.55C463.422 183.55 455.49 183.55 442.824 183.55H436.776C428.845 183.721 422.511 177.383 422.511 169.731C422.511 162.022 428.901 155.626 436.946 156.025C443.85 134.439 475.462 137.466 478.144 159.794C484.077 160.536 488.528 165.562 488.528 171.672Z"
              fill="url(#paint9_linear_1_3)"
            />
          </g>
          <path
            d="M476.603 183.55C483.165 183.55 488.528 178.24 488.528 171.672C488.528 165.106 483.165 159.795 476.603 159.795C470.041 159.795 464.677 165.106 464.677 171.672C464.677 178.24 470.041 183.55 476.603 183.55Z"
            fill="url(#paint10_linear_1_3)"
          />
          <path
            d="M457.146 183.665C468.843 183.665 478.372 174.185 478.372 162.479C478.372 150.772 468.843 141.292 457.146 141.292C445.449 141.292 435.92 150.772 435.92 162.479C435.92 174.185 445.391 183.665 457.146 183.665Z"
            fill="url(#paint11_linear_1_3)"
          />
          <g filter="url(#filter8_d_1_3)">
            <path
              d="M572.851 206.79C572.851 211.501 569.044 215.311 564.296 215.311C564.214 215.311 563.52 215.311 558.403 215.311C554.842 215.311 549.153 215.311 540.068 215.311H535.729C530.041 215.434 525.498 210.887 525.498 205.398C525.498 199.869 530.082 195.281 535.852 195.567C540.805 180.084 563.479 182.255 565.401 198.271C569.658 198.804 572.851 202.409 572.851 206.79Z"
              fill="url(#paint12_linear_1_3)"
            />
          </g>
          <path
            d="M564.296 215.311C569.003 215.311 572.851 211.501 572.851 206.792C572.851 202.081 569.003 198.271 564.296 198.271C559.589 198.271 555.743 202.081 555.743 206.792C555.743 211.501 559.589 215.311 564.296 215.311Z"
            fill="url(#paint13_linear_1_3)"
          />
          <path
            d="M550.34 215.393C558.731 215.393 565.565 208.593 565.565 200.196C565.565 191.8 558.731 185 550.34 185C541.951 185 535.115 191.8 535.115 200.196C535.115 208.593 541.91 215.393 550.34 215.393Z"
            fill="url(#paint14_linear_1_3)"
          />
          <path
            d="M350.404 56.5466H89.7836V42.8259C89.7836 38.168 93.3646 34.3571 97.7414 34.3571H342.446C346.823 34.3571 350.404 38.168 350.404 42.8259V56.5466Z"
            fill="url(#paint15_linear_1_3)"
          />
          <path
            d="M102.347 49.5108C104.471 49.5108 106.193 47.7872 106.193 45.6613C106.193 43.5353 104.471 41.8118 102.347 41.8118C100.223 41.8118 98.5006 43.5353 98.5006 45.6613C98.5006 47.7872 100.223 49.5108 102.347 49.5108Z"
            fill="#A1ACBD"
          />
          <path
            d="M114.019 49.5108C116.143 49.5108 117.865 47.7872 117.865 45.6613C117.865 43.5353 116.143 41.8118 114.019 41.8118C111.894 41.8118 110.172 43.5353 110.172 45.6613C110.172 47.7872 111.894 49.5108 114.019 49.5108Z"
            fill="#A1ACBD"
          />
          <path
            d="M125.69 49.5108C127.814 49.5108 129.536 47.7872 129.536 45.6613C129.536 43.5353 127.814 41.8118 125.69 41.8118C123.565 41.8118 121.843 43.5353 121.843 45.6613C121.843 47.7872 123.565 49.5108 125.69 49.5108Z"
            fill="#A1ACBD"
          />
          <defs>
            <filter
              id="filter0_d_1_3"
              x="440.735"
              y="93.6507"
              width="34.7975"
              height="34.4717"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_1_3"
              x="528.142"
              y="62.2344"
              width="68.0487"
              height="64.418"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_1_3"
              x="426.379"
              y="97.8287"
              width="174.778"
              height="53.6567"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_1_3"
              x="425.715"
              y="109.141"
              width="41.994"
              height="36.2937"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_d_1_3"
              x="490.99"
              y="124.39"
              width="78.0695"
              height="65.028"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <filter
              id="filter5_d_1_3"
              x="67.741"
              y="23.632"
              width="304.624"
              height="224.659"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <filter
              id="filter6_d_1_3"
              x="7.04761"
              y="57.772"
              width="117.939"
              height="91.2753"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <filter
              id="filter7_d_1_3"
              x="400.511"
              y="130.343"
              width="110.017"
              height="86.2103"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <filter
              id="filter8_d_1_3"
              x="503.498"
              y="174.037"
              width="91.3529"
              height="74.2767"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_3"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_3"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1_3"
              x1="461.102"
              y1="106.429"
              x2="469.848"
              y2="108.888"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1_3"
              x1="561.255"
              y1="80.926"
              x2="588.941"
              y2="88.6627"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1_3"
              x1="496.466"
              y1="116.663"
              x2="534.853"
              y2="167.9"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1_3"
              x1="448.806"
              y1="122.565"
              x2="461.216"
              y2="126.895"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1_3"
              x1="527.542"
              y1="143.318"
              x2="560.569"
              y2="154.139"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1_3"
              x1="219.968"
              y1="30.4533"
              x2="219.968"
              y2="217.239"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FDFEFF" />
              <stop offset="0.9964" stop-color="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_1_3"
              x1="66.0173"
              y1="88.308"
              x2="65.9931"
              y2="116.557"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="0.9964" stop-color="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_1_3"
              x1="74.8526"
              y1="86.5726"
              x2="86.7035"
              y2="99.5232"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#BCCBE1" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_1_3"
              x1="41.8398"
              y1="81.5545"
              x2="60.5053"
              y2="88.8528"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E2E8F0" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_1_3"
              x1="455.52"
              y1="158.787"
              x2="455.498"
              y2="184.009"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="0.9964" stop-color="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_1_3"
              x1="463.409"
              y1="157.237"
              x2="473.99"
              y2="168.8"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#BCCBE1" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_1_3"
              x1="433.932"
              y1="152.757"
              x2="450.598"
              y2="159.273"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E2E8F0" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_1_3"
              x1="549.174"
              y1="197.548"
              x2="549.158"
              y2="215.64"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="0.9964" stop-color="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_1_3"
              x1="554.832"
              y1="196.437"
              x2="562.422"
              y2="204.731"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#BCCBE1" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_1_3"
              x1="533.689"
              y1="193.223"
              x2="545.644"
              y2="197.897"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E2E8F0" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint15_linear_1_3"
              x1="99.6862"
              y1="52.1964"
              x2="341.97"
              y2="44.9405"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D5DDEA" />
              <stop offset="1" stop-color="#D5DDEA" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="d-md-none">
        <svg
          width="321"
          height="137"
          viewBox="0 0 321 137"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1_61)">
            <path
              d="M236.918 1.5585C237.942 1.27148 238.901 1.19973 239.925 1.19973C240.885 1.19973 241.908 1.27148 242.868 1.41499C243.828 1.5585 244.851 1.84551 245.811 2.34779C246.77 2.85007 247.73 3.56761 248.306 4.78744L247.219 4.50042L248.37 3.49586C248.818 3.20884 249.202 2.85007 249.586 2.56305C250.418 1.98902 251.313 1.48674 252.273 1.12797C253.232 0.769198 254.192 0.482185 255.151 0.338676C256.175 0.266922 257.135 0.266922 258.158 0.553938C258.35 0.625693 258.414 0.840955 258.35 0.984463C258.286 1.19973 258.158 1.27148 257.967 1.19973H257.903C257.071 0.984463 256.111 1.05622 255.215 1.19973C254.32 1.34324 253.424 1.70201 252.592 2.13253C251.761 2.56306 250.993 3.06533 250.225 3.63937C249.842 3.92638 249.457 4.28516 249.138 4.57217L248.114 5.57673C247.794 5.86375 247.41 5.792 247.155 5.50498L247.091 5.57673L247.026 5.43322C246.77 4.64393 246.067 3.99814 245.299 3.56761C244.531 3.13709 243.636 2.77832 242.676 2.56305C241.78 2.34779 240.821 2.20429 239.861 2.13253C238.901 2.06078 237.942 2.06078 237.11 2.27604H237.046C236.854 2.34779 236.726 2.20429 236.662 1.98902C236.662 1.77376 236.726 1.5585 236.918 1.5585Z"
              fill="#AAB2C5"
            />
            <path
              d="M214.056 20.8389C214.824 20.6379 215.544 20.5877 216.312 20.5877C217.031 20.5877 217.799 20.6379 218.519 20.7384C219.239 20.8389 220.007 21.0398 220.726 21.3914C221.446 21.7429 222.165 22.2452 222.598 23.0991L221.782 22.8982L222.645 22.195C222.981 21.9941 223.269 21.7429 223.557 21.5421C224.181 21.1402 224.853 20.7886 225.573 20.5375C226.292 20.2863 227.012 20.0855 227.731 19.985C228.499 19.9348 229.219 19.9348 229.987 20.1357C230.131 20.1859 230.179 20.3366 230.131 20.437C230.083 20.5877 229.987 20.6379 229.843 20.5877H229.795C229.171 20.437 228.451 20.4873 227.779 20.5877C227.107 20.6882 226.436 20.9393 225.812 21.2407C225.188 21.5421 224.612 21.8936 224.037 22.2955C223.749 22.4964 223.461 22.7475 223.221 22.9485L222.454 23.6517C222.213 23.8525 221.926 23.8024 221.734 23.6014L221.686 23.5512L221.638 23.4507C221.446 22.8982 220.918 22.4462 220.342 22.1448C219.766 21.8435 219.095 21.5923 218.375 21.4416C217.703 21.2909 216.983 21.1905 216.264 21.1402C215.544 21.09 214.824 21.09 214.201 21.2407H214.153C214.008 21.2909 213.912 21.1905 213.865 21.0398C213.865 20.9896 213.96 20.8389 214.056 20.8389Z"
              fill="#AAB2C5"
            />
            <path
              d="M78.4199 1.5585C79.4433 1.27148 80.4028 1.19973 81.427 1.19973C82.3865 1.19973 83.41 1.27148 84.3695 1.41499C85.329 1.55849 86.3532 1.84551 87.3127 2.34779C88.2722 2.85007 89.2317 3.56762 89.8077 4.78744L88.7204 4.50042L89.8717 3.49586C90.3191 3.20884 90.7033 2.85007 91.0869 2.56306C91.9185 1.98902 92.8148 1.48674 93.7744 1.12797C94.7339 0.769205 95.6934 0.482184 96.653 0.338675C97.6771 0.266922 98.6366 0.266924 99.6601 0.55394C99.8518 0.625695 99.9158 0.840955 99.8518 0.984463C99.7879 1.19973 99.6601 1.27148 99.4683 1.19973H99.4044C98.5727 0.984463 97.6125 1.05622 96.7169 1.19973C95.8213 1.34324 94.9257 1.70201 94.094 2.13253C93.2623 2.56305 92.4945 3.06533 91.7268 3.63937C91.3433 3.92638 90.959 4.28516 90.6394 4.57217L89.616 5.57673C89.2957 5.86375 88.9121 5.792 88.6557 5.50498L88.5918 5.43322L88.5279 5.28972C88.2722 4.50041 87.5684 3.85463 86.8006 3.4241C86.0329 2.99358 85.1372 2.63481 84.1777 2.41954C83.2821 2.20429 82.3226 2.06078 81.3624 1.98902C80.4028 1.91727 79.4433 1.91726 78.6116 2.13253H78.5477C78.356 2.20429 78.2281 2.06078 78.1642 1.84551C78.1642 1.77376 78.292 1.5585 78.4199 1.5585Z"
              fill="#AAB2C5"
            />
            <path
              d="M243.432 18.3605C244.455 18.1021 245.415 18.0375 246.439 18.0375C247.399 18.0375 248.422 18.1021 249.382 18.2313C250.341 18.3605 251.365 18.6187 252.325 19.0708C253.284 19.5229 254.244 20.1686 254.82 21.2665L253.732 21.0082L254.884 20.1041C255.332 19.8457 255.715 19.5229 256.1 19.2645C256.931 18.7479 257.827 18.2959 258.786 17.9729C259.746 17.6501 260.705 17.3917 261.665 17.2626C262.689 17.198 263.649 17.198 264.672 17.4563C264.864 17.5209 264.928 17.7147 264.864 17.8438C264.8 18.0375 264.672 18.1021 264.48 18.0375H264.416C263.585 17.8438 262.625 17.9084 261.729 18.0375C260.833 18.1667 259.938 18.4896 259.106 18.8771C258.274 19.2645 257.507 19.7166 256.739 20.2332C256.355 20.4915 255.971 20.8144 255.651 21.0727L254.628 21.9768C254.308 22.2352 253.924 22.1706 253.669 21.9122L253.605 21.8477L253.54 21.7185C253.284 21.0082 252.58 20.4269 251.813 20.0395C251.045 19.652 250.149 19.3291 249.19 19.1353C248.294 18.9417 247.335 18.8125 246.375 18.7479C245.415 18.6833 244.455 18.6833 243.624 18.8771H243.56C243.368 18.9417 243.24 18.8125 243.176 18.6187C243.176 18.5541 243.24 18.3605 243.432 18.3605Z"
              fill="#AAB2C5"
            />
            <path
              d="M183.051 32.0839L226.456 66.0643H236.088"
              stroke="#D6DDE8"
              stroke-width="6"
              stroke-miterlimit="10"
            />
            <path
              d="M182.356 98.8511L226.457 66.0643"
              stroke="#D6DDE8"
              stroke-width="6"
              stroke-miterlimit="10"
            />
            <g filter="url(#filter0_d_1_61)">
              <path
                d="M240.057 59.7797L239.821 58.7091C239.821 58.7091 240.726 57.0532 241.181 56.1959C241.694 55.3418 242.169 55.1909 244.34 55.0135C246.514 54.7774 248.167 54.4546 247.43 56.2371C246.635 58.0165 244.412 62.4818 244.412 62.4818L240.057 59.7797Z"
                fill="url(#paint0_linear_1_61)"
              />
            </g>
            <g filter="url(#filter1_d_1_61)">
              <path
                d="M285.817 61.7042L300.97 40.3582C301.286 39.905 301.771 39.5783 302.305 39.4306C303.31 39.1907 305.028 38.7538 306.678 38.4896C309.153 38.0933 311.977 37.7158 310.844 40.3587C309.707 43.0601 302.44 61.126 302.44 61.126L285.817 61.7042Z"
                fill="url(#paint1_linear_1_61)"
              />
            </g>
            <g filter="url(#filter2_d_1_61)">
              <path
                d="M239.142 57.2034C239.316 57.2128 247.011 64.1494 247.011 64.1494L272.918 60.3662L287.56 60.7986C287.56 60.7986 299.908 60.4027 303.695 60.6057C307.482 60.8088 312.519 61.6666 312.959 64.3939C313.399 67.1211 314.475 67.7665 312.843 68.7957C311.269 69.8281 305.02 74.1953 296.404 73.6158C287.788 73.0364 277.585 74.8398 277.585 74.8398C277.585 74.8398 277.527 73.72 274.731 73.5704C271.935 73.4201 237.383 68.3353 237.383 68.3353C237.383 68.3353 231.667 68.1463 232.319 62.48C233.027 56.8168 234.943 57.0372 236.17 57.0442C237.458 56.9956 239.142 57.2034 239.142 57.2034Z"
                fill="url(#paint2_linear_1_61)"
              />
            </g>
            <g filter="url(#filter3_d_1_61)">
              <path
                d="M238.015 63.0205L243.491 63.3141C243.491 63.3141 240.259 68.078 239.093 69.1322C237.927 70.1866 235.672 71.9467 233.471 71.593C231.269 71.24 231.859 70.0371 232.516 68.662C233.173 67.2866 233.923 65.2697 233.923 65.2697L238.015 63.0205Z"
                fill="url(#paint3_linear_1_61)"
              />
            </g>
            <g filter="url(#filter4_d_1_61)">
              <path
                d="M296.825 72.2864C292.395 70.9905 289.436 70.5969 284.229 71.846C279.021 73.0951 276.981 74.1023 276.981 74.1023L266.495 92.2891C265.85 93.4305 266.652 94.825 267.934 94.8942C270.501 94.9725 274.257 94.6453 276.414 92.5274C279.869 89.0682 296.825 72.2864 296.825 72.2864Z"
                fill="url(#paint4_linear_1_61)"
              />
            </g>
            <path
              opacity="0.4"
              d="M319.314 65.7337C319.296 66.0854 319.278 66.437 319.262 66.73C318.617 73.3957 315.075 78.4954 311.055 78.2801C308.084 78.1208 305.617 75.0501 304.621 70.706C304.245 68.9812 304.05 67.1487 304.155 65.156C304.158 65.0974 304.164 64.9802 304.168 64.9216C304.397 60.5845 305.823 56.8405 307.869 54.5993C309.173 53.141 310.796 52.2877 312.485 52.3782C312.602 52.3845 312.66 52.3876 312.777 52.3938C316.377 52.822 319.109 57.4941 319.439 63.3893C319.34 64.1482 319.358 64.9132 319.314 65.7337Z"
              fill="#D6DDE8"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M295.435 64.4563C295.759 62.9017 296.648 61.0508 298.292 60.2994L298.58 60.9358C297.232 61.5516 296.42 63.1343 296.115 64.6019L296.114 64.6074C295.524 67.237 296.107 70.0077 297.295 72.4332C297.497 72.8372 297.75 73.2342 297.999 73.5124V73.5851C297.751 73.6794 297.44 73.648 297.217 73.648C297.015 73.3705 296.828 73.0545 296.673 72.7456L296.673 72.7435C295.436 70.2202 294.802 67.2846 295.435 64.4563Z"
              fill="#8C96A6"
            />
            <path
              d="M272.329 60.452L271.942 62.2533L276.395 65.3133L284.997 65.0691C285.757 65.0512 286.374 64.4377 286.411 63.7344L286.458 62.8552L287.56 60.7984L286.211 59.7857C285.705 59.4059 285.132 59.1989 284.491 59.1646L277.071 59.1781C276.779 59.1625 276.485 59.2055 276.246 59.3102L272.329 60.452Z"
              fill="#69768C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M283.31 60.6846L286.627 62.5502L286.288 63.1603L283.137 61.3877L276.308 61.5405L272.024 62.5929L271.859 61.9138L276.217 60.8434L283.31 60.6846Z"
              fill="#AAB2C5"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M283.668 59.2011L283.242 62.887L283.642 65.0315L282.959 65.1604L282.54 62.9116L282.978 59.1205L283.668 59.2011Z"
              fill="#AAB2C5"
            />
            <path
              d="M276.395 65.3132L276.017 62.5305L276.174 60.6581L276.365 59.2578"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M276.52 60.6967L276.71 59.3053L276.021 59.2103L275.829 60.6196L275.667 62.5396L276.051 65.3607L276.74 65.2659L276.366 62.5215L276.52 60.6967Z"
              fill="#AAB2C5"
            />
            <path
              opacity="0.6"
              d="M312.664 52.329L312.142 62.1753L311.268 62.1284L310.636 63.035L307.811 54.5962C309.115 53.138 310.738 52.2846 312.428 52.3752C312.489 52.3197 312.605 52.3259 312.664 52.329Z"
              fill="white"
            />
            <path
              opacity="0.6"
              d="M319.315 65.7338C319.296 66.0855 319.278 66.4371 319.262 66.7302L315.527 66.6475L315.574 65.7683L314.672 65.1323L319.38 63.3863C319.34 64.1482 319.358 64.9133 319.315 65.7338Z"
              fill="white"
            />
            <path
              opacity="0.6"
              d="M310.719 66.9773L304.562 70.7025C304.186 68.9781 303.991 67.1456 304.097 65.1529L309.923 65.4652L309.876 66.3443L310.719 66.9773Z"
              fill="white"
            />
            <path
              opacity="0.3"
              d="M276.082 75.6415C277.842 75.5003 279.897 75.3165 279.897 75.3165L270.988 90.1796L267.658 90.1775"
              fill="#69768C"
            />
            <path
              opacity="0.3"
              d="M289.482 56.4933L292.744 56.6682L302.349 41.9016L299.961 41.7735"
              fill="#69768C"
            />
            <path
              d="M296.742 67.2272C296.967 67.2393 297.16 67.0653 297.172 66.8388C297.183 66.6122 297.011 66.4187 296.785 66.4067C296.56 66.3946 296.368 66.5685 296.356 66.795C296.344 67.0217 296.517 67.2151 296.742 67.2272Z"
              fill="#69768C"
            />
            <path
              d="M297.023 69.6519C297.248 69.664 297.441 69.4901 297.452 69.2636C297.464 69.037 297.291 68.8435 297.066 68.8315C296.841 68.8194 296.648 68.9933 296.637 69.2198C296.625 69.4464 296.798 69.6399 297.023 69.6519Z"
              fill="#69768C"
            />
            <path
              d="M297.834 71.9879C298.059 71.9998 298.251 71.8257 298.263 71.5993C298.276 71.3728 298.103 71.1792 297.878 71.1673C297.652 71.1547 297.46 71.3288 297.448 71.5552C297.436 71.7824 297.609 71.9753 297.834 71.9879Z"
              fill="#69768C"
            />
            <path
              d="M297.109 64.7194C297.334 64.7314 297.527 64.5576 297.539 64.3309C297.55 64.1043 297.377 63.9109 297.152 63.8988C296.927 63.8867 296.735 64.0606 296.723 64.2872C296.711 64.5138 296.884 64.7073 297.109 64.7194Z"
              fill="#69768C"
            />
            <path
              d="M298.337 62.4929C298.562 62.5049 298.754 62.3311 298.766 62.1045C298.778 61.8779 298.606 61.6844 298.38 61.6724C298.155 61.6603 297.962 61.8342 297.951 62.0607C297.939 62.2873 298.112 62.4808 298.337 62.4929Z"
              fill="#69768C"
            />
            <path
              opacity="0.6"
              d="M310.938 78.2738L311.168 68.412L312.043 68.4588L312.675 67.5523L315.735 75.9448C314.428 77.4616 312.864 78.3186 311.23 78.2892C311.113 78.2829 311.055 78.2801 310.938 78.2738Z"
              fill="white"
            />
            <path
              d="M311.268 62.1284C310.654 62.6833 308.362 66.2632 311.15 69.8804C314.146 68.4541 316.248 67.3913 316.163 65.6823C316.139 63.9178 311.268 62.1284 311.268 62.1284Z"
              fill="#69768C"
            />
            <g filter="url(#filter5_d_1_61)">
              <path
                d="M180.183 113.88H51.412C49.1088 113.88 47.2244 111.984 47.2244 109.667V22.5317C47.2244 20.2146 49.1088 18.3189 51.412 18.3189H180.183C182.486 18.3189 184.371 20.2146 184.371 22.5317V109.597C184.44 111.984 182.556 113.88 180.183 113.88Z"
                fill="url(#paint5_linear_1_61)"
              />
            </g>
            <g filter="url(#filter6_d_1_61)">
              <path
                d="M54.1949 54.3452C54.1949 58.2358 51.0673 61.3821 47.1663 61.3821C47.0991 61.3821 46.5274 61.3821 42.3237 61.3821C39.3979 61.3821 34.7235 61.3821 27.2578 61.3821H23.6931C19.0186 61.4836 15.2858 57.7283 15.2858 53.1949C15.2858 48.6277 19.0522 44.8386 23.794 45.0754C27.8631 32.2871 46.4938 34.0802 48.0743 47.3083C51.5718 47.7481 54.1949 50.7252 54.1949 54.3452Z"
                fill="url(#paint6_linear_1_61)"
              />
            </g>
            <path
              d="M47.1661 61.3821C51.0334 61.3821 54.1946 58.2358 54.1946 54.3452C54.1946 50.4546 51.0334 47.3083 47.1661 47.3083C43.2987 47.3083 40.1376 50.4546 40.1376 54.3452C40.1376 58.2358 43.2987 61.3821 47.1661 61.3821Z"
              fill="url(#paint7_linear_1_61)"
            />
            <path
              d="M35.6988 61.4498C42.5928 61.4498 48.2089 55.8338 48.2089 48.8984C48.2089 41.963 42.5928 36.3469 35.6988 36.3469C28.8048 36.3469 23.1887 41.963 23.1887 48.8984C23.1887 55.8338 28.7712 61.4498 35.6988 61.4498Z"
              fill="url(#paint8_linear_1_61)"
            />
            <g filter="url(#filter7_d_1_61)">
              <path
                d="M257.078 90.8072C257.078 94.2812 254.285 97.0904 250.802 97.0904C250.743 97.0904 250.232 97.0904 246.479 97.0904C243.866 97.0904 239.692 97.0904 233.027 97.0904H229.844C225.671 97.1805 222.338 93.8282 222.338 89.7804C222.338 85.7026 225.701 82.3195 229.934 82.5306C233.567 71.1128 250.202 72.7134 251.613 84.5241C254.736 84.9169 257.078 87.5752 257.078 90.8072Z"
                fill="url(#paint9_linear_1_61)"
              />
            </g>
            <path
              d="M250.802 97.0904C254.256 97.0904 257.078 94.2812 257.078 90.8072C257.078 87.334 254.256 84.5248 250.802 84.5248C247.349 84.5248 244.527 87.334 244.527 90.8072C244.527 94.2812 247.349 97.0904 250.802 97.0904Z"
              fill="url(#paint10_linear_1_61)"
            />
            <path
              d="M240.564 97.1512C246.719 97.1512 251.734 92.1367 251.734 85.9444C251.734 79.7522 246.719 74.7377 240.564 74.7377C234.409 74.7377 229.394 79.7522 229.394 85.9444C229.394 92.1367 234.378 97.1512 240.564 97.1512Z"
              fill="url(#paint11_linear_1_61)"
            />
            <g filter="url(#filter8_d_1_61)">
              <path
                d="M301.451 109.383C301.451 111.875 299.448 113.89 296.949 113.89C296.906 113.89 296.541 113.89 293.848 113.89C291.975 113.89 288.981 113.89 284.2 113.89H281.917C278.923 113.955 276.532 111.55 276.532 108.647C276.532 105.722 278.945 103.295 281.981 103.447C284.587 95.2569 296.519 96.4054 297.531 104.877C299.771 105.159 301.451 107.066 301.451 109.383Z"
                fill="url(#paint12_linear_1_61)"
              />
            </g>
            <path
              d="M296.949 113.89C299.426 113.89 301.451 111.875 301.451 109.384C301.451 106.892 299.426 104.877 296.949 104.877C294.472 104.877 292.448 106.892 292.448 109.384C292.448 111.875 294.472 113.89 296.949 113.89Z"
              fill="url(#paint13_linear_1_61)"
            />
            <path
              d="M289.605 113.934C294.021 113.934 297.617 110.337 297.617 105.895C297.617 101.454 294.021 97.8571 289.605 97.8571C285.191 97.8571 281.594 101.454 281.594 105.895C281.594 110.337 285.169 113.934 289.605 113.934Z"
              fill="url(#paint14_linear_1_61)"
            />
            <path
              d="M184.393 29.9107H47.2467V22.6531C47.2467 20.1892 49.1312 18.1735 51.4344 18.1735H180.205C182.509 18.1735 184.393 20.1892 184.393 22.6531V29.9107Z"
              fill="url(#paint15_linear_1_61)"
            />
            <path
              d="M53.858 26.1891C54.9758 26.1891 55.8821 25.2774 55.8821 24.1529C55.8821 23.0283 54.9758 22.1167 53.858 22.1167C52.7402 22.1167 51.834 23.0283 51.834 24.1529C51.834 25.2774 52.7402 26.1891 53.858 26.1891Z"
              fill="#A1ACBD"
            />
            <path
              d="M59.9999 26.1891C61.1177 26.1891 62.0239 25.2774 62.0239 24.1529C62.0239 23.0283 61.1177 22.1167 59.9999 22.1167C58.882 22.1167 57.9758 23.0283 57.9758 24.1529C57.9758 25.2774 58.882 26.1891 59.9999 26.1891Z"
              fill="#A1ACBD"
            />
            <path
              d="M66.1415 26.1891C67.2593 26.1891 68.1656 25.2774 68.1656 24.1529C68.1656 23.0283 67.2593 22.1167 66.1415 22.1167C65.0237 22.1167 64.1174 23.0283 64.1174 24.1529C64.1174 25.2774 65.0237 26.1891 66.1415 26.1891Z"
              fill="#A1ACBD"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1_61"
              x="224.821"
              y="44.8268"
              width="27.7869"
              height="27.6549"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_1_61"
              x="270.817"
              y="28.2089"
              width="45.2847"
              height="43.4952"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_1_61"
              x="217.267"
              y="47.0368"
              width="101.449"
              height="37.8029"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_1_61"
              x="216.917"
              y="53.0205"
              width="31.5739"
              height="28.6187"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_d_1_61"
              x="251.267"
              y="61.0866"
              width="50.5579"
              height="43.8179"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <filter
              id="filter5_d_1_61"
              x="25.2244"
              y="7.31886"
              width="181.148"
              height="139.561"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <filter
              id="filter6_d_1_61"
              x="-6.71423"
              y="25.3775"
              width="82.9091"
              height="69.0066"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <filter
              id="filter7_d_1_61"
              x="200.338"
              y="63.7647"
              width="78.7402"
              height="66.3274"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <filter
              id="filter8_d_1_61"
              x="254.532"
              y="86.8765"
              width="68.9185"
              height="60.0151"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_61"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_61"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1_61"
              x1="242.645"
              y1="56.2965"
              x2="247.251"
              y2="57.5849"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1_61"
              x1="295.349"
              y1="42.8064"
              x2="309.929"
              y2="46.8597"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1_61"
              x1="261.255"
              y1="61.7097"
              x2="281.589"
              y2="88.7109"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1_61"
              x1="236.175"
              y1="64.8319"
              x2="242.713"
              y2="67.1012"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1_61"
              x1="277.608"
              y1="75.8092"
              x2="295.005"
              y2="81.4798"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B0BACC" />
              <stop offset="1" stop-color="#969EAE" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1_61"
              x1="115.754"
              y1="16.1085"
              x2="115.754"
              y2="114.91"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FDFEFF" />
              <stop offset="0.9964" stop-color="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_1_61"
              x1="34.7403"
              y1="46.7112"
              x2="34.7274"
              y2="61.6537"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="0.9964" stop-color="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_1_61"
              x1="39.3896"
              y1="45.7932"
              x2="45.661"
              y2="52.6113"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#BCCBE1" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_1_61"
              x1="22.0173"
              y1="43.1389"
              x2="31.853"
              y2="46.9649"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E2E8F0" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_1_61"
              x1="239.708"
              y1="83.9915"
              x2="239.697"
              y2="97.3329"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="0.9964" stop-color="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_1_61"
              x1="243.859"
              y1="83.1716"
              x2="249.459"
              y2="89.2591"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#BCCBE1" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_1_61"
              x1="228.348"
              y1="80.802"
              x2="237.13"
              y2="84.2179"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E2E8F0" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_1_61"
              x1="288.992"
              y1="104.495"
              x2="288.983"
              y2="114.064"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="0.9964" stop-color="#ECF0F5" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_1_61"
              x1="291.969"
              y1="103.907"
              x2="295.985"
              y2="108.273"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#BCCBE1" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_1_61"
              x1="280.843"
              y1="102.207"
              x2="287.142"
              y2="104.657"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E2E8F0" />
              <stop offset="0.9942" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint15_linear_1_61"
              x1="52.4577"
              y1="27.6097"
              x2="179.956"
              y2="23.811"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D5DDEA" />
              <stop offset="1" stop-color="#D5DDEA" stop-opacity="0" />
            </linearGradient>
            <clipPath id="clip0_1_61">
              <rect width="321" height="137" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <h5>Looks like there's nothing here right now</h5>
    </div>
  );
};
