import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { Col } from "react-bootstrap";
import Inspirations from "./inspirations";
import { VendorsCategory } from "./vendorscategory";
import { SideBar } from "./sidebar.js";
import { NotificationsCanvas } from "../../notifications_canvas";

export const MainDashboard = ({ search, setSearch }) => {
  const navigate = useNavigate();
  const [customerMethod, setCustomerMethod] = useState("vendors");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const image = localStorage.getItem("profileImage");

  return (
    <Col md={9} xl={10} className="p-0">
      <div className="px-4 d-flex justify-content-center align-items-center d-md-none vh-10 bg-white">
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"root"} />
        <div className="d-flex poppins-font">
          <h6 className="fs-20 fw-bold mb-0">Afric</h6>
          <h6 className="fs-20 mb-0 fw-normal">Weddings</h6>
        </div>
        <img
          src={image}
          alt="profile"
          className="rounded-circle"
          style={{
            width: "40px",
            height: "40px",
            position: "absolute",
            right: "20px",
          }}
        />
      </div>

      <div className="Header Header-sm bg-dark d-flex justify-content-evenly flex-column">
        <div className="px-4 justify-content-end align-items-center d-none d-md-flex">
          <button
            type="button"
            className="btn btn-transparent position-relative me-3 p-0 d-flex align-items-center"
            onClick={() => setShow(true)}
          >
            <i className="iconly-Activity icli fs-20 text-white"></i>
            {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
              9+
              <span className="visually-hidden">unread messages</span>
            </span> */}
          </button>
          <Link to="/messagelist" className="no-underline">
            <button
              type="button"
              className="btn btn-transparent position-relative me-4 p-0 d-flex align-items-center"
            >
              <i className="iconly-Chat icli fs-20 text-white"></i>
              {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                9+
                <span className="visually-hidden">unread messages</span>
              </span> */}
            </button>
          </Link>

          <img
            src={image ? image : "https://via.placeholder.com/100"}
            className="rounded-circle"
            height="35"
            width="35"
            alt="account"
          />
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div
            style={{ backgroundColor: "#3B3B3B" }}
            className="input-group w-75 searchbar rounded text-small"
          >
            <span
              className="input-group-text text-white bg-transparent border-0"
              id="search-addon"
            >
              <BiSearch />
            </span>
            <input
              onFocus={() => navigate("/dashboard/search")}
              type="search"
              className="form-control bg-transparent border-0 text-white shadow-sm text-white Place-Holder"
              placeholder="Search For A Vendor"
              aria-label="Search"
              aria-describedby="search-addon"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="vh-75 vh-xs-65">
        <div
          className="d-flex justify-content-center border-left"
          style={{ height: "60px" }}
        >
          <div
            className={`px-5 py-3 w-1/2 Vendors  ${
              customerMethod === "vendors"
                ? "text-danger border-bottom border-danger"
                : ""
            } text-dark-400`}
            checked={customerMethod === "vendors"}
            onClick={() => setCustomerMethod("vendors")}
          >
            Vendors
          </div>
          <div
            className={`px-5 py-3 w-1/2  Vendors ${
              customerMethod === "inspirations"
                ? "text-danger border-bottom border-danger "
                : ""
            } text-dark-400`}
            checked={customerMethod === ""}
            onClick={() => setCustomerMethod("inspirations")}
          >
            Inspiration
          </div>
        </div>
        {customerMethod === "vendors" ? <VendorsCategory /> : null}
        {customerMethod === "inspirations" ? <Inspirations /> : null}
      </div>
      <Col className="p-0 d-md-none vh-10 w-100" xl={2} md={3} id="side-bar">
        <ul className="nav d-flex justify-content-around align-items-center h-100">
          <li className="nav-item">
            <NavLink
              to="/dashboard"
              className="nav-link text-dark"
              aria-current="page"
            >
              <div className="text-center">
                <i className={`iconly-Home icli fs-3 align-middle`}></i>
              </div>
              <span className="align-middle text-smaller">Home</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/messagelist"
              className="nav-link text-dark"
              aria-current="page"
            >
              <div className="text-center">
                <i className={`iconly-Chat icli fs-3 align-middle`}></i>
              </div>
              <span className="align-middle text-smaller">Messages</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/myvendors"
              className="nav-link text-dark"
              aria-current="page"
            >
              <div className="text-center">
                <i
                  className="iconly-User3 icli fs-3 align-middle"
                  aria-hidden="true"
                ></i>
              </div>
              <span className="align-middle text-smaller">My Vendors</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/profile"
              className="nav-link text-dark"
              aria-current="page"
            >
              <div className="text-center">
                <i
                  className="fa fa-user-o w-100 fs-5 align-middle h-100 mb-2px"
                  aria-hidden="true"
                ></i>
              </div>
              <span className="align-middle text-smaller">Profile</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/settings"
              className="nav-link text-dark"
              aria-current="page"
            >
              <div className="text-center">
                <i className={`iconly-Setting icli fs-3 align-middle`}></i>
              </div>
              <span className="align-middle text-smaller">Settings</span>
            </NavLink>
          </li>
        </ul>
      </Col>

      <NotificationsCanvas show={show} handleClose={handleClose} />
    </Col>
  );
};
