import React from "react";
import { Link } from "react-router-dom";

const Layout = ({ children }) => {
  return (
    <div className="container-fluid bg-white">
      <div className="row vh-100">
        <div className="col-md-6 col-12 d-none d-md-block LayoutPicture">
          <Link to="/">
            <h3 className="Logo">
              <b>Afric</b>Weddings
            </h3>
          </Link>
          <p className="LayoutText">
            Access Wedding Inspiration From <br /> All Over Africa
          </p>
        </div>
        <div className="col-md-6 col-12 px-lg-4 align-self-center justify-content-center w-form-responsive mx-auto">
          <div className="container">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
