import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import styled from "@emotion/styled";

function valuetext(value) {
  return `${value}°C`;
}

const BudgetSlider = styled(Slider)({
  color: "#E00253",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
});

export default function RangeSlider({ value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{}}>
      <BudgetSlider
        getAriaLabel={() => "Temperature range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0}
        max={100000}
        step={250}
        disableSwap
      />
    </Box>
  );
}
