import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { Api } from "../../../axiosInstance";
import { TextInput } from "../../../components/common/input";
import CustomButton from "../../../components/custom-button/custom-button";
import { Container, Row } from "react-bootstrap";
import { CircularLoading } from "../../../components/loading/CircularLoading";
import { BaseContainer } from "../../../components/dashboard/Containers";
import { Header } from "../../../components/dashboard/customer-dashboard/Inspiration/Header";

export const EditProfile = () => {
  const [imageUpload, setImageUpload] = useState();
  const [loading, setLoading] = useState("idle");

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    setSubmitting(true);
    try {
      delete values.email;
      const formData = new FormData();
      formData.append("fullName", values.fullName);
      if (imageUpload) formData.append("image", imageUpload);
      formData.append("phone", values.phone);
      formData.append("about", values.about);
      const response = await Api.patch(`/user`, formData);
      toast.success(response?.data.message);
      // resetForm();
      await getData();
      setTimeout(() => {}, 3000);
      setSubmitting(false);
      window.location.reload();
    } catch (e) {
      setSubmitting(false);
      toast.error(e);
      throw e;
    }
  };

  const [state, setState] = useState({
    fullName: "",
    email: "",
    phone: "",
    about: "",
  });

  async function getData() {
    try {
      setLoading("loading");
      const response = await Api.get("/user");
      const { fullName, email, phone, about } = response.data.data;
      setState({
        fullName,
        email,
        phone,
        about,
      });
      localStorage.setItem("fullName", fullName);
      setLoading("success");
      // window.location.reload
    } catch (e) {
      setLoading("error");
    }
  }

  useEffect(() => {
    getData();
    return () => setLoading("idle");
  }, []);

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <Header title="Edit Profile" />
        <div className="VCDiv vh-85 d-flex justify-content-center p-sm-0 p-md-4 px-md-5 y-scroll p-0 vh-sm-75">
          <Row className="bg-white rounded p-4 y-scroll w-100">
            <Formik
              initialValues={state}
              enableReinitialize
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                await handleSubmit(values, resetForm, setSubmitting);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Container>
                    {loading === "loading" && (
                      <Row>
                        <CircularLoading /> Fetching details...
                      </Row>
                    )}
                    {loading === "error" && (
                      <Row>
                        Something went wrong! Reload this page to try again.
                      </Row>
                    )}
                    <Row className="gy-md-2 gy-lg-5">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <label htmlFor="file-input">
                          <div
                            className="image-upload"
                            style={{
                              color: "#e00253",
                              background: "#FEF2F6",
                              width: "100px",
                              height: "100px",
                              display: "flex",
                              overflow: "hidden",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                              cursor: "pointer",
                              padding: 15,
                            }}
                          >
                            {!imageUpload && <i className="fa fa-camera" />}
                            {imageUpload && (
                              <img
                                style={{
                                  width: 100,
                                  height: 100,
                                  objectFit: "cover",
                                }}
                                src={URL.createObjectURL(imageUpload)}
                                alt="profileImage"
                              />
                            )}
                          </div>
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          className="d-none"
                          onChange={(e) => setImageUpload(e.target.files[0])}
                        />
                      </div>
                      <Col lg={6} className="mt-md-4">
                        <TextInput
                          label="Full Name"
                          name="fullName"
                          type="text"
                          className=""
                          placeholder="Enter Name"
                          required
                        />
                      </Col>
                      <Col lg={6} className="mt-md-4">
                        <TextInput
                          label="Email"
                          name="email"
                          type="email"
                          className=""
                          placeholder="Enter Email"
                          // disabled
                          required
                          disabled={true}
                        />
                      </Col>
                      <Col lg={6}>
                        <TextInput
                          label="Phone Number"
                          name="phone"
                          type="text"
                          className=""
                          placeholder="Enter Phone Number"
                          required
                        />
                      </Col>
                      <Col lg={6}>
                        <label className="font-weight-bold">About </label>
                        <textarea
                          style={{ width: "100%" }}
                          // value={this.state.textAreaValue}
                          // onChange={this.handleChange}
                          rows={5}
                          placeholder="Enter something about yourself"
                          className="mt-2 rounded border p-2"
                          resize="none"
                        />
                      </Col>
                      <div className="d-flex justify-content-center mt-2">
                        <div className="w-50 w-sm-100">
                          <CustomButton type="submit" disabled={isSubmitting}>
                            {isSubmitting ? <CircularLoading /> : "Save"}
                          </CustomButton>
                        </div>
                      </div>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          </Row>
        </div>

        <Col className="p-0 d-md-none vh-10 w-100" xl={2} md={3} id="side-bar">
          <ul className="nav d-flex justify-content-around align-items-center h-100">
            <li className="nav-item">
              <NavLink
                to="/dashboard"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i className={`iconly-Home icli fs-3 align-middle`}></i>
                </div>
                <span className="align-middle text-smaller">Home</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/messagelist"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i className={`iconly-Chat icli fs-3 align-middle`}></i>
                </div>
                <span className="align-middle text-smaller">Messages</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/myvendors"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i
                    className="iconly-User3 icli fs-3 align-middle"
                    aria-hidden="true"
                  ></i>
                </div>
                <span className="align-middle text-smaller">My Vendors</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/profile"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i
                    className="fa fa-user-o w-100 fs-5 align-middle h-100 mb-2px"
                    aria-hidden="true"
                  ></i>
                </div>
                <span className="align-middle text-smaller">Profile</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/settings"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i className={`iconly-Setting icli fs-3 align-middle`}></i>
                </div>
                <span className="align-middle text-smaller">Settings</span>
              </NavLink>
            </li>
          </ul>
        </Col>
      </Col>
    </BaseContainer>
  );
};
