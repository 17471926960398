import React, { useState, useEffect } from "react";
import { Modal, Form as Formee } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";

import {
  firestore,
  sendMessage,
  addToMessages,
} from "../../../../services/firebase";
import {
  query,
  collection,
  limit,
  where,
  onSnapshot,
} from "firebase/firestore";

import { Api } from "../../../../axiosInstance";
import { fetchCategories } from "../../../../services/customer/Inspirationboard";
import { TextInput } from "../../../common/input";
import { CircularLoading } from "../../../loading/CircularLoading";
import { Container, Col, Row } from "react-bootstrap";

export const EnquiryModal = ({ show, handleClose, id, vendorName }) => {
  const [categories, setCategories] = useState([]);
  const [messageThread, setMessageThread] = useState([]);
  const [messageId, setMessageId] = useState("");

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    const _id =
      window &&
      window.sessionStorage &&
      window.sessionStorage.getItem("userId");

    onSnapshot(
      query(
        collection(firestore, "messages"),
        where("members", "array-contains", id),
        limit(50)
      ),
      (querySnapshot) => {
        const messages = querySnapshot.docs
          .map((doc) => {
            setMessageId(doc.id);

            return {
              id: doc.id,
              ...doc.data(),
            };
          })
          .filter((message) => {
            return (
              message.members.includes(_id) && message.members.includes(id)
            );
          });
        console.log(messages);
        setMessageThread(messages);
      }
    );
  }, [id]);

  const _id =
    window && window.sessionStorage && window.sessionStorage.getItem("userId");

  const getCategories = async () => {
    try {
      const response = await fetchCategories();
      const { data } = response.data;
      setCategories(data);
    } catch (e) {
      console.log(e);
    }
  };

  const categoryList = categories.map((category) => {
    return (
      <option key={category._id} value={category.name}>
        {category.name}
      </option>
    );
  });

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    setSubmitting(true);
    try {
      const response = await Api.post("/bookings", values);

      // console.log(values);
      const fullName = localStorage.getItem("fullName");

      messageThread.length === 0
        ? sendMessage(
            _id,
            values.vendorId,
            "text",
            `Hi ${vendorName}, ${fullName} is interested in ${values.service}. The event is scheduled for ${values.timeOfEvent} for ${values.startTime} - ${values.endTime}. ${fullName} wants you to cater to  ${values.noOfGuests} guests and has a budget of ${values.budget} and the location of the event is at ${values.location}. Please let ${fullName} know if it's something you can handle.`
          )
        : addToMessages(
            _id,
            values.vendorId,
            "text",
            `Hi ${vendorName}, ${fullName} is interested in ${values.service}. The event is scheduled for ${values.timeOfEvent} for ${values.startTime} - ${values.endTime}. ${fullName} wants you to cater to  ${values.noOfGuests} guests and has a budget of ${values.budget} and the location of the event is at ${values.location}. Please let ${fullName} know if it's something you can handle.`,
            messageId
          );

      toast.success(response?.data.message);
      resetForm();
      setSubmitting(false);
      handleClose();
    } catch (e) {
      setSubmitting(false);
      toast.error(e);
    }
  };

  const state = {
    vendorId: id,
    timeOfEvent: "",
    startTime: "",
    endTime: "",
    location: "",
    service: "",
    budget: "",
    noOfGuests: "",
    // message: "",
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="align-items-center text-center enquiry-modal"
    >
      <Formik
        initialValues={state}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleSubmit(values, resetForm, setSubmitting);
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form>
            <Modal.Body style={{ maxWidth: 500 }}>
              <b className="fs-6">Make an Enquiry</b>
              <div className="d-flex align-items-center justify-content-center">
                <p className="text-small my-4 text-muted w-50">
                  This will help {vendorName} understand more about your event.
                </p>
              </div>
              <Container>
                <Row>
                  <Col sm={12}>
                    <TextInput
                      label="When is your event"
                      name="timeOfEvent"
                      type="date"
                      className=""
                      placeholder=""
                      required
                    />
                  </Col>
                  <Col md="6">
                    <TextInput
                      label="Start Time"
                      name="startTime"
                      type="time"
                      className=""
                      placeholder="11:00"
                      required
                    />
                  </Col>
                  <Col md="6">
                    <TextInput
                      label="End Time"
                      name="endTime"
                      type="time"
                      className=""
                      placeholder="11:00"
                      required
                    />
                  </Col>
                  <Col sm={12}>
                    <TextInput
                      label="Where is your event being hosted?"
                      name="location"
                      type="text"
                      className=""
                      placeholder="Enter Location"
                      required
                    />
                  </Col>
                  <Col sm={12}>
                    <Formee.Label className="fs-14 mb-2 fw-bold">
                      What Service Would You Like?
                    </Formee.Label>
                    <Formee.Select
                      className="SelectOption mb-3 form-control form-control-0 ps-3 py-3"
                      onChange={handleChange}
                      name="service"
                    >
                      <option>Select Service</option>
                      {categoryList}
                    </Formee.Select>
                  </Col>
                  <Col sm={12}>
                    <TextInput
                      label="What's your budget?"
                      name="budget"
                      type="text"
                      className=""
                      placeholder="Amount"
                      required
                    />
                  </Col>
                  <Col sm={12}>
                    <TextInput
                      label="Number of Guests"
                      name="noOfGuests"
                      type="number"
                      className="mb-4"
                      placeholder="Enter Number of Guests"
                      required
                    />
                  </Col>
                  {/* <div className="d-flex flex-column mb-4">
                <label>Additional Message</label>
                <textarea
                name="message"
                  value={values.message}
                  onChange={handleChange}
                  placeholder="Enter message"
                  className="mt-2 h-100 rounded border p-2"
                  />
                </div> */}
                  <div className="text-right">
                    <button
                      className="w-auto btn btn-theme fw-bold p-3"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularLoading /> : "Make Enquiry"}
                    </button>
                  </div>
                </Row>
              </Container>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
