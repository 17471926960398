import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Cookies from "universal-cookie";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC-QU7LDgOJIwThm7cWgy9ErmZwTPonV-8",
  authDomain: "africweddings-c366d.firebaseapp.com",
  projectId: "africweddings-c366d",
  storageBucket: "africweddings-c366d.appspot.com",
  messagingSenderId: "1032232844030",
  appId: "1:1032232844030:web:1547e78f32140c127243af",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  alert("Message received. ", payload);
  // Customize notification here
  // const notificationTitle = "Background Message Title";
  // const notificationOptions = {
  //   body: "Background Message body.",
  //   icon: "/firebase-logo.png",
  // };

  // self.registration.showNotification(
  //   notificationTitle,
  //   notificationOptions
  // );
});

export const BaseContainer = ({ children }) => {
  const cookies = new Cookies();
  const jwt = cookies.get("jwt");
  if (!jwt) window.location.href = "/signin";

  const Logout = () => {
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    sessionStorage.removeItem("userId");

    window.location.href = "/signin";
  };

  const fullName = localStorage.getItem("fullName");
  const profileImage = localStorage.getItem("profileImage");

  return (
    <Row className="m-0">
      <Col className="p-0 d-md-block d-none" xl={2} md={3} id="side-bar">
        <div className="bg-white vh-100">
          <div className="d-flex flex-column flex-shrink-0 bg-light w-100 pt-v-5 side-bar bg-white justify-content-between vh-100">
            <div className="h-100">
              <div className="mb-3 px-3">
                <a href="/" className="text-decoration-none">
                  <h5 className="mb-1 text-dark">
                    <span className="fw-bolder">Afric</span>
                    <span className="fw-normal">Weddings</span>
                  </h5>
                </a>
                <p className="fs-12">Built For African Weddings</p>
              </div>

              <div className="card mb-4 mx-3 profile-card shadow-sm">
                <Link to="/profile" className="no-underline">
                  <div className="card-body text-dark">
                    <div className="row px-2 align-items-center">
                      <div className="col-3 ps-0">
                        <img
                          src={
                            profileImage
                              ? profileImage
                              : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                          }
                          className="rounded-circle"
                          height="45"
                          width="45"
                          alt="account"
                        />
                      </div>
                      <div className="col-9 pe-0">
                        <p className="fs-14 fw-bold mb-0">{fullName}</p>
                        {/* <p className="ft-2 fs-12 mb-1">Basic Account</p> */}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="px-3">
                <p className="fs-12 text-muted fw-bold mb-2">MENU</p>
              </div>
              <ul className="nav flex-column mb-3 justify-content-around side-specific-height">
                <li className="nav-item">
                  <NavLink
                    to="/dashboard"
                    className="nav-link text-dark"
                    aria-current="page"
                  >
                    <i
                      className={`iconly-Home icli fs-20 align-middle me-3`}
                    ></i>
                    <span className="align-middle fs-14">Home</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/inspirationboard"
                    className="nav-link text-dark"
                    aria-current="page"
                  >
                    <i
                      className={`iconly-Category icli fs-20 align-middle me-3`}
                    ></i>
                    <span className="align-middle fs-14">
                      Inspiration board
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/projects"
                    className="nav-link text-dark"
                    aria-current="page"
                  >
                    <i
                      className={`iconly-Document icli fs-20 align-middle me-3`}
                    ></i>
                    <span className="align-middle fs-14">Projects</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/myvendors"
                    className="nav-link text-dark"
                    aria-current="page"
                  >
                    <i
                      className="iconly-User3 icli fs-20 align-middle me-3"
                      aria-hidden="true"
                    ></i>
                    <span className="align-middle">My Vendors</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/settings"
                    className="nav-link text-dark"
                    aria-current="page"
                  >
                    <i
                      className={`iconly-Setting icli fs-20 align-middle me-3`}
                    ></i>
                    <span className="align-middle fs-14">Settings</span>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <ul className="nav flex-column logout-btn">
                <li className="nav-item h-100">
                  <button
                    onClick={Logout}
                    className="nav-link border-top border-bottom w-100 bg-light logout-button h-100 grey-bg border-0"
                    aria-current="page"
                  >
                    <i className="fa-solid fa-arrow-right-from-bracket align-middle me-3"></i>
                    <span className="align-middle fs-14 text-dark fw-bold text-small">
                      LOG OUT
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Col>
      {children}
    </Row>
  );
};
