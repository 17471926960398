import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { Form as CheckForm } from "react-bootstrap";
// import { BsFacebook } from "react-icons/bs";
// import { FcGoogle } from "react-icons/fc";
import { toast } from "react-toastify";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import { CircularLoading } from "../../../components/loading/CircularLoading.js";
import Layout from "../../../components/common/layout";
import { TextInput } from "../../../components/common/input";
import CustomButton from "../../../components/custom-button/custom-button";
// import { Phone } from "../../../components/common/input";

import { Api } from "../../../axiosInstance";
import {
  Lock,
  Message,
  User2,
} from "../../../components/iconly-icons/index.js";
// import { GrCheckbox } from "react-icons/gr";

const SIGNUP_URL = "/auth/register";

const SignUp = (props) => {
  const {
    state: { accountType },
  } = useLocation();

  const initialState = {
    fullName: "",
    email: "",
    phone: "",
    password: "",
    role: accountType,
  };
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);

  console.log(accountType);

  const navigate = useNavigate();

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    if (!checked) {
      toast.error("Please agree to the terms of conditions");
      return;
    }
    values.phone = value;
    values.email = values.email.toLowerCase();
    setSubmitting(true);
    try {
      const response = await Api.post(SIGNUP_URL, values);
      if (response?.data?.success) {
        if (accountType === "vendor")
          window.location.href = `${process.env.REACT_APP_VENDOR_REDIRECT_URL}/category?email=${values.email}`;
        else if (accountType === "customer") {
          toast.success(response?.data.message);
          resetForm();
          navigate(`/verify?email=${values.email}&role=customer`);
        }
      } else {
        toast.error(response);
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      if (e.response) {
        if (e.response?.data) {
          setError(e.response.data?.message);
          return;
        }
        setError("An error occurred. Please try again.");
        return;
      }
    }
  };
  return (
    <Layout>
      <Formik
        initialValues={initialState}
        onSubmit={async (values, { setSubmitting, resetForm }) =>
          await handleSubmit(values, resetForm, setSubmitting)
        }
      >
        {({ isSubmitting }) => (
          <Form>
            <p className="text-end">
              Already Have An Account?
              <Link
                to="/signin"
                className="text-theme ms-2"
                style={{
                  color: accountType === "vendor" ? "#6f0463" : "#e00253",
                }}
              >
                Log In
              </Link>
            </p>
            <div className="py-4">
              <h2 className="fw-bolder">Create Account</h2>
              <p>Joining us is quite easy, let's get you started.</p>

              <div style={{ color: "red", paddingBottom: "10px" }}>{error}</div>

              {/* <div className="ContinueButtons">
                      <button
                        type="submit"
                        className="facebook"
                      >
                        <span className="w-24">
                          <BsFacebook className="mt-1" />
                        </span>
                        Continue with Facebook
                      </button>
                      <button
                        type="submit"
                        className="google"
                      >
                        <span className="w-24">
                          <FcGoogle className="mt-1" />
                        </span>
                        Continue with Google
                      </button>
                    </div>
            <br/>
                    <hr/> */}

              <TextInput
                label="Full Name"
                name="fullName"
                type="text"
                className="EachTextInput"
                placeholder="Enter Your Full Name"
                iconImage={<User2 />}
                required
              />

              <TextInput
                label="Email Address"
                name="email"
                type="email"
                className="EachTextInput"
                placeholder="Enter Your Email Address"
                iconImage={<Message />}
                required
              />

              <p className="PhoneNumberText">Phone Number</p>
              <PhoneInput
                defaultCountry="NG"
                label="Phone Number"
                placeholder="Enter phone number"
                withCountryCallingCode
                international
                countryCallingCodeEditable={false}
                initialValueFormat="national"
                value={value}
                onChange={setValue}
                className="form-control form-control-0 ps-3 py-3 mb-3"
                error={
                  value
                    ? isPossiblePhoneNumber(value)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
              />

              <TextInput
                label="Password"
                type="password"
                name="password"
                className="EachTextInput"
                placeholder="Enter Your Password"
                iconImage={<Lock />}
                required
              />

              <CheckForm>
                <CheckForm.Check
                  inline
                  label="I have read and agreed to AfricWeddings Terms of Service and
                  privacy policy."
                  name="group1"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </CheckForm>
            </div>

            {/* disabled={!validName || !validEmail || !validNumber || !validPwd ? true : false} */}
            <CustomButton
              type="submit"
              disabled={isSubmitting}
              style={{
                backgroundColor:
                  accountType === "vendor" ? "#6f0463" : "#e00253",
              }}
            >
              {isSubmitting ? <CircularLoading /> : "Create Account"}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default SignUp;
