import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { BsGeoAlt } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { AiFillStar } from "react-icons/ai";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Col, Card, Placeholder } from "react-bootstrap";
import { Api } from "../../../../axiosInstance";
import CustomSelect from "../../../common/CustomSelect";
import { Offcanvas } from "react-bootstrap";
import RangeSlider from "./slider";
import { FormatMoney } from "../../../../utils/formatMoney";
import { Container } from "react-bootstrap";
import { DefaultProfileImage } from "../../../common/DefaultProfileImage";
// import { fetchCategories } from "../../../../services/customer/Inspirationboard";
import { BaseContainer } from "../../Containers";
import { NotificationsCanvas } from "../../notifications_canvas";
import { EmptyState } from "../../../empty_states/empty";

const countryOptions = [
  { value: "nigeria", label: "Nigeria" },
  { value: "united kingdom", label: "United Kingdom" },
  { value: "united states", label: "United States" },
];

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const VendorSearch = ({ search, setSearch, ...props }) => {
  let navigate = useNavigate();

  let query = useQuery();
  const category = query.get("category");
  const handleClose = () => {
    setShow(false);
    setShow2(false);
  };
  const handleShow = () => setShow(true);

  const categorizedUrl = category
    ? `/vendors?page=1&categories=${encodeURI(category)}`
    : "/vendors?page=1";

  const [options, setOptions] = useState({
    categories: [],
    country: "",
  });
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [value, setValue] = React.useState([20000, 100000]);
  const [categoriesFetched, setCategoriesFetched] = useState([]);
  const [vendorsFetched, setVendorsFetched] = useState([]);
  const [url, setUrl] = useState(categorizedUrl);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Api.get(url)
      .then((response) => {
        setVendorsFetched(response.data?.data.results);

        Api.get("/vendors/vendor-categories")
          .then((response) => {
            setCategoriesFetched(response.data?.data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [url]);

  const extract = () => {
    setIsLoading(true);
    let categories = options.categories.join(",");
    let country = options.country;

    if (categories) setUrl(`/vendors?page=1&categories=${categories}`);
    if (country) setUrl(`/vendors?page=1&location=${country}`);

    setShow(false);
  };

  const clear = () => {
    // setIsLoading(true);
    // setOptions({
    //   categories: [],
    //   country: "",
    // });
    // setUrl(categorizedUrl);
    // setShow(false);
    navigate("/dashboard/search");
  };

  const setSearchString = (value) => {
    setIsLoading(true);
    setUrl(`${categorizedUrl}&search=${value}`);
  };

  const image = localStorage.getItem("profileImage");

  const placeholderArray = [1, 2, 3, 4, 5, 6];
  const placeholder = placeholderArray.map((item) => {
    return (
      <Card
        className="border-0 mb-4 d-flex align-items-center justify-content-between shadow-sm"
        key={item}
        style={{ height: 120 }}
      >
        <Card.Body className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Placeholder
              xs={4}
              style={{
                height: "75px",
                width: "75px",
              }}
              className="rounded"
            />
            <div
              className="d-flex flex-column justify-content-between"
              style={{ height: 75 }}
            >
              <Placeholder
                xs={12}
                className="ms-3"
                style={{ width: 130, height: 30 }}
              />
              <Placeholder xs={12} className="ms-3" style={{ width: 130 }} />
              <Placeholder xs={12} className="ms-3" style={{ width: 130 }} />
            </div>
          </div>

          <Placeholder xs={4} style={{ width: "50px", borderRadius: 15 }} />
        </Card.Body>
      </Card>
    );
  });

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <div className="Header header2 bg-dark flex-column d-none d-md-flex">
          <div className="px-4 w-100 justify-content-between align-items-center d-flex">
            <i
              className="iconly-Arrow-Left-Circle icli caret-holder cursor-pointer"
              style={{ fontSize: "30px" }}
              onClick={() => navigate(-1)}
            ></i>
            <div className="justify-content-end align-items-center d-flex">
              <button
                type="button"
                className="btn btn-transparent position-relative me-3 p-0 d-flex align-items-center"
                onClick={() => setShow2(true)}
              >
                <i className="iconly-Activity icli fs-20 text-white"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                  9+
                  <span className="visually-hidden">unread messages</span>
                </span>
              </button>
              <Link to="/messagelist" className="no-underline">
                <button
                  type="button"
                  className="btn btn-transparent position-relative me-4 p-0 d-flex align-items-center"
                >
                  <i className="iconly-Chat icli fs-20 text-white"></i>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                    9+
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </button>
              </Link>

              <img
                src={image ? image : "https://via.placeholder.com/100"}
                className="rounded-circle"
                height="35"
                width="35"
                alt="account"
              />
            </div>
          </div>
          <h5 style={{ fontWeight: "bolder" }} className="poppins-font">
            {category || "All Vendors"}
          </h5>
          <div className="d-flex w-100 justify-content-center mt-4">
            <div
              style={{ backgroundColor: "#fff", width: "40%" }}
              className="input-group searchbar"
            >
              <span
                className="input-group-text bg-transparent border-0"
                id="search-addon"
              >
                <BiSearch />
              </span>
              <input
                type="search"
                className="form-control bg-transparent border-0 shadow-sm"
                placeholder="Search for a vendor"
                aria-label="Search"
                aria-describedby="search-addon"
                autoFocus
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
            &nbsp; &nbsp;
            <div
              style={{ backgroundColor: "#fff", width: "25%" }}
              className="input-group pl-4 searchbar"
            >
              <span
                className="input-group-text bg-transparent border-0"
                id="search-addon"
              >
                <BsGeoAlt />
              </span>
              <input
                type="search"
                className="form-control bg-transparent border-0 shadow-sm text-dark"
                placeholder="Lagos, Nigeria"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
            &nbsp; &nbsp;
            <div
              onClick={handleShow}
              role="button"
              className="d-inline-flex mt-2 pointer ms-2"
            >
              <FiSettings className="mt-2" />
              &nbsp;
              <p className="mt-1">Filter</p>
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdropClassName=""
              className="Back-drop h-75 p-0 p-md-3 vh-sm-70"
              style={{ top: "unset", width: "470px" }}
            >
              <Offcanvas.Body>
                <div className="VendorScroll">
                  <div className="d-flex  justify-content-between mb-2">
                    <div className="d-flex flex-column justify-content-center ">
                      <h5 className="mb-0 fw-bold">FILTERS</h5>
                    </div>
                    <div className="">
                      <button
                        className="btn btn-danger rounded me-3"
                        onClick={extract}
                      >
                        Apply
                      </button>
                      <button
                        className="btn rounded text-danger bg-transparent"
                        onClick={clear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="">
                    <div className="card d-block h-50 py-4 border-0 mb-4">
                      <p className="fs-6 m-0 fw-bold">Categories</p>
                      {categoriesFetched.map((categ, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            if (
                              options.categories.filter((c) => c === categ.name)
                                .length
                            ) {
                              setOptions({
                                ...options,
                                categories: [
                                  ...options.categories.filter(
                                    (c) => c !== categ.name
                                  ),
                                ],
                              });
                              return;
                            }
                            setOptions({
                              ...options,
                              categories: [...options.categories, categ.name],
                            });
                          }}
                          style={{
                            backgroundColor: options.categories.filter(
                              (c) => c === categ.name
                            ).length || categ.name === category
                              ? "#E00253"
                              : "#E0E0E0",
                            color: options.categories.filter(
                              (c) => c === categ.name
                            ).length || categ.name === category
                              ? "#fff"
                              : "#000",
                          }}
                          role="button"
                          className="rounded-pill fs-6 px-4 py-2 me-3 mt-3 d-inline-block"
                        >
                          {categ.name}
                        </div>
                      ))}
                    </div>
                    <div className="card py-4 border-0 mb-4">
                      <p>Budget</p>
                      <RangeSlider {...{ value, setValue }} />
                      <p>
                        {FormatMoney(value[0])} - {FormatMoney(value[1])}
                      </p>
                    </div>
                    <div className="card py-4 border-0 mb-4">
                      <CustomSelect
                        className={"SelectOption"}
                        label="Country"
                        labelClass="PersonalText LA"
                        // iconImage={<User2 />}
                        options={countryOptions}
                        placeholder="Select A Country"
                        name="account"
                        onChange={(e) =>
                          setOptions({ ...options, country: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>

        <div className="px-4 Header header2 bg-dark flex-column d-md-none justify-content-end vh-30">
          <div className="w-100 justify-content-between align-items-center d-flex mb-3">
            <div className="d-flex align-items-center">
              <i
                className="iconly-Arrow-Left-Circle icli caret-holder cursor-pointer me-2"
                style={{ fontSize: "30px" }}
                onClick={() => navigate.goBack()}
              ></i>
              <h5 style={{ fontWeight: "bolder" }} className="poppins-font m-0">
                {category || "All Vendors"}
              </h5>
            </div>
            <div
              onClick={handleShow}
              role="button"
              className="d-inline-flex pointer"
            >
              <svg
                id="Iconly_Light_Setting"
                data-name="Iconly/Light/Setting"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g id="Setting" transform="translate(2.5 1.5)">
                  <path
                    id="Path_33946"
                    d="M17.528,5.346l-.622-1.08a1.913,1.913,0,0,0-2.609-.7h0a1.9,1.9,0,0,1-2.609-.677,1.831,1.831,0,0,1-.256-.915h0A1.913,1.913,0,0,0,9.519,0H8.265a1.9,1.9,0,0,0-1.9,1.913h0A1.913,1.913,0,0,1,4.448,3.8a1.831,1.831,0,0,1-.915-.256h0a1.913,1.913,0,0,0-2.609.7l-.668,1.1a1.913,1.913,0,0,0,.7,2.609h0a1.913,1.913,0,0,1,.957,1.657,1.913,1.913,0,0,1-.957,1.657h0a1.9,1.9,0,0,0-.7,2.6h0l.632,1.089A1.913,1.913,0,0,0,3.5,15.7h0a1.895,1.895,0,0,1,2.6.7,1.831,1.831,0,0,1,.256.915h0a1.913,1.913,0,0,0,1.913,1.913H9.519a1.913,1.913,0,0,0,1.913-1.9h0a1.9,1.9,0,0,1,1.913-1.913,1.95,1.95,0,0,1,.915.256h0a1.913,1.913,0,0,0,2.609-.7h0l.659-1.1a1.9,1.9,0,0,0-.7-2.609h0a1.9,1.9,0,0,1-.7-2.609,1.876,1.876,0,0,1,.7-.7h0a1.913,1.913,0,0,0,.7-2.6h0Z"
                    transform="translate(0.779 0.778)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                  />
                  <circle
                    id="Ellipse_737"
                    cx="2.636"
                    cy="2.636"
                    r="2.636"
                    transform="translate(7.039 7.753)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </div>
          </div>

          <div className="d-flex w-100 justify-content-center mb-2">
            <div className="input-group searchbar rounded-xs bg-white">
              <input
                type="search"
                className="form-control bg-transparent border-0 shadow-sm"
                placeholder="Find A Vendor"
                aria-label="Search"
                aria-describedby="search-addon"
                autoFocus
                onChange={(e) => setSearchString(e.target.value)}
              />
              <span
                className="input-group-text bg-transparent border-0"
                id="search-addon"
              >
                <BiSearch />
              </span>
            </div>
          </div>

          <div className="d-flex w-100 justify-content-center mb-3">
            <div className="input-group pl-4 searchbar rounded-xs bg-white">
              <span
                className="input-group-text bg-transparent border-0"
                id="search-addon"
              >
                <BsGeoAlt />
              </span>
              <input
                type="search"
                className="form-control bg-transparent border-0 shadow-sm text-dark"
                placeholder="Lagos, Nigeria"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </div>
        </div>

        <div className="VCDiv vh-75 y-scroll vh-sm-70 px-2 px-md-4">
          <Container
            fluid
            className="d-flex flex-column align-items-center py-0 justify-content-center px-0"
          >
            <div className="col-md-10 col-xl-7 col-12">
              {isLoading ? (
                placeholder
              ) : vendorsFetched === undefined ||
                vendorsFetched.length === 0 ||
                vendorsFetched === null ? (
                <EmptyState />
              ) : (
                vendorsFetched.map((vendor, index) => (
                  <Card
                    className="mb-4 bg-white p-3 d-flex align-items-center justify-content-between border-0 flex-row shadow-sm"
                    key={index}
                    style={{ height: "120px" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="w-auto profileHeight p-0 overflow-hidden rounded me-3">
                        {!vendor.profileImage ? (
                          <div style={{ height: "75px", width: "75px" }}>
                            <DefaultProfileImage fullName={vendor.fullName} />
                          </div>
                        ) : (
                          <img
                            src={vendor.profileImage}
                            style={{
                              height: "75px",
                              width: "75px",
                            }}
                            className="rounded"
                            alt="vendorProfile"
                          />
                        )}
                      </div>
                      <div className="text-small">
                        <Link to={`/vendor/${vendor._id}`}>
                          <h6 className="text1 lh-base mb-1">
                            {vendor.fullName || "Vendor Name"}
                          </h6>
                          <div className="budgetLocation">
                            <p className="text3 lh-base m-0">
                              Location :{" "}
                              <span>{vendor.location || "Unavailable"}</span>{" "}
                            </p>{" "}
                          </div>{" "}
                          <p className="text3 m-0">
                            Min Budget:{" "}
                            <span>{vendor.budget || "Unavailable"}</span>{" "}
                          </p>{" "}
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <div
                        className="border border-warning d-inline-block"
                        style={{
                          letterSpacing: "1px",
                          borderRadius: "15px",
                        }}
                      >
                        <div
                          className="d-flex text-center px-2 align-items-center justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          <AiFillStar
                            style={{
                              color: "gold",
                              verticalAlign: "initial",
                            }}
                          />
                          &nbsp;
                          {vendor.rating ? vendor.rating.averageRate : "0.0"}
                        </div>
                      </div>
                    </div>
                  </Card>
                ))
              )}
            </div>
          </Container>{" "}
        </div>

        <NotificationsCanvas show={show2} handleClose={handleClose} />
      </Col>
    </BaseContainer>
  );
};
export default VendorSearch;
