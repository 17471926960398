export const formatTime = (body) => {
    const created_date = new Date(body);
    const timeDiff = Math.abs(Date.now() - created_date.getTime());
    const diffYears = Math.floor(timeDiff / (1000 * 3600 * 24 * 356)); // Years
    const diffMonths = Math.floor(timeDiff / (1000 * 3600 * 24 * 30)); // Months
    const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24)); // days
    const diffHours = Math.floor(timeDiff / (1000 * 3600)); // hours
    const diffMinutes = Math.floor(timeDiff / (1000 * 60)); // minutes
    
    let time = "";
    if (diffYears > 0)
      time = diffYears === 1 ? `${diffYears} year ago` : `${diffYears} years ago`;
    else if (diffMonths > 0)
      time =
        diffMonths === 1 ? `${diffMonths} month ago` : `${diffMonths} months ago`;
    else if (diffDays > 0)
      time = diffDays === 1 ? `${diffDays} day ago` : `${diffDays} days ago`;
    else if (diffHours > 0)
      time = diffHours === 1 ? `${diffHours} hour ago` : `${diffHours} hours ago`;
    else if (diffMinutes > 0)
      time =
        diffMinutes === 1
          ? `${diffMinutes} minute ago`
          : `${diffMinutes} minutes ago`;
    else time = "just now";
  
    return time;
  };
  