import { Modal, Card, Row, Col, Form } from "react-bootstrap";
import React, { useState } from "react";
import { toast } from "react-toastify";
// import { Formik, Form } from "formik";
import { editInspirationBoard } from "../../../../services/customer/Inspirationboard";
import { CircularLoading } from "../../../loading/CircularLoading";

export const EditModalBoard = ({ edit, handleClose, handleClose2, load }) => {
  const [newName, setNewName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!newName) {
      toast.error("Please enter a name");
      setIsSubmitting(false);
      return;
    } else {
      try {
        const response = await editInspirationBoard(newName, edit.id);
        if (response?.data?.success) {
          load();
          toast.success(response?.data.message);
          setNewName("");
          handleClose2();
        } else {
          toast.error(response);
        }
      } catch (e) {
        toast.error(e);
      }
      setIsSubmitting(false);
    }
  };

  return (
    // <Modal
    //   show={edit.show}
    //   onHide={handleClose}
    //   centered
    //   className="pt-4 md-modal-dialog align-items-end "
    // >
    //   <Formik
    //     initialValues={{ name: edit.name }}
    //     onSubmit={async (values, { setSubmitting, resetForm }) => {
    //       await handleSubmit(values, resetForm, setSubmitting);
    //     }}
    //   >
    //     {({ isSubmitting }) => (
    //       <Form>
    //         <Modal.Body className="p-4">
    //           <h3 className="text-center">Edit Board</h3>
    //           {/* <p>Name your board and save it to keep track of inspiration.</p> */}
    //           <TextInput
    //             label="Name"
    //             name="name"
    //             type="text"
    //             className=""
    //             placeholder="Enter Board Name"
    //             required
    //           />

    //           <div className="d-flex">
    //             <div className="w-50">
    //               <CustomButton
    //                 style={{
    //                   color: "#e00253",
    //                   borderColor: "#e00253",
    //                   backgroundColor: "#ffff",
    //                   // padding: "15px",
    //                 }}
    //                 // className="rounded"
    //                 onClick={handleClose}
    //               >
    //                 Cancel
    //               </CustomButton>
    //             </div>
    //             &nbsp; &nbsp;
    //             <div className="w-50">
    //               <CustomButton
    //                 style={{
    //                   color: "#ffff",
    //                   borderColor: "#e00253",
    //                   backgroundColor: "#e00253",
    //                 }}
    //                 type="submit"
    //                 // className="rounded"
    //                 disabled={isSubmitting}
    //                 // onClick={handleClose}
    //               >
    //                 {isSubmitting ? <CircularLoading /> : "Save"}
    //               </CustomButton>
    //             </div>
    //           </div>
    //         </Modal.Body>
    //       </Form>
    //     )}
    //   </Formik>
    // </Modal>
    <Modal
      show={edit.show}
      onHide={handleClose2}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="small-modal-2"
    >
      <Card className="py-4 px-3 border-0">
        <Card.Body>
          <div className="text-center">
            <h5 className="mb-3">
              <b>Edit Board</b>
            </h5>
            <p className="text-muted text-small">
              Edit your board name to a more descriptive one to keep track of
              inspirations
            </p>
          </div>
          <Form>
            <Form.Group
              className="mt-4"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                <b>Name</b>
              </Form.Label>
              <Form.Control
                placeholder="Enter New Board Name"
                aria-label="Name"
                className="p-3 react-textarea"
                type="text"
                onChange={(e) => setNewName(e.target.value)}
              />
            </Form.Group>
            <Row>
              <Col className="text-center" xs={6}>
                <button
                  className="btn text-theme border-theme p-3 mt-4 w-100"
                  onClick={(handleClose)}
                >
                  Cancel
                </button>
              </Col>
              <Col className="text-center" xs={6}>
                <button
                  className="btn btn-theme w-100 p-3 mt-4 fw-bold"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <CircularLoading /> : "Edit Board"}
                </button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Modal>
  );
};
