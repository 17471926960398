import React from "react";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Api } from "../../../axiosInstance";
import Layout from "../../../components/common/layout";
import { TextInput } from "../../../components/common/input";
import CustomButton from "../../../components/custom-button/custom-button";
import { CircularLoading } from "../../../components/loading/CircularLoading.js";
import { Message } from "../../../components/iconly-icons";

const ForgotPassword = (props) => {
  // const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    sessionStorage.setItem("forgotPasswordEmail", values.email);
    setSubmitting(true);
    try {
      const response = await Api.post("/auth/forgot-password", values);

      if (response?.data?.success) {
        toast.success(response?.data.message);
        resetForm();
        navigate("/otp");
      } else {
        toast.error(response);
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      if (e.response) {
        if (e.response?.data) {
          // setError(e.response.data?.message);
          return;
        }
        // setError("An error occurred");
        return;
      }
    }
  };
  return (
    <>
      <Layout>
        <Formik
          initialValues={{ email: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) =>
            await handleSubmit(values, resetForm, setSubmitting)
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <p className="text-end">
                Already Have An Account?&nbsp;
                <Link to="/signin" className="text-theme">
                  Log In
                </Link>
              </p>

              <div className="py-4">
                <h2 className="fw-bolder">Forgot Password</h2>
                <p>
                  Lost your password? Type in your email to get a reset code
                </p>
                <TextInput
                  labelClass="PersonalText"
                  label={`Email Address`}
                  className="EachTextInput"
                  iconImage={<Message />}
                  type="email"
                  name="email"
                  placeholder="Enter Your Email Address"
                />
              </div>
              <CustomButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularLoading /> : "Send Reset Code"}
              </CustomButton>
            </Form>
          )}
        </Formik>
      </Layout>
    </>
  );
};
export default ForgotPassword;
