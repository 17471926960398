import React from "react";
import { useField } from "formik";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import "react-phone-number-input/style.css";
import Select from "react-select";

export const TextInput = ({
  label,
  labelclass,
  iconImage,
  iconClass,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(props);

  props.className =
    meta.touched && meta.error
      ? props.className + "errorline"
      : props.className;

  // return (
  //   <>
  //     <label htmlFor={props.id || props.name} className={labelclass}>
  //       {label}
  //     </label>
  //     <div {...props}>
  //       &nbsp; &nbsp; {iconImage} <input type={props.type} {...placeholder} />{" "}
  //     </div>
  //     <div className="formik-error">
  //       {meta.touched && meta.error ? meta.error : null}
  //     </div>
  //   </>
  // );

  return (
    <React.Fragment>
      <label htmlFor={props.id || props.name} className="fs-14 mb-2 font-weight-bold">
        {label}
      </label>
      <div className="input-group mb-3">
        {iconImage && (
          <span className="input-group-text input-group-text-0 ">
            {iconImage}
          </span>
        )}
        <input
          type={props.type}
          placeholder={placeholder}
          className="form-control form-control-0 ps-3 py-2"
          name={props.name}
          disabled={props.disabled}
          required
          {...field}
          // {...props}
        />
      </div>
    </React.Fragment>
  );
};

export const SelectInput = ({ label, labelClass, iconImage, ...props }) => {
  const [field, meta, helpers] = useField(props);

  props.className =
    meta.touched && meta.error
      ? props.className + " errorline"
      : props.className;

  return (
    <>
      <label htmlFor={props.id || props.name} className={labelClass}>
        {label}
      </label>
      <div {...props}>
        {iconImage}
        <div className={props.className}>
          <Select
            {...props}
            value={
              props.options
                ? props.options.find((option) => option.value === field.value)
                : ""
            }
            onChange={(option) => helpers.setValue(option.value)}
            options={props.options}
            isSearchable={false}
            placeholder={props.placeholder}
          />
        </div>
        {/* <CustomSelect
          {...field}
          {...props}
          value={
            props.options
              ? props.options.find((option) => option.value === field.value)
              : ""
          }
          onChange={(option) => helpers.setValue(option.value)}
        /> */}
      </div>
      <div className="formik-error">
        {meta.touched && meta.error ? meta.error : null}
      </div>
    </>
  );
};
export const EnquirySelectInput = ({
  label,
  labelClass,
  iconImage,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  props.className =
    meta.touched && meta.error
      ? props.className + " errorline"
      : props.className;

  return (
    <>
      <label htmlFor={props.id || props.name} className="fs-14 mb-2 fw-bold">
        {label}
      </label>
      <div {...props}>
        {iconImage}
        <div
        // className={props.className}
        >
          <select
            {...props}
            value={
              props.options
                ? props.options.find((option) => option.value === field.value)
                : ""
            }
            onChange={(option) => helpers.setValue(option.value)}
            className="form-control form-control-0 ps-3 py-3"
            options={props.options}
            isSearchable={false}
            placeholder={props.placeholder}
          >
            {props.options.map((option) => (
              <option name={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        {/* <CustomSelect
          {...field}
          {...props}
          value={
            props.options
              ? props.options.find((option) => option.value === field.value)
              : ""
          }
          onChange={(option) => helpers.setValue(option.value)}
        /> */}
      </div>
      <div className="formik-error">
        {meta.touched && meta.error ? meta.error : null}
      </div>
    </>
  );
};

export const Phone = ({ label, labelClass, iconImage, ...props }) => {
  const [field] = useField(props);

  return (
    <PhoneInput
      onChange={field.onChange(field.name)}
      defaultCountry="NG"
      label="Phone Number"
      placeholder="Enter phone number"
      withCountryCallingCode
      international
      countryCallingCodeEditable={false}
      initialValueFormat="national"
      value={field.value}
      // onChange={field.onChange}
      className="form-control form-control-0 ps-3 py-2 mb-3"
      error={
        field.value
          ? isPossiblePhoneNumber(field.value)
            ? undefined
            : "Invalid phone number"
          : "Phone number required"
      }
    />
  );
};
