import React, { useEffect, useState } from "react";
import { Col, Container, Row, Placeholder } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchCategories } from "../../../../services/customer/Inspirationboard";
import { EmptyState } from "../../../empty_states/empty";

export const VendorsCategory = ({ load }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const response = await fetchCategories();
      const { data } = response.data;
      setCategories(data);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const placeholderArray = [1, 2, 3, 4, 5, 6, 7, 8];
  const placeholder = placeholderArray.map((item, index) => {
    return (
      <Placeholder
        lg={3}
        md={4}
        xs={6}
        animation="glow"
        className="mb-0 mt-2"
        key={index}
      >
        <Placeholder
          style={{ height: 230 }}
          className="rounded w-100 mb-4 xs-180"
        />
      </Placeholder>
    );
  });

  return (
    <div className="VCDiv VendorScroll">
      <Container fluid className="text-center mb-3 px-0 px-md-5">
        <Row className="gx-2 gy-2 y-scroll vendor-categories">
          {isLoading ? (
            placeholder
          ) : categories.length > 0 ? (
            categories.map((category, i) => (
              <Col
                key={`cat-${i}`}
                xs={6}
                md={4}
                lg={3}
                className="rounded position-relative overflow-hidden mb-4"
                style={{
                  overflow: "hidden",
                }}
              >
                <img
                  src={category.imageUrl}
                  style={{
                    height: 230,
                    width: "100%",
                  }}
                  className="rounded xs-180"
                  alt="vendorsCategory"
                />
                <Link to={`/dashboard/search?category=${category.name}`}>
                  <div
                    className="text-white rounded position-absolute d-flex justify-content-center align-items-center xs-180"
                    style={{
                      bottom: 0,
                      left: 4,
                      right: 4,
                      top: 0,
                      backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                  >
                    <b>{category.name}</b>
                  </div>
                </Link>
              </Col>
            ))
          ) : (
            <EmptyState />
          )}
        </Row>
      </Container>
    </div>
  );
};
