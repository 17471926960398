export const FormatMoney = (number, symbol = null) => {
  number = !number ? 0.0 : number;
  if (symbol) {
    return (
      symbol +
      parseFloat(number)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    ); // 12,345.67
  } else {
    return (
      "₦" +
      parseFloat(number)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    ); // 12,345.67
  }
};
export const FormatMoneyWithoutCurrency = (number, symbol = null) => {
  number = !number ? 0.0 : number;
  return parseFloat(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,"); // 12,345.67
};
