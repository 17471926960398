import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import CustomButton from "../../../custom-button/custom-button";
import { CircularLoading } from "../../../loading/CircularLoading";
import { DefaultProfileImage } from "../../../common/DefaultProfileImage";
import { EmptyState } from "../../../empty_states/empty";

const Active = ({ vendors }) => {
  const [show, setShow] = useState(false);
  const [isSubmitting] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({});

  const handleShow = (vendor) => {
    setShow(true);
    setSelectedVendor(vendor);
  };

  const handleClose = () => setShow(false);

  if (vendors === [] || vendors === null) return <EmptyState />;
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="w-90 grid-template-columns-2 w-sm-100">
          {vendors.map((vendor, index) => {
            const link = `/vendor/${vendor.vendor._id}`;
            return (
              <div className="VendorCard" key={index}>
                <div className=" w-100 h-100 bg-white rounded shadow-sm">
                  <div className="d-flex justify-content-between px-3 py-4">
                    <div className="d-flex">
                      {vendor.vendor.profileImage ? (
                        <img
                          className="rounded me-3"
                          src={vendor.vendor.profileImage}
                          alt="vendor-img"
                          height="100px"
                          width="75px"
                        />
                      ) : (
                        <div
                          style={{ height: "100px", width: "75px" }}
                          className="me-3"
                        >
                          <DefaultProfileImage
                            fullName={vendor.vendor.fullName}
                          />
                        </div>
                      )}
                      <div className="d-flex justify-content-between flex-column">
                        <h6 className="font-weight-bold">
                          <Link to={link}>{vendor.vendor.fullName}</Link>
                        </h6>
                        <div className="budgetLocation lh-sm">
                          <p className="text3 mb-2 text-small">
                            Location:{" "}
                            <span>
                              {vendor.vendor.location
                                ? vendor.vendor.location
                                : "Unspecified"}
                            </span>
                          </p>
                          <p className="text3 mb-2 text-small">
                            Project Cost:{" "}
                            <span>
                              {"N" + vendor.workedProject.budget ||
                                "Unspecified"}
                            </span>
                          </p>
                          <p className="text3 mb-0 text-small">
                            Approved Amount:{" "}
                            <span>
                              {"N" + vendor.bid.approvedAmount || "Unspecified"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="border border-warning d-inline-block mt-2"
                      style={{
                        letterSpacing: "1px",
                        borderRadius: "8px",
                        width: "fit-content",
                        height: "fit-content",
                      }}
                    >
                      <div
                        className="d-flex text-center px-2 align-items-center justify-content-center"
                        style={{ fontSize: "12px" }}
                      >
                        <AiFillStar
                          style={{ color: "#DDA830", verticalAlign: "initial" }}
                        />
                        &nbsp;
                        {vendor?.vendor.rating
                          ? vendor?.vendor.rating.averageRate
                          : "0.0"}
                      </div>
                    </div>
                  </div>
                  <div className="border-top grid-2 gap-0">
                    <div className="text-center py-2">
                      <div
                        role="button"
                        className=""
                        onClick={() => handleShow(vendor.vendor)}
                      >
                        Approve Amount
                      </div>
                    </div>
                    <div className="text-center py-2 border-left">
                      <div role="button" className="">
                        Send Message
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="medium-modal"
      >
        <Modal.Header className="px-2 px-md-5">
          <Modal.Title style={{ fontWeight: "700", fontSize: "1.5rem" }}>
            <div>
              <img
                src={
                  selectedVendor.profileImage
                    ? selectedVendor.profileImage
                    : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                }
                alt="logo"
                className="approve-amount-image mb-2"
              />
            </div>
          </Modal.Title>
          <div>
            <h6 className="mb-4">{selectedVendor.fullName}</h6>
            <h4 className="fw-bold">Approve amount</h4>
            <p className="text-small fw-normal mt-3">
              Approving an amount will automatically let the vendor have acccess
              to the funds approved
            </p>
          </div>
        </Modal.Header>

        <Modal.Footer className="px-2 px-md-5">
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-start">Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                autoFocus
                required
                // onChange={(e) => setName(e.target.value)}
                // value={name}
                autoComplete="off"
                style={{ lineHeight: "2.5" }}
              />
            </Form.Group>
            <div className="text-end" style={{ fontSize: "75%" }}>
              Total Unapproved Balance: N300,000
            </div>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-start">Passworrd</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Password"
                autoFocus
                required
                // onChange={(e) => setName(e.target.value)}
                // value={name}
                autoComplete="off"
                style={{ lineHeight: "2.5" }}
              />
            </Form.Group>
          </Form>
          <div className="mt-3">
            <div className="grid-2">
              <CustomButton
                style={{
                  color: "#e00253",
                  borderColor: "#e00253",
                  backgroundColor: "#fff",
                }}
                onClick={handleClose}
              >
                Cancel
              </CustomButton>
              <CustomButton
                type="submit"
                disabled={isSubmitting}
                // onClick={() => handleSubmit("published")}
              >
                {isSubmitting ? <CircularLoading /> : "Approve"}
              </CustomButton>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Active;
