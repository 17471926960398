import React from "react";
import { Form } from "react-bootstrap";
import { Api } from "../../../../../axiosInstance";
import { toast } from "react-toastify";
import { CircularLoading } from "../../../../loading/CircularLoading";

export default function Report() {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await Api.post("/report", {
        name,
        description,
      });
      if (response?.data?.success) {
        toast.success(response?.data.message);
      } else {
        toast.error(response);
      }
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };
  return (
    <div>
      <Form className="px-5 pt-5 px-lg-3">
        <Form.Group
          className="mt-4 mb-3"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>
            <b>What do you want to report</b>
          </Form.Label>
          <Form.Select
            className="p-3 mb-4 react-textarea"
            value={name}
            onChange={(e) => setName(e.target.value)}
          >
            <option value="App Issue">App Issue</option>
            <option value="Other Issues">Other Issues</option>
          </Form.Select>
          <Form.Label>
            <b>Briefly explain the issue</b>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={7}
            placeholder="Briefly describe the issue"
            className="react-textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <div className="text-right">
          <button
            className="btn-theme btn py-3 px-5 w-auto font-weight-bold"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularLoading /> : "Submit"}
          </button>
        </div>
      </Form>
    </div>
  );
}
