import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Card, Row, Col, Modal, Placeholder } from "react-bootstrap";
import Cookie from "universal-cookie";
import { BiSearch } from "react-icons/bi";

import { BaseContainer } from "../../components/dashboard/Containers";
import { Api } from "../../axiosInstance";
import { CircularLoading } from "../../components/loading/CircularLoading";
import { NotificationsCanvas } from "../../components/dashboard/notifications_canvas";
import { EmptyState } from "../../components/empty_states/empty";

const BidsFromVendor = () => {
  const cookie = new Cookie();
  const jwt = cookie.get("jwt");
  const { id, name } = useParams();
  const navigate = useNavigate();

  const [bids, setBids] = useState([]);
  const [searchedBids, setSearchedBids] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [search, setSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchBids = async (id) => {
    try {
      const response = await Api.get(`/projects/bids/${id}`);
      setBids(response.data.data.results);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBids(id);
  }, [id]);

  const handleClose = () => {
    setShow(false);
    setShow2(false);
    setShow3(false);
    setShow4(false);
    setShow5(false);
  };

  const acceptBid = async (bidId) => {
    setIsSubmitting(true);
    const data = {
      projectId: id,
      bidId,
    };
    try {
      const response = await Api.post("/projects/accept-bid", data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (response?.data?.success) {
        setIsLoading(true);
        fetchBids(id);
        setShow(false);
        setShow2(true);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
  };

  const rejectBid = async (bidId) => {
    setIsSubmitting(true);
    const data = {
      projectId: id,
      bidId,
    };
    try {
      const response = await Api.post("/projects/reject-bid", data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (response?.data?.success) {
        setIsLoading(true);
        fetchBids(id);
        setShow3(false);
        setShow4(true);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
  };

  const getBidsBySearch = (value) => {
    setSearch(value);
    const searchedBids = bids.filter((bid) =>
      bid.userId.fullName.toLowerCase().includes(value.toLowerCase())
    );
    setSearchedBids(searchedBids);
  };

  const returnedBids = search ? searchedBids : bids;
  const image = localStorage.getItem("profileImage");

  const placeholderArray = [1, 2, 3, 4];
  const placeholder = placeholderArray.map((item) => {
    return (
      <Card className="border-0 w-50 mb-4 w-sm-100" key={item}>
        <Card.Body>
          <div className="d-flex align-items-center">
            <Placeholder animation="glow">
              <Placeholder
                xs={4}
                style={{
                  height: "50px",
                  width: "50px",
                }}
                className="rounded"
              />
            </Placeholder>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ height: 50 }}
            >
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  className="ms-3"
                  style={{ width: 120, height: 20 }}
                />
              </Placeholder>
            </div>
          </div>
          <Placeholder
            as={Card.Text}
            animation="glow"
            className="mb-0 mt-1 text-left"
          >
            <Placeholder xs={6} />
          </Placeholder>
        </Card.Body>
        <Card.Footer className="bg-white mt-1 p-3 text-center d-flex justify-content-around">
          <Placeholder animation="glow" className="w-100">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder animation="glow" className="w-100">
            <Placeholder xs={6} />
          </Placeholder>
        </Card.Footer>
      </Card>
    );
  });

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <div className="Header header2 bg-dark d-none d-md-flex flex-column justify-content-evenly vh-20">
          <div className="px-4 w-100 justify-content-between align-items-center d-flex">
            <i
              className="iconly-Arrow-Left-Circle icli caret-holder cursor-pointer"
              style={{ fontSize: "30px" }}
              onClick={() => navigate(-1)}
            ></i>
            <b className="mb-0 fs-5">{name}</b>
            <div className="justify-content-end align-items-center d-flex">
              <button
                type="button"
                className="btn btn-transparent position-relative me-3 p-0 d-flex align-items-center"
                onClick={() => setShow5(true)}
              >
                <i className="iconly-Activity icli fs-20 text-white"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                  9+
                  <span className="visually-hidden">unread messages</span>
                </span>
              </button>
              <Link to="/messagelist" className="no-underline">
                <button
                  type="button"
                  className="btn btn-transparent position-relative me-4 p-0 d-flex align-items-center"
                >
                  <i className="iconly-Chat icli fs-20 text-white"></i>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                    9+
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </button>
              </Link>

              <img
                src={image ? image : "https://via.placeholder.com/100"}
                className="rounded-circle"
                height="35"
                width="35"
                alt="account"
              />
            </div>
          </div>
          <div className="input-group .text-white bg-white w-50 searchbar">
            <span
              className="input-group-text text-white no-border vw-50 bg-white"
              id="search-addon"
            >
              <BiSearch />
            </span>
            <input
              type="search"
              className="form-control text-white border shadow-sm text-white no-border bg-white text-capitalize"
              placeholder="Search for a Bid"
              aria-label="Search"
              aria-describedby="search-addon"
              onChange={(e) => getBidsBySearch(e.target.value)}
            />
          </div>
        </div>
        <div className="Header header2 bg-dark d-flex flex-column justify-content-evenly vh-20 d-md-none">
          <div className="px-4 w-100 align-items-center d-flex">
            <i
              className="iconly-Arrow-Left-Circle icli caret-holder cursor-pointer me-3"
              style={{ fontSize: "30px" }}
              onClick={() => navigate.goBack()}
            ></i>
            <b className="mb-0 fs-5">{name}</b>
          </div>
        </div>

        <div className="m-10 VCDiv h-80 d-flex flex-column align-items-center vh-80">
          {isLoading ? (
            placeholder
          ) : returnedBids.length > 0 ? (
            returnedBids.map((bid, index) => {
              const vendorProfileLink = `/vendor/${bid.userId._id}`;
              const { status } = bid;
              let statusColor = "";
              if (status === "pending") statusColor = "text-warning";
              else if (status === "accepted") statusColor = "text-success";
              else if (status === "rejected") statusColor = "text-danger";
              console.log(bid);
              return (
                <div
                  key={index}
                  className="d-flex justify-content-center w-100"
                >
                  <Card className="mb-3 w-50 border-0 full-width-75">
                    <div className="text-right position-relative">
                      <div
                        className={`cursor-pointer text-center position-absolute px-3 w-auto h-auto angled-position text-capitalize ${statusColor}`}
                      >
                        {/* <ImCross
                          className="h-100 rounded-circle"
                          // onClick={() => {
                          //   setDeleteModal(true);
                          //   setSelectedImage(imageItem);
                          // }}
                        /> */}
                        {status}
                      </div>
                    </div>
                    <Card.Body>
                      <div className="mb-2">
                        <img
                          src={
                            bid.userId.profileImage
                              ? bid.userId.profileImage
                              : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                          }
                          alt="project"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginRight: "15px",
                            borderRadius: "5px",
                          }}
                        ></img>
                        <Link to={vendorProfileLink}>
                          <b>{bid.userId.fullName}</b>
                        </Link>
                      </div>
                      <div className="text-small">
                        {bid.description || "No description"}
                      </div>
                    </Card.Body>
                    <Card.Footer className="p-0">
                      <div className="d-flex justify-content-between text-dark cursor-pointer">
                        <span
                          className="w-100 text-center py-3"
                          onClick={() => setShow(true)}
                        >
                          Accept
                        </span>
                        <span
                          className="w-100 text-center py-3 border-left"
                          onClick={() => setShow3(true)}
                        >
                          Decline
                        </span>
                      </div>
                    </Card.Footer>
                  </Card>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="my-modal"
                  >
                    <Card className="p-2 text-center border-0">
                      <Card.Body>
                        {/* <Card.Title> */}
                        <div className="mb-3 fs-5">
                          <b>Are you sure you want to accept this bid?</b>
                        </div>
                        {/* </Card.Title> */}
                        <Card.Text className="text-small-2">
                          Accepting this bid grants the vendor access to
                          directly message you regarding the project
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card.Footer>
                      <Row className="text-center">
                        <Col xs={6}>
                          <button
                            className="btn btn-theme w-100 fw-bold"
                            type="submit"
                            onClick={() => acceptBid(bid._id)}
                            style={{
                              padding: "1rem 1.5rem",
                            }}
                          >
                            {isSubmitting ? <CircularLoading /> : "Accept"}
                          </button>
                        </Col>
                        <Col xs={6}>
                          <button
                            className="btn w-100"
                            style={{
                              color: "#e00253",
                              borderColor: "#e00253",
                              backgroundColor: "#fff",
                              padding: "1rem 1.5rem",
                            }}
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Modal>
                  <Modal
                    show={show2}
                    onHide={handleClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="my-modal"
                  >
                    <Card
                      className="p-4 text-center"
                      style={{ border: "none", fontFamily: "Lato" }}
                    >
                      <Card.Body>
                        <div className="mb-3">
                          <img
                            src="https://st2.depositphotos.com/5777248/10629/v/950/depositphotos_106299224-stock-illustration-green-tick-check-mark-icon.jpg"
                            alt="check"
                            width="85px"
                            height="85px"
                          ></img>
                        </div>
                        <h4>
                          <b>You accepted this bid</b>
                        </h4>
                        <Card.Text className="mt-3">
                          The vendor will be notified and will also be able to
                          message you instantl
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card.Footer>
                      <Row className="text-center">
                        <Col xs={12}>
                          <button
                            className="btn w-100"
                            style={{
                              color: "#e00253",
                              borderColor: "#e00253",
                              backgroundColor: "#fff",
                              padding: "1rem 1.5rem",
                            }}
                            //   onClick={handleClose}
                          >
                            Message Vendor
                          </button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Modal>
                  <Modal
                    show={show3}
                    onHide={handleClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="my-modal"
                  >
                    <Card className="p-2 text-center border-0">
                      <Card.Body>
                        <div className="mb-3 fs-5">
                          <b>Are you sure you want to reject this bid?</b>
                        </div>
                        <Card.Text className="text-small-2">
                          After rejecting the bid, the vendor will be notified
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card.Footer>
                      <Row className="text-center">
                        <Col xs={6}>
                          <button
                            className="btn btn-theme w-100 fw-bold"
                            type="submit"
                            onClick={() => rejectBid(bid._id)}
                            style={{
                              padding: "1rem 1.5rem",
                            }}
                          >
                            {isSubmitting ? <CircularLoading /> : "Reject"}
                          </button>
                        </Col>
                        <Col xs={6}>
                          <button
                            className="btn w-100"
                            style={{
                              color: "#e00253",
                              borderColor: "#e00253",
                              backgroundColor: "#fff",
                              padding: "1rem 1.5rem",
                            }}
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Modal>
                  <Modal
                    show={show4}
                    onHide={handleClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="my-modal"
                  >
                    <Card
                      className="p-4 text-center"
                      style={{ border: "none", fontFamily: "Lato" }}
                    >
                      <Card.Body>
                        <div className="mb-3">
                          <img
                            src="https://png.monster/wp-content/uploads/2021/06/png.monster-11-370x370.png"
                            alt="check"
                            width="85px"
                            height="85px"
                          ></img>
                        </div>
                        <h4>
                          <b>You rejected this bid</b>
                        </h4>
                        <Card.Text className="mt-3">
                          The vendor will be notified that you rejected his bid
                          for your project
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    {/* <Card.Footer>
                  <Row className="text-center">
                    <Col xs={12}>
                      <button
                        className="btn w-100"
                        style={{
                          color: "#e00253",
                          borderColor: "#e00253",
                          backgroundColor: "#fff",
                          padding: "1rem 1.5rem",
                        }}
                        //   onClick={handleClose}
                      >
                        Message Vendor
                      </button>
                    </Col>
                  </Row>
                </Card.Footer> */}
                  </Modal>
                </div>
              );
            })
          ) : (
            <EmptyState />
          )}
        </div>

        <NotificationsCanvas show={show5} handleClose={handleClose} />
      </Col>
    </BaseContainer>
  );
};

export default BidsFromVendor;
