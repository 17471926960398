import React, { useContext } from "react";
import { Routes, Route, Navigate, } from "react-router-dom";

import AccountType from "./pages/onboarding/accounttype/accounttype";
import SignUp from "./pages/onboarding/SignUp/signup";
import SignIn from "./pages/onboarding/SignIn/signin";
import ForgotPassword from "./pages/onboarding/forgotpassword/forgotpassword";
import Otp from "./pages/onboarding/otp/otp";
import NewPassword from "./pages/onboarding/new-password/newpassword";
import VerifyAccount from "./pages/onboarding/verifyAccount/verify";
// import Dashboard from "./pages/dashboard/dashboard";
import CustomerDashboard from "./pages/dashboard/home/customerdashboard";
import MySubmissions from "./pages/dashboard/mysubmissions";
import CustomerSettings from "./pages/dashboard/settings";
import InspirationBoard from "./pages/dashboard/inspirationboards/inspirationboard";
import CustomerPayment from "./pages/dashboard/payment";
import Projects from "./pages/dashboard/projects";
import MyVendors from "./pages/dashboard/myvendors";
import ProjectsWithVendor from "./pages/dashboard/projects-with-vendor";
import BidsFromVendor from "./pages/dashboard/bids-from-vendor";

import "./App.scss";
import AuthContext from "./context/AuthProvider";
import Cookies from "universal-cookie";
import { Board } from "./pages/dashboard/inspirationboards/Board";
import CustomerInspiration from "./components/dashboard/customer-dashboard/Inspiration/customerinspiration";
import { VendorProfile } from "./components/dashboard/customer-dashboard/home/vendorprofile";
import VendorSearch from "./components/dashboard/customer-dashboard/home/vendorsearch";
import { EditProfile } from "./pages/dashboard/profile/EditProfile";
import { Messages } from "./pages/dashboard/Messaging/Messages";
import { MessageList } from "./pages/dashboard/Messaging/MessageList";

const cookies = new Cookies();

const App = () => {
  const jwt = cookies.get("jwt");
  const { auth } = useContext(AuthContext);
  
  return (
    <Routes>
      {jwt || auth.email ? (
        <>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route exact path="/dashboard" element={<CustomerDashboard />} />
          <Route path="/profile" element={<EditProfile />} />
          <Route path="/messagelist" element={<MessageList />} />
          <Route path="/messages/:id/" exact element={<Messages />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/submissions" element={<MySubmissions />} />
          <Route path="/settings" element={<CustomerSettings />} />
          <Route path="/dashboard/search" element={<VendorSearch />} />
          <Route path="/inspirationboard" element={<InspirationBoard />} />
          <Route
            path="/board/:id/add/:name"
            element={<CustomerInspiration />}
          />
          <Route exact path="/vendor/:id" element={<VendorProfile />} />
          <Route path="/board/view/:id/:title" element={<Board />} />
          <Route path="/customerpayment" element={<CustomerPayment />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/myvendors" element={<MyVendors />} />
          <Route path="/vendor/projects/:id" element={<ProjectsWithVendor />} />
          <Route path="/bids/:id/:name" element={<BidsFromVendor />} />
        </>
      ) : (
        <>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/verify" element={<VerifyAccount />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/newpassword" element={<NewPassword />} />
          <Route path="/" element={<AccountType />} />
        </>
      )}
    </Routes>
  );
};

export default App;
