import React, { useState } from "react";
import { BaseContainer } from "../../components/dashboard/Containers";

import AllSubmission from "../../components/dashboard/customer-dashboard/mysubmission/all";

const MySubmissions = () => {
  const [submissionMethod, setSubmissionMethod] = useState("all");
  return (
    <BaseContainer>
      <div className="Header header2 bg-dark d-flex flex-column ">
        <h3> My Submissions </h3>{" "}
        <div className="d-flex justify-content-center ">
          <div
            className={`p-4 w-1/2 Vendors  ${
              submissionMethod === "vendors"
                ? "text-danger border-bottom border-danger"
                : ""
            } text-gray-400`}
            checked={submissionMethod === "all"}
            onClick={() => setSubmissionMethod("all")}
          >
            All{" "}
          </div>{" "}
          <div
            className={`p-4 w-1/2 Vendors  ${
              submissionMethod === "vendors"
                ? "text-danger border-bottom border-danger"
                : ""
            } text-gray-400`}
            checked={submissionMethod === "all"}
            onClick={() => setSubmissionMethod("all")}
          >
            Approved{" "}
          </div>{" "}
          <div
            className={`p-4 w-1/2 Vendors  ${
              submissionMethod === "vendors"
                ? "text-danger border-bottom border-danger"
                : ""
            } text-gray-400`}
            checked={submissionMethod === "all"}
            onClick={() => setSubmissionMethod("all")}
          >
            Pending{" "}
          </div>{" "}
          <div
            className={`p-4 w-1/2 Vendors  ${
              submissionMethod === "vendors"
                ? "text-danger border-bottom border-danger"
                : ""
            } text-gray-400`}
            checked={submissionMethod === "all"}
            onClick={() => setSubmissionMethod("all")}
          >
            Draft{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div>{submissionMethod === "all" ? <AllSubmission /> : null}</div>
    </BaseContainer>
  );
};
export default MySubmissions;
