import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Modal,
  Card,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import aws from "aws-sdk";

import {
  firestore,
  sendMessage,
  addToMessages,
} from "../../../services/firebase";
import {
  getProjectsWithCustomer,
  verifyPayment,
} from "../../../services/customer/myvendors";
import {
  query,
  collection,
  limit,
  where,
  getDoc,
  onSnapshot,
  doc as docFunc,
} from "firebase/firestore";
import { BaseContainer } from "../../../components/dashboard/Containers";

export const Messages = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [messageThread, setMessageThread] = useState([]);
  const [inputText, setInputText] = useState("");
  const [sender, setSender] = useState({});
  const [messageId, setMessageId] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [requestedAmount, setRequestedAmount] = useState("");
  const [bidId, setBidId] = useState("");
  const [offerTitle, setOfferTitle] = useState("");

  const [image, setImage] = useState({ preview: "", raw: "" });

  const messageBox = useRef();

  function scrollBottom(element) {
    element.scroll({ top: element.scrollHeight, behavior: "smooth" });
  }

  const _id =
    window && window.sessionStorage && window.sessionStorage.getItem("userId");

  const addMessage = (e) => {
    e.preventDefault();
    messageThread.length === 0
      ? sendMessage(_id, id, "text", inputText)
      : addToMessages(_id, id, "text", inputText, messageId);
    setInputText("");
  };

  const handleClose = () => {
    setShow(false);
    setShow2(false);
  };

  useEffect(() => {
    // Get messages
    // Query

    // TODO: Review
    const _id =
      window &&
      window.sessionStorage &&
      window.sessionStorage.getItem("userId");

    onSnapshot(
      query(
        collection(firestore, "messages"),
        where("members", "array-contains", id),
        limit(50)
      ),
      (querySnapshot) => {
        const messages = querySnapshot.docs
          .map((doc) => {
            const { members } = doc.data();
            const senderID = members.find((id) => id !== _id);
            getDoc(docFunc(firestore, "users", senderID)).then((senderSnap) => {
              if (senderID === id) setSender(senderSnap.data());
            });

            setMessageId(doc.id);

            return {
              id: doc.id,
              ...doc.data(),
            };
          })
          .filter((message) => {
            return (
              message.members.includes(_id) && message.members.includes(id)
            );
          });
        console.log(messages);
        setMessageThread(messages);
      }
    );

    scrollBottom(messageBox.current);
  }, [id]);

  useEffect(() => {
    getProjectsWithCustomer(id)
      .then((res) => {
        console.log(res);
        setBidId(res.bid._id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const triggerFileInput = () => {
    const hold = document?.getElementById("upload-button");
    hold?.click();
  };

  const uploadImage = async (e) => {
    if (image.raw) {
      e.preventDefault();
      const file = image.raw;

      const region = "us-east-1";
      const bucketName = "africweddings";
      const accessKeyId = "AKIAYPAQADF7IK7UJAVE";
      const secretAccessKey = "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";

      const s3 = new aws.S3({
        region,
        accessKeyId,
        secretAccessKey,
        signatureVersion: "v4",
      });

      const params = {
        Bucket: bucketName,
        // generate random 16 digit string
        Key:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        Expires: 60,
        ACL: "public-read",
      };

      const uploadURL = await s3.getSignedUrlPromise("putObject", params);

      console.log(uploadURL);

      await fetch(uploadURL, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file,
      });

      const imageUrl = uploadURL.split("?")[0];
      console.log(imageUrl);

      messageThread.length === 0
        ? sendMessage(_id, id, "image", imageUrl)
        : addToMessages(_id, id, "image", imageUrl, messageId);

      setImage({ preview: "", raw: "" });
    }
  };

  const releaseFunds = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    Fincra.initialize({
      key: process.env.REACT_APP_FINCRA_KEY,
      amount: Number(requestedAmount),
      currency: "NGN",
      customer: {
        name: localStorage.getItem("fullName"),
        email: "alan@africweddings.com",
        phoneNumber: "+2348133814442",
      },

      //Kindly chose the bearer of the fees
      feeBearer: "customer",
      metadata: {
        bidId,
      },

      onClose: function () {
        console.log("Transaction was not completed, window closed.");
      },
      onSuccess: async function (data) {
        const reference = data.reference;
        console.log("Payment complete! Reference: " + reference);

        const res = await verifyPayment(reference);
        console.log(res);
        if (res?.data?.success) {
          setShow(false);
        }

        messageThread.length === 0
          ? sendMessage(_id, id, "request", {
              type: "accept",
              amount: requestedAmount,
            })
          : addToMessages(
              _id,
              id,
              "request",
              { type: "accept", amount: requestedAmount },
              messageId
            );
      },
    });
  };

  const payForOffer = (e) => {
    e.preventDefault();

    console.log(_id, id);
    // eslint-disable-next-line no-undef
    Fincra.initialize({
      key: process.env.REACT_APP_FINCRA_KEY,
      amount: Number(requestedAmount),
      currency: "NGN",
      customer: {
        name: localStorage.getItem("fullName"),
        email: "alan@africweddings.com",
        phoneNumber: "+2348133814442",
      },

      //Kindly chose the bearer of the fees
      feeBearer: "customer",
      metadata: {
        userId: _id,
        vendorId: id,
      },

      onClose: function () {
        console.log("Transaction was not completed, window closed.");
      },
      onSuccess: async function (data) {
        const reference = data.reference;
        console.log("Payment complete! Reference: " + reference);

        const res = await verifyPayment(reference);
        console.log(res);
        if (res?.data?.success) {
          setShow(false);
        }

        // messageThread.length === 0
        //   ? sendMessage(_id, id, "request", {
        //       type: "accept",
        //       amount: requestedAmount,
        //     })
        //   : addToMessages(
        //       _id,
        //       id,
        //       "request",
        //       { type: "accept", amount: requestedAmount },
        //       messageId
        //     );
      },
    });
  };

  const declineRequest = (e) => {
    e.preventDefault();
    messageThread.length === 0
      ? sendMessage(_id, id, "request", {
          type: "decline",
          amount: requestedAmount,
        })
      : addToMessages(
          _id,
          id,
          "request",
          { type: "decline", amount: requestedAmount },
          messageId
        );
    setShow(false);
  };

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <header className="bg-dark vh-15 d-flex align-items-center justify-content-center">
          <Container fluid>
            <Row className="px-4 align-items-center text-white">
              <Col className="text-left" xs={10}>
                <div className="special-grid-3 text-white">
                  <i
                    className="iconly-Arrow-Left-Circle icli caret-holder"
                    onClick={() => navigate(-1)}
                  ></i>
                  <img
                    src={sender.photo}
                    alt="profile-img"
                    width="50px"
                    height="50px"
                    className="rounded-circle mx-3"
                  />
                  <span className="message-img-holder d-flex justify-content-around poppins-family">
                    <h6 className="m-0 font-weight-bolder">
                      {sender.fullName}
                    </h6>
                    <span className="text-smaller font-weight-lighter">
                      {sender.role}
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </header>

        <Container fluid className="vh-85 pt-3 px-50 px-md-0 y-scroll border">
          {/* messaging */}
          <Col ref={messageBox} className="vh-70 y-scroll p-4">
            <Container className="py-4">
              {messageThread[0]?.chats.map((thread, index) => (
                <div key={index}>
                  {thread.from_uid === id ? (
                    thread.type === "offer" ? (
                      <div
                        className="text-center bg-primary type-message"
                        onClick={() => {
                          setShow2(true);
                          setRequestedAmount(thread.value.amount);
                          setOfferTitle(thread.value.title);
                        }}
                      >
                        An offer was made for {thread.value.title} at{" "}
                        {thread.value.amount}
                      </div>
                    ) : thread.type === "request" ? (
                      thread.value.type === "initial" ? (
                        <div
                          className="text-center bg-info type-message text-secondary"
                          onClick={() => {
                            setShow(true);
                            setRequestedAmount(thread.value.amount);
                          }}
                        >
                          {sender.fullName} requested for {thread.value.amount}
                        </div>
                      ) : thread.value.type === "decline" ? (
                        <div className="text-center bg-decline type-message text-secondary">
                          You declined the request for {thread.value.amount}
                        </div>
                      ) : (
                        <div className="text-center bg-success type-message text-secondary">
                          You accepted and paid the N{thread.value.amount} fee
                        </div>
                      )
                    ) : (
                      <Row className="mb-2">
                        <Col className="left-msg message-item">
                          <div className="left-msg-inner">
                            {thread.type === "text" ? (
                              thread.value
                            ) : (
                              <img
                                src={thread.value}
                                alt="message-img"
                                style={{ borderRadius: "20px" }}
                                className="w-100"
                              />
                            )}
                          </div>
                          <span className="msg-time-left">
                            {new Date(thread.createdAt).toLocaleTimeString(
                              "en-us",
                              {
                                hour: "numeric",
                                minute: "numeric",
                              }
                            )}
                          </span>
                        </Col>
                      </Row>
                    )
                  ) : thread.type === "offer" ? (
                    <div
                      className="text-center bg-primary"
                      onClick={() => {
                        setShow2(true);
                        setRequestedAmount(thread.value.amount);
                        setOfferTitle(thread.value.title);
                      }}
                    >
                      An offer was made for {thread.value.title} at{" "}
                      {thread.value.amount}
                    </div>
                  ) : thread.type === "request" ? (
                    thread.value.type === "initial" ? (
                      <div
                        className="text-center bg-info type-message text-secondary"
                        onClick={() => {
                          setShow(true);
                          setRequestedAmount(thread.value.amount);
                        }}
                      >
                        {sender.fullName} requested for {thread.value.amount}
                      </div>
                    ) : thread.value.type === "decline" ? (
                      <div className="text-center bg-decline type-message text-secondary">
                        You declined the request for {thread.value.amount}
                      </div>
                    ) : (
                      <div className="text-center bg-success type-message text-secondary">
                        You accepted and paid the N{thread.value.amount} fee
                      </div>
                    )
                  ) : (
                    <Row>
                      <Col className="right-msg mb-2 message-item">
                        <div className="right-msg-inner">
                          {thread.type === "text" ? (
                            thread.value
                          ) : (
                            <img
                              src={thread.value}
                              alt="message-img"
                              style={{ borderRadius: "20px" }}
                              className="w-100"
                            />
                          )}
                        </div>
                        <span className="msg-time-right">
                          {new Date(thread.createdAt).toLocaleTimeString(
                            "en-us",
                            {
                              hour: "numeric",
                              minute: "numeric",
                            }
                          )}
                        </span>
                      </Col>
                    </Row>
                  )}
                </div>
              ))}
            </Container>
          </Col>

          {image.preview ? (
            <>
              <div className="msg-img-holder d-flex align-items-center justify-content-center h-250px">
                <img
                  src={image.preview}
                  alt="img"
                  className="msg-img w-100 h-100"
                  onClick={triggerFileInput}
                />
                <div>
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    className="ms-3 send-btn border-0 shadow-msg"
                    onClick={uploadImage}
                  >
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <InputGroup className="mt-3 h-50px">
              <Form.Control
                aria-label="Message"
                placeholder="Type Something..."
                className="react-textarea message-inputs message-input-start text-center"
                onChange={(e) => setInputText(e.target.value)}
                value={inputText}
              />
              <InputGroup.Text
                className="message-inputs"
                onClick={triggerFileInput}
              >
                <i className="fa fa-upload" aria-hidden="true"></i>
              </InputGroup.Text>
              <InputGroup.Text className="message-inputs message-input-end">
                <i className="fa fa-file-text-o" aria-hidden="true"></i>
              </InputGroup.Text>
              <Button
                variant="outline-secondary"
                id="button-addon2"
                className="ms-3 send-btn border-0 shadow-msg"
                onClick={addMessage}
              >
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </Button>
            </InputGroup>
          )}
          <input
            type="file"
            id="upload-button"
            className="d-none"
            accept=".png, .jpg, .jpeg"
            onChange={handleChange}
          />
        </Container>
      </Col>

      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-modal"
      >
        <Card className="p-5 text-center border-0">
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-left">
              <div className="fs-14">New Offer</div>
              <b style={{ fontSize: "35px" }}>N{requestedAmount}</b>
              <div className="fs-14">Wedding Photoshoot</div>
            </div>

            <div>
              <img
                src={sender.photo}
                className="rounded-circle red-border-img"
                alt="user"
              />
              <div
                className="fs-14
              "
              >
                {sender.fullName}
              </div>
            </div>
          </div>

          <Row className="text-center mt-5">
            <Col xs={6}>
              <button
                className="btn btn-theme w-100 fw-bold"
                onClick={releaseFunds}
                style={{
                  padding: "1rem 1.5rem",
                }}
              >
                Accept
              </button>
            </Col>
            <Col xs={6}>
              <button
                className="btn w-100"
                style={{
                  color: "#e00253",
                  borderColor: "#e00253",
                  backgroundColor: "#fff",
                  padding: "1rem 1.5rem",
                }}
                onClick={declineRequest}
              >
                Decline
              </button>
            </Col>
          </Row>
        </Card>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-modal"
      >
        <Card className="p-5 text-center border-0">
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-left">
              <div className="fs-14">New Offer</div>
              <b style={{ fontSize: "35px" }}>N{requestedAmount}</b>
              <div className="fs-14 text-capitalize">{offerTitle}</div>
            </div>

            <div>
              <img
                src={sender.photo}
                className="rounded-circle red-border-img"
                alt="user"
              />
              <div
                className="fs-14
              "
              >
                {sender.fullName}
              </div>
            </div>
          </div>

          <Row className="text-center mt-5">
            <Col xs={6}>
              <button
                className="btn btn-theme w-100 fw-bold"
                onClick={payForOffer}
                style={{
                  padding: "1rem 1.5rem",
                }}
              >
                Accept
              </button>
            </Col>
            <Col xs={6}>
              <button
                className="btn w-100"
                style={{
                  color: "#e00253",
                  borderColor: "#e00253",
                  backgroundColor: "#fff",
                  padding: "1rem 1.5rem",
                }}
                onClick={declineRequest}
              >
                Decline
              </button>
            </Col>
          </Row>
        </Card>
      </Modal>
    </BaseContainer>
  );
};
