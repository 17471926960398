import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NotificationsCanvas } from "../../notifications_canvas";

const image = localStorage.getItem("profileImage");

export const Header = ({ title, className }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const classes = `Header header2 bg-dark vh-15 ${className}`;

  return (
    <>
      <div className={classes}>
        <div className="px-4 w-100 justify-content-between align-items-center d-md-flex d-none">
          <i
            className="iconly-Arrow-Left-Circle icli caret-holder cursor-pointer"
            style={{ fontSize: "30px" }}
            onClick={() => navigate(-1)}
          ></i>
          <h5 style={{ fontWeight: "bolder" }} className="poppins-font m-0">
            {title}
          </h5>
          <div className="justify-content-end align-items-center d-flex">
            <button
              type="button"
              className="btn btn-transparent position-relative me-3 p-0 d-flex align-items-center"
              onClick={() => setShow(true)}
            >
              <i className="iconly-Activity icli fs-20 text-white"></i>
              {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                9+
                <span className="visually-hidden">unread messages</span>
              </span> */}
            </button>
            <Link to="/messagelist" className="no-underline">
              <button
                type="button"
                className="btn btn-transparent position-relative me-4 p-0 d-flex align-items-center"
              >
                <i className="iconly-Chat icli fs-20 text-white"></i>
                {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                  9+
                  <span className="visually-hidden">unread messages</span>
                </span> */}
              </button>
            </Link>

            <img
              src={image}
              className="rounded-circle"
              height="35"
              width="35"
              alt="account"
            />
          </div>
        </div>
        <div className="px-4 w-100 align-items-center d-md-none d-flex">
          <i
            className="iconly-Arrow-Left-Circle icli caret-holder cursor-pointer me-3"
            style={{ fontSize: "30px" }}
            onClick={() => navigate(-1)}
          ></i>
          <h6 style={{ fontWeight: "bolder" }} className="poppins-font m-0">
            {title}
          </h6>
        </div>
      </div>
      <NotificationsCanvas show={show} handleClose={handleClose} />
    </>
  );
};
