import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Api } from "../../../axiosInstance";
import Layout from "../../../components/common/layout";
import CustomButton from "../../../components/custom-button/custom-button";
import { CircularLoading } from "../../../components/loading/CircularLoading.js";
import { Lock } from "../../../components/iconly-icons";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords must match");
      return;
    }
    setIsSubmitting(true);

    try {
      const jwtToken = sessionStorage.getItem("forgotPasswordToken");
      const response = await Api.post(
        "/auth/reset-password",
        { password },
        {
          headers: {
            validateAccess: `Bearer ${jwtToken}`,
          },
        }
      );
      console.log(response?.data);
      if (response?.data?.success) {
        toast.success("Password has been successfully reset");
        setConfirmPassword("");
        setPassword("");
        navigate("/signin");
      } else {
        toast.error(response);
      }
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Layout>
        <Form>
          <div className="py-4">
            <h2 className="fw-bolder mb-4">Set New Password</h2>

            <label htmlFor="password" className="fs-14 mb-2 font-weight-bold">
              New Password
            </label>
            <div className="input-group mb-3">
              {<Lock /> && (
                <span className="input-group-text input-group-text-0 ">
                  {<Lock />}
                </span>
              )}
              <input
                type="password"
                placeholder="Enter Your Password"
                className="form-control form-control-0 ps-3 py-2"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <label htmlFor="password2" className="fs-14 mb-2 font-weight-bold">
              Confirm Password
            </label>
            <div className="input-group mb-3">
              {<Lock /> && (
                <span className="input-group-text input-group-text-0 ">
                  {<Lock />}
                </span>
              )}
              <input
                type="password"
                placeholder="Confirm Your Password"
                className="form-control form-control-0 ps-3 py-2"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <CustomButton
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? <CircularLoading /> : "Update Password"}
          </CustomButton>
        </Form>
      </Layout>
    </>
  );
};
export default NewPassword;
