import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import Cookie from "universal-cookie";
import { AiFillStar } from "react-icons/ai";
import { BaseContainer } from "../../components/dashboard/Containers";
import { Header } from "../../components/dashboard/customer-dashboard/Inspiration/Header";

import { Api } from "../../axiosInstance";

const ProjectsWithVendor = () => {
  const cookie = new Cookie();
  const jwt = cookie.get("jwt");
  const { id } = useParams();

  const [name, setName] = useState("");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    Api.get(`/user/${id}`)
      .then((res) => {
        setName(res.data.data.fullName);

        Api.get(`/projects/list-of-projects/${id}`)
          .then((res) => {
            setProjects(res.data.data.results);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, jwt]);

  console.log(projects);

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <Header title={`Projects with ${name}`} />
        <div className="m-10 VCDiv vh-85 d-flex flex-column align-items-center y-scroll px-2 px-md-4">
          {projects.map((project, index) => {
            let statusColor;
            if (project.bid.status === "accepted") statusColor = "text-success";
            else if (project.bid.status === "rejected")
              statusColor = "text-danger";
            else if (project.bid.status === "pending")
              statusColor = "text-warning";
            else statusColor = "text-theme";

            return (
              <Card
                className="mb-3 w-75 full-width-small border-0 shadow-sm text-small"
                key={index}
              >
                <Card.Body>
                  <Container className="fs-6 p-0">
                    <Row>
                      <Col xs={12}>
                        <span className="font-weight-bold fs-5">
                          {project.projectDetails.name}
                        </span>
                      </Col>
                    </Row>

                    <div className="mt-3 d-flex align-items-center justify-content-between">
                      <div>
                        Project Cost:{" "}
                        {"N" + project.projectDetails.budget || "Unspecified"}
                      </div>
                      <div className="text-right">
                        Approved:{" "}
                        {project.bid.approvedAmount
                          ? "N" + project.bid.approvedAmount
                          : "Unspecified"}
                      </div>
                    </div>

                    <div className="mt-3 mb-4 d-flex align-items-center justify-content-between">
                      <div className={statusColor}>
                        Status: {project.bid.status}
                      </div>
                      <div className="text-right">
                        Rating: {" "}<AiFillStar style={{ color: "#FBB700" }} />
                        <AiFillStar style={{ color: "#FBB700" }} />
                        <AiFillStar style={{ color: "#FBB700" }} />
                        <AiFillStar style={{ color: "#FBB700" }} />
                        <AiFillStar style={{ color: "#FBB700" }} />
                      </div>
                    </div>

                    <Row className="mb-3">
                      <Col
                        xs={12}
                        className="text-right text-small"
                        style={{ fontWeight: 300 }}
                      >
                        {new Date(
                          project.projectDetails.createdAt
                        ).toDateString()}
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      </Col>
    </BaseContainer>
  );
};

export default ProjectsWithVendor;
