import React from "react";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { Row, Col } from "react-bootstrap";

// type props = {
//   children: React.ReactNode;
// };

const Logout = () => {
  document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  sessionStorage.removeItem("userId");

  window.location.href = "/signin";
};

export const SideBar = (props) => {
  return (
    <Menu {...props}>
      <ul className="nav flex-column mb-3 justify-content-between side-specific-height text-small d-flex vh-85 y-scroll">
        <li className="nav-item menu-item">
          <NavLink
            to="/dashboard"
            className="nav-link text-dark px-4"
            aria-current="page"
          >
            <i className={`iconly-Home icli fs-20 align-middle me-3`}></i>
            <span className="align-middle fs-14">Home</span>
          </NavLink>
        </li>
        <li className="nav-item menu-item">
          <NavLink
            to="/inspirationboard"
            className="nav-link text-dark px-4"
            aria-current="page"
          >
            <i className={`iconly-Category icli fs-20 align-middle me-3`}></i>
            <span className="align-middle fs-14">Inspiration board</span>
          </NavLink>
        </li>
        <li className="nav-item menu-item">
          <NavLink
            to="/projects"
            className="nav-link text-dark px-4"
            aria-current="page"
          >
            <i className={`iconly-Document icli fs-20 align-middle me-3`}></i>
            <span className="align-middle fs-14">Projects</span>
          </NavLink>
        </li>
        <li className="nav-item menu-item">
          <NavLink
            to="/myvendors"
            className="nav-link text-dark px-4"
            aria-current="page"
          >
            <i
              className="iconly-User3 icli fs-20 align-middle me-3"
              aria-hidden="true"
            ></i>
            <span className="align-middle fs-14">My Vendors</span>
          </NavLink>
        </li>
        <li className="nav-item menu-item">
          <NavLink
            to="/settings"
            className="nav-link text-dark px-4"
            aria-current="page"
          >
            <i className={`iconly-Setting icli fs-20 align-middle me-3`}></i>
            <span className="align-middle fs-14">Settings</span>
          </NavLink>
        </li>
        <li className="nav-item menu-item border-top">
          <Row className="mt-3">
            <Col xs={6} className="text-center">
              <NavLink
                to="/notifications"
                className="nav-link text-dark px-4"
                aria-current="page"
              >
                <i
                  className={`iconly-Activity icli fs-20 align-middle me-2`}
                ></i>
                <span className="position-absolute translate-middle bg-danger border border-light rounded-circle text-white badge-small">
                  9+
                  <span className="visually-hidden">New alerts</span>
                </span>
                <span className="align-middle fs-14">Notifications</span>
              </NavLink>
            </Col>
            <Col xs={6} className="text-center">
              <NavLink
                to="/messagelist"
                className="nav-link text-dark px-4"
                aria-current="page"
              >
                <i className={`iconly-Chat icli fs-20 align-middle me-2`}></i>
                <span className="position-absolute translate-middle bg-danger border border-light rounded-circle text-white badge-small">
                  9+
                  <span className="visually-hidden">New alerts</span>
                </span>
                <span className="align-middle fs-14">Messages</span>
              </NavLink>
            </Col>
          </Row>
        </li>
        <div className="position-absolute w-100 bottom-0">
          <ul className="nav flex-column logout-btn">
            <li className="nav-item h-100">
              <button
                onClick={Logout}
                className="nav-link w-100 bg-black h-100 bg-black border-0 text-white"
                aria-current="page"
              >
                LOG OUT
              </button>
            </li>
          </ul>
        </div>
      </ul>
    </Menu>
  );
};
