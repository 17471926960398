import { Modal } from "react-bootstrap";
import React from "react";
import { InspirationsCarousel } from "./InspirationsCarousel";

export const CarouselModal = ({
  show,
  handleClose,
  projectToShow,
  vendorName,
}) => {
  
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title id="contained-modal-title-vcenter">
          <b className="fs-6">Projects by {vendorName}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InspirationsCarousel projectToShow={projectToShow} />
      </Modal.Body>
    </Modal>
  );
};
