import React from "react";
import { BsPlusCircleFill } from "react-icons/bs";

const AllSubmission = () => (
  //   <>
  <div className="VCDiv">
    <div className=" d-flex  flex-column align-items-center pb-0 justify-content-center mb-4">
      <div className=" w-50  bg-white p-4 ">
        <div className="d-flex justify-content-around">
          <div className="border p-4">image</div>
          <div>
            <h4 className="text1"> Justine & Grace Wedding </h4>{" "}
            <div className="budgetLocation">
              <p className="text3">
                ID: <span> Y72828 </span>{" "}
              </p>{" "}
              <p className="text3">
                Updated on: <span> 12 June 2021 </span>{" "}
              </p>{" "}
            </div>{" "}
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-around">
          <div className=""> Edit Submission </div>{" "}
          <div> Delete Submission </div>{" "}
        </div>{" "}
      </div>
    </div>{" "}
    <div className=" d-flex  flex-column align-items-center pb-0 justify-content-center mb-4">
      <div className=" w-50  bg-white p-4 ">
        <div className="d-flex justify-content-around">
          <div className="border p-4">image</div>
          <div>
            <h4 className="text1"> Justine & Grace Wedding </h4>{" "}
            <div className="budgetLocation">
              <p className="text3">
                ID: <span> Y72828 </span>{" "}
              </p>{" "}
              <p className="text3">
                Updated on: <span> 12 June 2021 </span>{" "}
              </p>{" "}
            </div>{" "}
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-around">
          <div className=""> Edit Submission </div>{" "}
          <div> Delete Submission </div>{" "}
        </div>{" "}
      </div>
    </div>
    <div>
      <BsPlusCircleFill />
    </div>
  </div>
);
export default AllSubmission;
