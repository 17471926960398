import React, { useState } from "react";
import OtpInput from "react18-otp-input";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Api } from "../../../axiosInstance";

import Layout from "../../../components/common/layout";
import CustomButton from "../../../components/custom-button/custom-button";
import { CircularLoading } from "../../../components/loading/CircularLoading.js";

const Otp = (props) => {
  const [error, setError] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate();
  const handleChange = (token) => setToken(token);

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    if (!token) {
      toast.error("Please enter a valid token");
      return;
    }
    const payload = {
      email: sessionStorage.getItem("forgotPasswordEmail"),
      token,
    };
    setSubmitting(true);
    try {
      const response = await Api.post("/auth/validate-otp", payload);
      console.log(response?.data);
      if (response?.data?.success) {
        const jwtToken = response?.data.data.jwtToken;
        sessionStorage.setItem("forgotPasswordToken", jwtToken);
        toast.success(response?.data.message);
        resetForm();
        navigate("/newpassword");
      } else {
        toast.error(response);
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      if (e.response) {
        if (e.response?.data) {
          setError(e.response.data?.message);
          return;
        }
        setError("An error occurred");
        return;
      }
    }
  };

  const resendCode = async () => {
    const payload = { email: sessionStorage.getItem("forgotPasswordEmail") };
    try {
      const response = await Api.post("/auth/verification-email", payload);
      if (response?.data?.success) {
        toast.success("verification email resent");
      }
    } catch (e) {
      if (e.response) {
        if (e.response?.data) {
          toast.error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Layout>
      <Formik
        initialValues={{ token: "" }}
        onSubmit={async (values, { setSubmitting, resetForm }) =>
          await handleSubmit(values, resetForm, setSubmitting)
        }
      >
        {({ isSubmitting }) => (
          <Form>
            <p className="text-end">
              Already Have An Account? &nbsp;
              <Link to="/signin" className="text-theme">
                Log In
              </Link>
            </p>

            <div className="py-4">
              <h2 className="fw-bolder">Verify Your Email Address</h2>
              <p>
                Type in the code sent to your email address to confirm your
                identity.{" "}
              </p>
              <p className="error">{error}</p>
              <OtpInput
                value={token}
                onChange={handleChange}
                otpType="number"
                placeholder="******"
                disabled={false}
                secure
                autoFocus
                numInputs={6}
                containerStyle="otp d-flex flex-row justify-content-center mt-"
                inputStyle="m-2 text-center form-control rounded"
                name="token"
                isInputNum={true}
                // className="OTP"
              />
              <div className="text-end">
                <span onClick={resendCode} className="fs-14">
                  Resend Code
                </span>
              </div>
            </div>

            <CustomButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? <CircularLoading /> : "Confirm Code"}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};
export default Otp;
// }
