import React, { useState } from "react";
import OtpInput from "react18-otp-input";
import { Formik, Form } from "formik";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Api } from "../../../axiosInstance";

import Layout from "../../../components/common/layout";
import CustomButton from "../../../components/custom-button/custom-button";
import { CircularLoading } from "../../../components/loading/CircularLoading.js";

const VerifyAccount = (props) => {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const role = new URLSearchParams(search).get("role");

  const [error, setError] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate();

  const handleChange = (token) => setToken(token);

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const payload = {
      email,
      token,
    };
    setSubmitting(true);
    try {
      const response = await Api.post("/auth/verify-account", payload);
      console.log(response?.data);
      if (response?.data?.success) {
        toast.success(response?.data.message);
        resetForm();
        navigate("/signin?role=" + role);
      } else {
        toast.error(response);
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      if (e.response) {
        if (e.response?.data) {
          setError(e.response.data?.message);
          return;
        }
        setError("An error occurred");
        return;
      }
    }
  };

  const resendCode = async () => {
    const payload = { email };
    try {
      const response = await Api.post("/auth/verification-email", payload);
      if (response?.data?.success) {
        toast.success("verification email resent");
      }
    } catch (e) {
      if (e.response) {
        if (e.response?.data) {
          toast.error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Layout>
      <Formik
        initialValues={{ token: "" }}
        onSubmit={async (values, { setSubmitting, resetForm }) =>
          await handleSubmit(values, resetForm, setSubmitting)
        }
      >
        {({ isSubmitting }) => (
          <Form>
            <p className="text-end">
              Already Have An Account?
              <Link
                to="/signin"
                className="ms-2"
                style={{ color: role === "vendor" ? "#6f0463" : "#e00253" }}
              >
                Log In
              </Link>
            </p>
            <div className="py-4">
              <h2 className="fw-bolder">Verify Your Email Address</h2>
              <p className="text-muted text-small">
                Type in the code sent to your email address to confirm your
                identity.
              </p>
              <p className="error">{error}</p>
              <p className="mt-4 mb-1 ps-2">Code</p>
              <OtpInput
                value={token}
                onChange={handleChange}
                otpType="number"
                placeholder="******"
                disabled={false}
                secure
                autoFocus
                numInputs={6}
                containerStyle="otp d-flex flex-row justify-content-center mt-"
                inputStyle="m-2 text-center form-control rounded"
                name="token"
                isInputNum={true}
              />
              <p className="text-end pe-2 mt-1 cursor-pointer embigen " onClick={resendCode}>
                Resend Code
              </p>
            </div>

            <CustomButton
              type="submit"
              disabled={isSubmitting}
              style={{
                backgroundColor: role === "vendor" ? "#6f0463" : "#e00253",
              }}
            >
              {isSubmitting ? <CircularLoading /> : "Confirm Code"}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};
export default VerifyAccount;
