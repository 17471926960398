import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  query,
  where,
} from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Header } from "../../../components/dashboard/customer-dashboard/Inspiration/Header";
// import { } from "react-router-dom";
import { Link } from "react-router-dom";
import { BaseContainer } from "../../../components/dashboard/Containers";
import { firestore } from "../../../services/firebase";
// import { DefaultProfileImage } from "../../../components/common/DefaultProfileImage";

export const MessageList = () => {
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);

  let userID = window.sessionStorage.getItem("userId");

  useEffect(() => {
    const q = query(
      collection(firestore, "messages"),
      where("members", "array-contains", userID),
      limit(25)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const msgs = [];
      querySnapshot.forEach(async (docSnap) => {
        // const { members } = docSnap.data();
        // console.log(members);

        // check msgs array to make sure there are no records with duplicate members
        // const isDuplicate = msgs.some((msg) => {
        //   return msg.members.every((member) => {
        //     return members.includes(member);
        //   });
        // });
        // console.log(isDuplicate);
        // if (isDuplicate) return;

        // const senderID = members.find((id) => id !== userID);
        // getDoc(doc(firestore, "users", senderID)).then((senderSnap) => {
        //   // console.log(senderSnap.data());

        //   // update the sender array by adding the sender data to it
        //   setSender(senderSnap.data());
        // });

        // sender: senderSnap.data()gf
        msgs.push({ ...docSnap.data(), id: docSnap.id });
      });

      // console.log(msgs);
      setMessages(msgs);
    });

    return unsubscribe;
  }, [userID]);

  useEffect(() => {
    messages.forEach((message) => {
      const toSendId = message.members.find((id) => id !== userID);

      getDoc(doc(firestore, "users", toSendId)).then((senderSnap) => {
        const { fullName, photo, role } = senderSnap.data();

        // check if the sender is already in the chats array
        const isDuplicate = chats.some((chat) => {
          return chat.fullName === fullName;
        });

        if (isDuplicate) return;
        setChats((prev) => [
          ...prev,
          {
            fullName,
            photo,
            role,
          },
        ]);
      });
    });
  }, [chats, messages, userID]);

  // const messages = [1, 2, 3, 4, 5, 6];

  console.log(chats);

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <Header title="Messages" />

        {/* messaging */}
        <Container fluid className="vh-85 body-bg pt-3 px-25 y-scroll vh-xs-75">
          {messages.map((message, index) => {
            const toSendId = message.members.find((id) => id !== userID);
            let _message =
              message.chats[message.chats.length - 1].type === "text"
                ? message.chats[message.chats.length - 1].value
                : "Other";

            // check if _message is more than 50 characters and if so slice it and add ... to the end
            if (_message.length > 50) _message = _message.slice(0, 50) + "...";

            return (
              <Link
                to={`/messages/${toSendId}`}
                key={index}
                className="text-dark no-underline"
              >
                <Card className="h-100px mb-2">
                  <Card.Body>
                    <Row className=" h-100">
                      <Col md={6} xs={8} className="d-flex align-items-center">
                        <div className="special-grid">
                          <img
                            src={
                              chats[index]?.photo ||
                              "https://via.placeholder.com/100"
                            }
                            alt="profile-img"
                            width="50px"
                            height="50px"
                            className="rounded-circle me-3"
                          />
                          <span className="message-img-holder d-flex justify-content-around poppins-family">
                            <h6 className="m-0 font-weight-bolder">
                              {chats[index]?.fullName}
                            </h6>
                            <span className="text-muted text-small">
                              {_message}
                            </span>
                          </span>
                        </div>
                      </Col>
                      <Col
                        md={6}
                        xs={4}
                        className="text-right text-small text-muted pe-4"
                      >
                        <div className="mt-1">
                          {new Date(
                            message.chats[message.chats.length - 1].createdAt
                          ).toLocaleTimeString("en-us", {
                            hour: "numeric",
                            minute: "numeric",
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Link>
            );
          })}
        </Container>
      </Col>
    </BaseContainer>
  );
};
