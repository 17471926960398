export const User2 = () => (
  <i className="iconlyBulk-User2 fs-22 border-end-0">
    <span className="path1"></span>
    <span className="path2"></span>
    <span className="path3"></span>
    <span className="path4"></span>
  </i>
);

export const User = () => (
  <i className="iconlyBulk-User2 fs-22 border-end-0">
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
);

export const Message = () => (
  <i className="iconlyBulk-Message fs-22 border-end-0">
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
);

export const Lock = () => (
  <i className="iconlyBulk-Lock fs-22 border-end-0">
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
);

export const Home = () => (
  <i className="iconlyBulk-Home fs-22 border-end-0"></i>
);

export const EditSquare = () => (
  <i className="iconlyBulk-Edit-Square fs-22 border-end-0">
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
);

export const Document = () => (
  <i className="iconlyBulk-Document fs-22 border-end-0">
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
);

export const Category = () => (
  <i className="iconlyBulk-Category fs-22 border-end-0">
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
);

export const Setting = () => (
  <i className="iconlyBulk-Setting fs-22 border-end-0">
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
);
