import React, { useState } from "react";
import { Col, Modal, Card, Form, Row } from "react-bootstrap";

import { Header } from "../../components/dashboard/customer-dashboard/Inspiration/Header";
import ChangePassword from "../../components/dashboard/customer-dashboard/settings/changepassword";
// import Deactivate from "../../components/dashboard/customer-dashboard/settings/DeactivateAccount/deactivate";
import Report from "../../components/dashboard/customer-dashboard/settings/reportAccount/report";
// import LiveSupport from "../../components/dashboard/customer-dashboard/settings/LiveSupport/livesupport";
import { BaseContainer } from "../../components/dashboard/Containers";

const CustomerSettings = () => {
  const [settingsMethod, setSettingsMethod] = useState("report");
  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  }

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <Header title="Settings" />
        <div className="d-flex VCDiv vh-85 y-scroll ">
          <div className="w-100 h-90 bg-white d-flex">
            <Col sm={4} className="border-right p-4">
              <ul className="nav flex-column mb-3">
                <li className="nav-item pb-2">
                  <div
                    className={`p-2 w-1/2  Vendors  ${
                      settingsMethod === "report" ? "text-danger" : ""
                    } text-dark-400`}
                    checked={settingsMethod === "report"}
                    onClick={() => setSettingsMethod("report")}
                  >
                    Report an Issue{" "}
                  </div>{" "}
                </li>
                {/* <li className="nav-item pb-2">
                  <div
                    className={`p-2 w-1/2  Vendors  ${
                      settingsMethod === "support" ? "text-danger" : ""
                    } text-dark-400`}
                    checked={settingsMethod === "support"}
                    onClick={() => setSettingsMethod("support")}
                  >
                    Live Support{" "}
                  </div>{" "}
                </li> */}
                <li className="nav-item pb-2">
                  <div
                    className={`p-2 w-100   Vendors word-wrap  ${
                      settingsMethod === "changePassword" ? "text-danger" : ""
                    } text-dark-400`}
                    checked={settingsMethod === "changePassword"}
                    onClick={() => setSettingsMethod("changePassword")}
                  >
                    Change&nbsp;Password{" "}
                  </div>{" "}
                </li>
                <li className="nav-item pb-2">
                  <div
                    className={`p-2 w-1/2  Vendors  ${
                      settingsMethod === "deactivate" ? "text-danger" : ""
                    } text-dark-400`}
                    // checked={settingsMethod === "deactivate"}
                    onClick={() => setShow(true)}
                  >
                    Deactivate&nbsp;Account{" "}
                  </div>{" "}
                </li>
              </ul>
            </Col>
            <Col sm={8} className="p-4">
              {settingsMethod === "report" ? <Report /> : null}
              {/* {settingsMethod === "support" ? <LiveSupport /> : null} */}
              {settingsMethod === "changePassword" ? <ChangePassword /> : null}
              {/* {settingsMethod === "deactivate" ? <Deactivate /> : null} */}
            </Col>
          </div>
        </div>
      </Col>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="small-modal-2"
      >
        <Card className="py-4 px-3 border-0">
          <Card.Body>
            <div className="text-center">
              <h6 className="mb-4">
                <b>We're sorry to see you Leave</b>
              </h6>
              <p className="text-muted text-small">
                You can contact us if you have an issue we can resolve please
                contact us. If you decide to deactivate your account, your data
                would be stored on our servers for 60 days before It's
                permanently deleted.
              </p>
            </div>
            <Form autoComplete="off">
              <Form.Group
                className="mt-4 text-small"
                controlId="exampleForm.ControlTextarea2"
              >
                <Form.Label>
                  <b>Password</b>
                </Form.Label>
                <Form.Control
                  placeholder="Enter Password"
                  aria-label="Password"
                  className="p-3 react-textarea border"
                  type="password"
                  // onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Row>
                <Col className="text-center" xs={12}>
                  <button
                    className="btn border-theme text-theme p-3 mt-4 w-100"
                    // onClick={handleDeactivate}
                  >
                    Deactivate
                  </button>
                </Col>
              </Row>
              <Row>
                <Col className="text-center" xs={12}>
                  <button
                    className="btn btn-theme w-100 p-3 mt-2 font-weight-bold"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Modal>
    </BaseContainer>
  );
};
export default CustomerSettings;
