import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  Placeholder,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Cookie from "universal-cookie";

import { fetchCategories } from "../../services/customer/Inspirationboard";
import { Api } from "../../axiosInstance";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import CustomButton from "../../components/custom-button/custom-button";
import { CircularLoading } from "../../components/loading/CircularLoading";
import { EmptyBoard } from "../../components/dashboard/customer-dashboard/Inspiration/EmptyBoard";
import { BaseContainer } from "../../components/dashboard/Containers";
import { NotificationsCanvas } from "../../components/dashboard/notifications_canvas";

const Projects = () => {
  const cookies = new Cookie();
  const jwt = cookies.get("jwt");

  const navigate = useNavigate();

  const [publishedProjects, setpublishedProjects] = useState([]);
  const [draftProjects, setDraftProjects] = useState([]);
  const [searchedProjects, setSearchedProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [budget, setBudget] = useState("");
  const [duration, setDuration] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("published");
  const [reRender, setReRender] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const response = await fetchCategories();
      const { data } = response.data;
      setCategories(data);
    } catch (e) {
      console.log(e);
    }
  };

  const categoryList = categories.map((category) => {
    return (
      <option key={category._id} value={category.name}>
        {category.name}
      </option>
    );
  });


  useEffect(() => {
    Api.get("/projects?filter=published", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => {
        setpublishedProjects(response.data.data.results);
      })
      .catch((error) => {
        console.error(error);
      });

    Api.get("/projects?filter=draft", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => {
        setDraftProjects(response.data.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isSubmitting, jwt, reRender]);

  const handleClose = () => {
    setName("");
    setDuration("");
    setBudget("");
    setDescription("");
    setLocation("");
    setShow(false);
    setShow4(false);
  };
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);

  const handleClose3 = () => {
    setShow3(false);
    setDeleteId("");
  };
  const handleShow3 = () => setShow3(true);

  const switchModal = () => {
    setShow(false);
    setShow2(true);
  };

  const switchModal2 = () => {
    setShow2(false);
    setShow(true);
  };

  const continueDraft = (name, duration, budget, description, location, id) => {
    setName(name);
    setDuration(duration);
    setBudget(budget);
    setDescription(description);
    setLocation(location);
    setUpdateId(id);
    handleShow();
  };

  const handleSubmit = async (filter) => {
    setIsSubmitting(true);
    const payload = {
      name,
      budget,
      duration,
      location,
      category,
      description,
      type: filter,
    };

    let response;
    try {
      if (!updateId) {
        response = await Api.post("/projects", payload, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      } else {
        response = await Api.put(`/projects/${updateId}`, payload, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      }
      if (response?.data?.success) {
        toast.success(response?.data.message);
        setShow(false);
        setShow2(false);
        setName("");
        setBudget("");
        setDuration("");
        setLocation("");
        setDescription("");
        setType(filter);
        setUpdateId("");
      } else {
        toast.error(response);
      }
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      if (e.response) {
        if (e.response?.data) {
          // setError(e.response.data?.message);
          return;
        }
        // setError("An error occurred. Please try again.");
        return;
      }
    }
  };

  const openDeleteModal = (id) => {
    setDeleteId(id);
    handleShow3();
  };

  const deleteProject = async () => {
    setIsSubmitting(true);
    setShow3(false);
    setDeleteId("");
    try {
      const response = await Api.delete(`/projects/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (response?.data?.success) {
        setReRender(Math.random());
        toast.success(response?.data.message);
      } else {
        toast.error(response);
      }
      setIsSubmitting(false);
    } catch (e) {
      setReRender(Math.random());
      if (e.response) {
        if (e.response?.data) {
          // setError(e.response.data?.message);
          return;
        }
        // setError("An error occurred. Please try again.");
        return;
      }
    }
  };

  const getProjectsBySearch = async (search) => {
    setSearch(search);
    if (type === "published") {
      const searchedPublishedProjects = publishedProjects.filter((project) =>
        project.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchedProjects(searchedPublishedProjects);
    } else {
      const searchedDraftProjects = draftProjects.filter((project) =>
        project.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchedProjects(searchedDraftProjects);
    }
  };

  const image = localStorage.getItem("profileImage");

  let projects = [];
  if (search) projects = searchedProjects;
  else if (type === "published") projects = publishedProjects;
  else if (type === "draft") projects = draftProjects;

  const placeholderArray = [1, 2, 3, 4];
  const placeholder = placeholderArray.map((item) => {
    return (
      <Card className="border-0 w-50 mb-4 full-width-75" key={item}>
        <Card.Body>
          <Placeholder
            as={Card.Title}
            animation="glow"
            className="mb-0 mt-3 d-flex justify-content-between align-items-center"
          >
            <Placeholder xs={5} /> <Placeholder xs={2} />
          </Placeholder>
          <Placeholder
            as={Card.Title}
            animation="glow"
            className="mb-0 mt-2 text-left"
          >
            <Placeholder xs={4} />
          </Placeholder>
          <Placeholder
            as={Card.Text}
            animation="glow"
            className="mb-0 mt-3 text-left"
          >
            <Placeholder xs={6} />
          </Placeholder>
        </Card.Body>
        <Card.Footer className="bg-white mt-3 p-3 text-center d-flex justify-content-around">
          <Placeholder animation="glow" className="w-100">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder animation="glow" className="w-100">
            <Placeholder xs={6} />
          </Placeholder>
        </Card.Footer>
      </Card>
    );
  });

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <div className="Header header2 bg-dark d-flex flex-column justify-content-between">
          <div className="px-4 w-100 justify-content-between align-items-center d-none d-md-flex mt-2">
            <i
              className="iconly-Arrow-Left-Circle icli caret-holder mt-2 cursor-pointer"
              style={{ fontSize: "30px" }}
              onClick={() => navigate(-1)}
            ></i>
            <b className="mt-2 fs-6 poppins-font">Projects</b>
            <div className="justify-content-end align-items-center d-flex mt-2">
              <button
                type="button"
                className="btn btn-transparent position-relative me-3 p-0 d-flex align-items-center"
                onClick={() => setShow4(true)}
              >
                <i className="iconly-Activity icli fs-20 text-white"></i>
                {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                  9+
                  <span className="visually-hidden">unread messages</span>
                </span> */}
              </button>
              <Link to="/messagelist" className="no-underline">
                <button
                  type="button"
                  className="btn btn-transparent position-relative me-4 p-0 d-flex align-items-center"
                >
                  <i className="iconly-Chat icli fs-20 text-white"></i>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                    9+
                    <span className="visually-hidden">unread messages</span>
                  </span> */}
                </button>
              </Link>

              <img
                src={image ? image : "https://via.placeholder.com/100"}
                className="rounded-circle"
                height="35"
                width="35"
                alt="account"
              />
            </div>
          </div>

          <div className="w-100 d-none d-md-flex flex-column align-items-center">
            <div className="input-group .text-white bg-white w-50 searchbar">
              <span
                className="input-group-text text-white no-border vw-50 bg-white"
                id="search-addon"
              >
                <BiSearch />
              </span>
              <input
                type="search"
                className="form-control text-white border shadow-sm text-white no-border bg-white"
                placeholder="Search for a Project"
                aria-label="Search"
                aria-describedby="search-addon"
                onChange={(e) => getProjectsBySearch(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex d-md-none h-33"></div>
          <div className="d-flex d-md-none align-items-center px-4 w-100">
            <i
              className="iconly-Arrow-Left-Circle icli caret-holder mt-2 cursor-pointer me-3"
              style={{ fontSize: "30px" }}
              onClick={() => navigate(-1)}
            ></i>
            <b className="mt-2 fs-6 poppins-font">Projects</b>
          </div>

          <div className="d-flex justify-content-center">
            <div
              className={`p-4 w-1/2 nav-link-class Vendors ${
                type === "published"
                  ? "text-white border-bottom-white border-white font-weight-bold"
                  : ""
              } text-gray-400`}
              checked={type === "published"}
              onClick={() => setType("published")}
            >
              Published
            </div>
            <div
              className={`p-4 w-1/2 nav-link-class Vendors ${
                type === "draft"
                  ? "text-white border-bottom-white border-white font-weight-bold "
                  : ""
              } text-gray-400`}
              checked={type === "draft"}
              onClick={() => setType("draft")}
            >
              Drafts
            </div>
          </div>
        </div>

        <div className="m-10 vh-75 y-scroll VCDiv ">
          <div className="d-flex align-items-center text-center pb-3 flex-column">
            {isLoading ? (
              placeholder
            ) : typeof publishedProjects !== "undefined" &&
              publishedProjects.length === 0 &&
              typeof draftProjects !== "undefined" &&
              draftProjects.length === 0 ? (
              <div style={{ width: "300px" }}>
                <EmptyBoard
                  header="No Projects Yet"
                  footer="Looks like you've not added anything here yet. Start adding projects now"
                />
              </div>
            ) : (
              // eslint-disable-next-line no-eval
              eval(projects).map((project) => {
                let bidPhrase;
                if (project.bids.length === 1) bidPhrase = "1 bid";
                else bidPhrase = `${project.bids.length} bids`;
                return (
                  <Card
                    key={project._id}
                    className="mb-3 w-50 full-width-75 border-0"
                  >
                    <Card.Body className="py-0">
                      <Card.Title>
                        <Container fluid className="p-0 p-md-2">
                          <Row className="mb-2 mb-md-3 mt-4">
                            <Col xs={8}>
                              <div className="col-xs-6 left-header-item">
                                {project.name}
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div className="col-xs-6 right-header-item text-small">
                                {bidPhrase} so far
                              </div>
                            </Col>
                          </Row>
                          <div className="text-small">
                            <Row className="mb-3 text-muted">
                              <Col xs={12}>Budget: {project.budget}</Col>
                            </Row>
                            <Row className="mb-3 text-muted">
                              <Col xs={12}>
                                Available for {project.duration}
                              </Col>
                            </Row>
                            {/* <Row className="text-muted">
                              <Col xs={12}>{project.description}</Col>
                            </Row> */}
                          </div>
                        </Container>
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer className="p-0">
                      <Container className="p-0">
                        {type === "published" ? (
                          <>
                            <div className="grid-2 gap-0">
                              <div className="text-center py-3">
                                <Link
                                  to={`/bids/${project._id}/${project.name}`}
                                  style={{ width: "auto" }}
                                >
                                  <span>View bids</span>
                                </Link>
                              </div>
                              <div className="text-center py-3 border-left">
                                <span
                                  onClick={() => openDeleteModal(project._id)}
                                  style={{ width: "auto" }}
                                  className="text-dark cursor-pointer"
                                >
                                  Delete Submission
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <Col
                            xs={12}
                            className="text-center text-dark py-3 cursor-pointer"
                            onClick={() =>
                              continueDraft(
                                project.name,
                                project.duration,
                                project.budget,
                                project.description,
                                project.location,
                                project._id
                              )
                            }
                          >
                            Continue Editing
                          </Col>
                        )}
                      </Container>
                    </Card.Footer>
                  </Card>
                );
              })
            )}

            <div onClick={handleShow}>
              <div className="">
                <AiFillPlusCircle className="plusIcon" />{" "}
              </div>
            </div>

            <Modal
              show={show}
              onHide={handleClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <b className="fs-5">Create A New Project</b>
              </Modal.Header>
              <Modal.Body className="show-grid px-1 px-md-5 mt-2">
                <Form>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="fw-bold">
                            Name of project
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter name"
                            autoFocus
                            required
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="fw-bold">Budget</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Budget"
                            onChange={(e) => setBudget(e.target.value)}
                            value={budget}
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">Duration</Form.Label>
                          <Form.Select
                            onChange={(e) => setDuration(e.target.value)}
                            value={duration}
                          >
                            <option>Select Duration</option>
                            <option value="1 week">1 week</option>
                            <option value="2 weeks">2 weeks</option>
                            <option value="3 weeks">3 weeks</option>
                            <option value="4 weeks">4 weeks</option>
                            <option value="5 weeks">5 weeks</option>
                            <option value="6 weeks">6 weeks</option>
                            <option value="7 weeks">7 weeks</option>
                            <option value="8 weeks">8 weeks</option>
                            <option value="9 weeks">9 weeks</option>
                            <option value="10 weeks">10 weeks</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="fw-bold">Location</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Select Location"
                            onChange={(e) => setLocation(e.target.value)}
                            value={location}
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">Category</Form.Label>
                          <Form.Select
                            onChange={(e) => setCategory(e.target.value)}
                            value={category}
                          >
                            <option>Select Category</option>
                            {categoryList}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label className="fw-bold">Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Describe the project"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        autoComplete="off"
                        resize="none"
                      />
                    </Form.Group>
                  </Container>
                </Form>
              </Modal.Body>
              <Modal.Footer className="px-2 px-md-5 text-right">
                <Container className="m-0 px-2 px-md-2">
                  <button
                    className="btn btn-theme py-3 px-4 fw-bold w-sm-100"
                    onClick={switchModal}
                  >
                    Continue
                  </button>
                </Container>
              </Modal.Footer>
            </Modal>

            <Modal
              show={show2}
              onHide={handleClose2}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{ alignItems: "center" }}
            >
              <Container style={{ maxWidth: 400 }}>
                <Modal.Header>
                  <b className="fs-5">Event Preview</b>
                  <div>
                    <p className="text-small fw-normal m-0">
                      This is how your event would appear to vendors
                    </p>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <Card className="text-center">
                    <Card.Body>
                      <Card.Title>
                        <Container className="p-0">
                          <div className="mb-2 d-flex align-items-center justify-content-between">
                            <div className="col-xs-6 left-header-item text-small">
                              {name}
                            </div>
                            <div className="text-small">
                              <div className="col-xs-6 right-header-item text-small">
                                0 bids so far
                              </div>
                            </div>
                          </div>
                          <div className="text-small">
                            <Row className="mb-2 text-muted text-small">
                              <Col xs={12}>Budget: {budget}</Col>
                            </Row>
                            <Row className="mb-5 text-muted text-small">
                              <Col xs={12}>Available for {duration}</Col>
                            </Row>
                            <Row className="text-muted text-small">
                              <Col xs={12}>{description}</Col>
                            </Row>
                          </div>
                        </Container>
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer>Apply</Card.Footer>
                  </Card>

                  <p
                    className="text-danger cursor-pointer text-right mt-2"
                    onClick={switchModal2}
                  >
                    Go back to editing
                  </p>
                </Modal.Body>

                <Modal.Footer>
                  <CustomButton
                    style={{
                      color: "#e00253",
                      borderColor: "#e00253",
                      backgroundColor: "#fff",
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => handleSubmit("draft")}
                  >
                    {isSubmitting ? <CircularLoading /> : "Save as draft"}
                  </CustomButton>

                  <CustomButton
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => handleSubmit("published")}
                  >
                    {isSubmitting ? <CircularLoading /> : "Publish Event"}
                  </CustomButton>
                </Modal.Footer>
              </Container>
            </Modal>

            <Modal
              show={show3}
              onHide={handleClose3}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              dialogClassName="small-modal-2"
            >
              <Card className="py-4 px-3 border-0">
                <Card.Body>
                  <div className="text-center">
                    <h5 className="mb-3">
                      <b>Delete Project</b>
                    </h5>
                    <p className="text-muted text-small mb-1">
                      Are you sure you want to delete this project?
                    </p>
                    <p className="text-muted text-small">
                      This action is irreversible
                    </p>
                  </div>
                  <Row>
                    <Col className="text-center" xs={6}>
                      <button
                        className="btn border-theme text-theme w-100 custom-btn-3 p-3 mt-4"
                        onClick={handleClose3}
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col className="text-center" xs={6}>
                      <button
                        className="btn btn-theme w-100 p-3 mt-4 font-weight-bold"
                        onClick={deleteProject}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <CircularLoading /> : "Delete"}
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Modal>
          </div>
        </div>

        <NotificationsCanvas show={show4} handleClose={handleClose} />
      </Col>
    </BaseContainer>
  );
};

export default Projects;
