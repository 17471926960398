import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import AuthContext from "../../../context/AuthProvider";
import Layout from "../../../components/common/layout";
import CustomSelect from "../../../components/common/CustomSelect";
import CustomButton from "../../../components/custom-button/custom-button";
import { User2 } from "../../../components/iconly-icons";

const accountOptions = [
  { value: "vendor", label: "Vendor Account" },
  { value: "customer", label: "Customer Account" },
];

export default function AccountType(props) {
  const [accountType, setAccountType] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (accountType === "") toast.error("Please select an account type");
    else navigate("/signup", { state: { accountType } });
  };

  return (
    <>
      <Layout>
        <div>
          <p className="text-end">
            Already Have An Account?
            <Link to="/signin" className="text-theme ms-2">
              Log In
            </Link>
          </p>
          <div className="py-4">
            <h2 className="fw-bolder mb-5">
              What type of account do you want?
            </h2>
            <CustomSelect
              className={"SelectOption"}
              label="Account Type"
              labelClass="PersonalText LA"
              iconImage={<User2 />}
              options={accountOptions}
              placeholder="Select Account Type"
              name="account"
              onChange={(e) => setAccountType(e.target.value)}
            />
            <p className="small">
              {" "}
              This account type works for individuals and and business looking
              to hire vendors on the platform.{" "}
            </p>
          </div>
          <CustomButton onClick={handleSubmit}>Continue</CustomButton>
        </div>
      </Layout>
    </>
  );
}
