import React from "react";
import { Link } from "react-router-dom";

import CustomButton from "../../components/custom-button/custom-button";
// import { CircularLoading } from "../../components/loading/CircularLoading";
import { BaseContainer } from "../../components/dashboard/Containers";

const CustomerPayment = () => (
  <BaseContainer>
    <div className="Header header2 bg-dark">
      <h1>Cards and Payments</h1>
    </div>
    <div className="VCDiv h-75">
      <div
        className="d-flex justify-content-center"
        style={{ alignItems: "center", marginTop: "15%" }}
      >
        <Link to="">
          <CustomButton type="submit">
            Proceed To Make Payment
            {/* {isSubmitting ? <CircularLoading /> : "Proceed To Make Payment"} */}
          </CustomButton>
        </Link>
      </div>
    </div>
  </BaseContainer>
);
export default CustomerPayment;
