import React from "react";

const CustomButton = ({ children, ...props }) => (
  <div className="d-grid gap-2">
    <button className="btn btn-theme btn-block py-3  fw-bold" {...props}>
      {children}
    </button>
  </div>
);

export default CustomButton;
