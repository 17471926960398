import React, { useState } from "react";

// import DashboardLayout from "../../components/dashboard/dashboardlayout";
import { BaseContainer } from "../../../components/dashboard/Containers";
// import VendorsCategory from "../../../components/dashboard/customer-dashboard/home/vendorscategory";
// import Inspirations from "../../../components/dashboard/customer-dashboard/home/inspirations";

// import VendorSearch from "../../../components/dashboard/customer-dashboard/home/vendorsearch";
// import EditProfile from "../../../components/dashboard/customer-dashboard/home/editprofile";
import { MainDashboard } from "../../../components/dashboard/customer-dashboard/home/MainDashboard";
// import { VendorProfile } from "../../../components/dashboard/customer-dashboard/home/vendorprofile";

const CustomerDashboard = () => {
  const [search, setSearch] = useState("");
  return (
    <>
      <BaseContainer>
        <MainDashboard search={search} setSearch={setSearch} />
        {/*  
        <VendorSearch search={search} setSearch={setSearch} />s
        */}
        {/* <EditProfile /> */}
        {/* <VendorProfile /> */}
      </BaseContainer>
    </>
  );
};
export default CustomerDashboard;
