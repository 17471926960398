import React, { useEffect, useState } from "react";
import { Col, Container, Row, Placeholder } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllInspirations } from "../../../../services/customer/Inspirationboard";
import { EmptyState } from "../../../empty_states/empty";
// import { EmptyBoard } from "../Inspiration/EmptyBoard";
import { HomeInspirationCarousel } from "../Inspiration/HomeInspirationCarousel";

const Inspirations = () => {
  const [loading, setloading] = useState(false);
  const [inspirations, setInspirations] = useState([]);
  const [homeInspirationcarousel, setHomeInspirationCarousel] = useState(false);
  const [inspirationToShow, setInspirationToShow] = useState([]);

  useEffect(() => {
    getInspirations();
  }, []);

  const getInspirations = async () => {
    setloading(true);
    try {
      const response = await getAllInspirations();
      setInspirations(response.data.data.results);
    } catch (e) {
      console.log(e);
    }
    setloading(false);
  };

  const placeholderArray = [1, 2, 3, 4, 5, 6, 7, 8];
  const placeholder = placeholderArray.map((item, index) => {
    return (
      <Placeholder
        lg={3}
        md={4}
        xs={6}
        animation="glow"
        className="mb-0 mt-2"
        key={index}
      >
        <Placeholder style={{ height: 250 }} className="rounded w-100 mb-4 xs-180" />
      </Placeholder>
    );
  });

  console.log(loading);
  return (
    <div className="VCDiv  VendorScroll">
      {/* {inspiration.length === 0 && (
        <div style={{ marginTop: "8%" }}>
          <EmptyBoard
            heading="No inspirations yet"
            message="You haven't created any inspiration board."
            style={{ marginTop: "20%" }}
          />
        </div>
      )} */}

      <Container fluid className="text-center mb-3 px-0 px-md-5">
        <Row className="gx-2 gy-2 y-scroll vendor-categories">
          {loading
            ? placeholder
            : inspirations.length > 0 ?
              inspirations.map((inspiration, index) => {
                const link = `/board/view/${inspiration._id}/${inspiration.name}`;
                return (
                  <Col
                    key={`inspiration-${index}`}
                    xs={6}
                    md={4}
                    lg={3}
                    className="rounded position-relative overflow-hidden mb-4"
                    style={{
                      overflow: "hidden",
                      height: "fit-content",
                    }}
                    onClick={() => {
                      setHomeInspirationCarousel(true);
                      setInspirationToShow(inspiration);
                    }}
                  >
                    <Link to={link}>
                      <img
                        src={
                          inspiration.images.length > 0
                            ? inspiration.images[0]
                            : "https://via.placeholder.com/150"
                        }
                        style={{
                          height: 250,
                          width: "100%",
                        }}
                        className="rounded xs-180"
                        alt="vendorsInspirations"
                      />
                    </Link>
                  </Col>
                );
              }) : <EmptyState />}
        </Row>
      </Container>

      {/* {inspirations.length === 0 && (
        <div style={{ marginTop: "8%" }}>
          <EmptyBoard
            heading="No inspirations yet"
            message="You haven't created any inspiration board."
            style={{ marginTop: "20%" }}
          />
        </div>
      )} */}

      <HomeInspirationCarousel
        show={homeInspirationcarousel}
        // id={id}
        inspirationToShow={inspirationToShow}
        handleClose={() => setHomeInspirationCarousel(false)}
      />
    </div>
  );
};
export default Inspirations;
