import React from "react";
import { Card, Placeholder } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatTime } from "../../../../utils/timeFormatter";
import { EmptyBoard } from "./EmptyBoard";

const CreateInspiration = ({
  inspirations,
  edit,
  setEdit,
  toDelete,
  setDelete,
  isLoading,
}) => {
  const placeholderArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const placeholder = placeholderArray.map((item) => {
    return (
      <Card className="border-0 mx-3 mb-3" key={item}>
        <Card.Body style={{ height: "250px" }}>
          <Placeholder as={Card.Title} animation="glow" className="mb-0 h-75">
            <Placeholder xs={12} className="h-100 rounded" />
          </Placeholder>
          <Placeholder as={Card.Title} animation="glow" className="mb-0 mt-2">
            <Placeholder xs={4} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow" className="mb-0">
            <Placeholder xs={6} />
          </Placeholder>
        </Card.Body>
      </Card>
    );
  });

  return (
    <div>
      <div className="container-fluid">
        {isLoading ? (
          <div className="grid-4 grid-lg-3 mb-3 grid-sm-1">{placeholder}</div>
        ) : inspirations.length > 0 ? (
          <div className="grid-4 grid-lg-3 mb-3 grid-sm-1">
            {inspirations.map((inspiration, index) => {
              const { name, createdAt, _id, images } = inspiration;
              const time = formatTime(createdAt);
              return (
                <>
                  <Card
                    className="border-0 d-none d-md-block mx-3 border-top-radius mb-3"
                    key={index}
                  >
                    <Card.Body style={{ height: "250px" }} className="p-0">
                      <Link
                        to={`/board/view/${_id}/${name}`}
                        className="no-underline text-dark"
                      >
                        <img
                          src={
                            images !== undefined && images[0]
                              ? images[0]
                              : "https://via.placeholder.com/150"
                          }
                          alt="card"
                          width="100%"
                          className="h-75 border-top-radius"
                        />
                      </Link>
                      <div className="d-flex justify-content-between align-items-center mt-2 px-3">
                        <div>
                          <div>
                            <b>{name}</b>
                          </div>
                          <div className="text-muted text-small">
                            Created {time}
                          </div>
                        </div>
                        <span className="big-icons">
                          <i
                            className="iconly-Edit-Square icli text-color me-2 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEdit({ ...edit, name, id: _id, show: true });
                            }}
                          ></i>
                          <i
                            className="iconly-Delete icli text-danger cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDelete({
                                ...toDelete,
                                name,
                                id: _id,
                                show: true,
                              });
                            }}
                          ></i>
                        </span>
                      </div>
                    </Card.Body>
                  </Card>

                  <Card className="border-0 d-md-none mb-3">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <Link
                        to={`/board/view/${_id}/${name}`}
                        className="no-underline text-dark"
                      >
                        <div className="d-flex">
                          <img
                            src={
                              images !== undefined && images[0]
                                ? images[0]
                                : "https://via.placeholder.com/150"
                            }
                            alt="card"
                            width="75px"
                            height="75px"
                          />
                          <div className="ms-3">
                            <div className="mt-3">
                              <b>{name}</b>
                            </div>
                            <div>
                              <span className="text-muted text-small">
                                Created {time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>

                      <div className="big-icons">
                        <i
                          className="iconly-Edit-Square icli text-color me-2 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEdit({ ...edit, name, id: _id, show: true });
                          }}
                        ></i>
                        <i
                          className="iconly-Delete icli text-danger cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDelete({
                              ...toDelete,
                              name,
                              id: _id,
                              show: true,
                            });
                          }}
                        ></i>
                      </div>
                    </Card.Body>
                  </Card>
                </>
              );
            })}
          </div>
        ) : (
          <div className="w-100 text-center">
            <EmptyBoard
              header="No Boards Created"
              footer="You haven't created any boards yet. Create one now"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default CreateInspiration;
