import { Api } from "../../../axiosInstance";
import Cookie from "universal-cookie";

const cookie = new Cookie();
const jwt = cookie.get("jwt");

export const createInspirationBoard = async (name) => {
  const response = await Api.post(
    `/boards`,
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const editInspirationBoard = async (name, boardId) => {
  const response = await Api.put(
    `/boards/edit/${boardId}`,
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const removeFromInspirationBoard = async (boardId, imageUrl) => {
  const response = await Api.put(
    `/boards/${boardId}`,
    {
      imageUrl,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const getPosts = async (page, limit) => {
  const response = await Api.get(`/boards/posts?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const getAllInspirations = async () => {
  const response = await Api.get("/boards", {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const addToInspirationBoard = async (boardId, images) => {
  const response = await Api.post(
    "/boards/add",
    { boardId, images },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const getUsersInspirationBoard = async (page, limit) => {
  const response = await Api.get(`/boards?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const fetchVendorsProfile = async (vendorId) => {
  const response = await Api.get(`/vendors/profile/${vendorId}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const deleteInspirationBoard = async (id) => {
  const response = await Api.delete(`/boards/${id}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const editProfile = async () => {
  const response = await Api.get("/user", {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const bookVendor = async () => {
  const response = await Api.post("/bookings", {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const fetchCategories = async () => {
  const response = await Api.get("/projects/get-vendor-categories", {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const fetchAllInspirationPosts = async () => {
  const response = await Api.get("/boards/posts", {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const addToBoard = async (values) => {
  const response = await Api.post("boards/add", values, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

///-------------------------------------------------Settings-------------------------------------------///
export const deactivateAccount = async (id) => {
  const response = await Api.patch(`/user/deactivate/${id}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const reportIssue = async () => {
  const response = await Api.patch("/report", {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};
