import React, { useState, useEffect } from "react";
import Cookie from "universal-cookie";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Col, Placeholder, Card } from "react-bootstrap";

import { BiSearch } from "react-icons/bi";
import Active from "../../components/dashboard/customer-dashboard/myvendors/active";
import All from "../../components/dashboard/customer-dashboard/myvendors/all";
import Favorites from "../../components/dashboard/customer-dashboard/myvendors/favorites";
import { Api } from "../../axiosInstance";
import { BaseContainer } from "../../components/dashboard/Containers";
import { NotificationsCanvas } from "../../components/dashboard/notifications_canvas";

const MyVendors = () => {
  const cookie = new Cookie();
  const jwt = cookie.get("jwt");
  const navigate = useNavigate();

  const [vendorOption, setVendorOption] = useState("all");
  const [search, setSearch] = useState("");
  const [searchedVendors, setSearchedVendors] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [activeVendors, setActiveVendors] = useState([]);
  const [favoriteVendors, setFavoriteVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  // use effect hook for fetching vendors
  useEffect(() => {
    Api.get("/vendors/my-vendors?filter=all", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((res) => {
        setAllVendors(res.data.data.results);
      })
      .catch((err) => {
        console.log(err);
      });

    Api.get("/vendors/my-vendors?filter=active", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((res) => {
        setActiveVendors(res.data.data.results);
      })
      .catch((err) => {
        console.log(err);
      });

    Api.get("/vendors/my-vendors?filter=favorites", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((res) => {
        setFavoriteVendors(res.data.data.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [jwt]);

  let vendors = [];
  if (vendorOption === "active") vendors = activeVendors;
  else if (vendorOption === "all") vendors = allVendors;
  else if (vendorOption === "favorite") vendors = favoriteVendors;

  const getVendorsBySearch = (search) => {
    setSearch(search);
    const searchedVendors = vendors.filter((vendor) => {
      return vendor.fullName.toLowerCase().includes(search.toLowerCase());
    });
    setSearchedVendors(searchedVendors);
  };

  const returnedVendors = search ? searchedVendors : vendors;
  const image = localStorage.getItem("profileImage");

  const placeholderArray = [1, 2, 3, 4, 5, 6];
  const placeholder = placeholderArray.map((item) => {
    return (
      <Card className="border-0 w-100 mb-0 rounded shadow-sm" key={item}>
        <Card.Body className="d-flex align-items-top justify-content-between">
          <div className="d-flex align-items-center">
            <Placeholder animation="glow">
              <Placeholder
                xs={4}
                style={{
                  width: "75px",
                  height: "100px",
                }}
                className="rounded"
              />
            </Placeholder>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ height: 75 }}
            >
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  className="ms-3 mb-2"
                  style={{ width: 100, height: 20 }}
                />
              </Placeholder>
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  className="ms-3"
                  style={{ width: 100, height: 10 }}
                />
              </Placeholder>
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  className="ms-3"
                  style={{ width: 100, height: 10 }}
                />
              </Placeholder>
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  className="ms-3"
                  style={{ width: 100, height: 10 }}
                />
              </Placeholder>
            </div>
          </div>
          <Placeholder as={Card.Text} animation="glow" className="m-0">
            <Placeholder xs={12} style={{ width: "50px", borderRadius: 15 }} />
          </Placeholder>
        </Card.Body>
        <Card.Footer className="bg-white mt-1 p-3 text-center d-flex justify-content-around">
          <Placeholder animation="glow" className="w-100">
            <Placeholder xs={7} />
          </Placeholder>
          <Placeholder animation="glow" className="w-100">
            <Placeholder xs={7} />
          </Placeholder>
        </Card.Footer>
      </Card>
    );
  });

  return (
    <BaseContainer>
      <Col md={9} xl={10} className="p-0">
        <div className="Header header2 bg-dark d-flex flex-column justify-content-between">
          <div className="px-4 w-100 justify-content-between align-items-center d-none d-md-flex mt-2">
            <i
              className="iconly-Arrow-Left-Circle icli caret-holder mt-2 cursor-pointer"
              style={{ fontSize: "30px" }}
              onClick={() => navigate(-1)}
            ></i>
            <b className="mt-2 fs-6 poppins-font">My Vendors</b>
            <div className="justify-content-end align-items-center d-flex mt-2">
              <button
                type="button"
                className="btn btn-transparent position-relative me-3 p-0 d-flex align-items-center"
                onClick={() => setShow(true)}
              >
                <i className="iconly-Activity icli fs-20 text-white"></i>
                {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                  9+
                  <span className="visually-hidden">unread messages</span>
                </span> */}
              </button>
              <Link to="/messagelist" className="no-underline">
                <button
                  type="button"
                  className="btn btn-transparent position-relative me-4 p-0 d-flex align-items-center"
                >
                  <i className="iconly-Chat icli fs-20 text-white"></i>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger unread-notifications d-flex align-items-center justify-content-center">
                    9+
                    <span className="visually-hidden">unread messages</span>
                  </span> */}
                </button>
              </Link>

              <img
                src={image ? image : "https://via.placeholder.com/100"}
                className="rounded-circle"
                height="35"
                width="35"
                alt="account"
              />
            </div>
          </div>
          <div className="w-100 d-none d-md-flex flex-column align-items-center">
            <div className="input-group bg-white w-50 searchbar">
              <span
                className="input-group-text text-white no-border vw-50 bg-white"
                id="search-addon"
              >
                <BiSearch />
              </span>
              <input
                type="search"
                className="form-control text-white border shadow-sm text-white no-border bg-white text-capitalize"
                placeholder="Search for a Vendor"
                aria-label="Search"
                aria-describedby="search-addon"
                onChange={(e) => getVendorsBySearch(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex d-md-none h-33"></div>
          <div className="d-flex d-md-none align-items-center px-4 w-100">
            <i
              className="iconly-Arrow-Left-Circle icli caret-holder mt-2 cursor-pointer me-3"
              style={{ fontSize: "30px" }}
              onClick={() => navigate(-1)}
            ></i>
            <b className="mt-2 fs-6 poppins-font">My Vendors</b>
          </div>

          <div className="d-flex justify-content-center">
            <div
              className={`p-4 w-1/2 nav-link-class Vendors ${
                vendorOption === "all"
                  ? "text-white border-bottom-white border-white font-weight-bold "
                  : ""
              } text-gray-400`}
              checked={vendorOption === "all"}
              onClick={() => setVendorOption("all")}
            >
              All
            </div>
            <div
              className={`p-4 w-1/2 nav-link-class Vendors  ${
                vendorOption === "active"
                  ? "text-white border-bottom-white border-white font-weight-bold"
                  : ""
              } text-gray-400`}
              checked={vendorOption === "active"}
              onClick={() => setVendorOption("active")}
            >
              Active
            </div>
            <div
              className={`p-4 w-1/2 nav-link-class Vendors  ${
                vendorOption === "favorite"
                  ? "text-white border-bottom-white border-white font-weight-bold"
                  : ""
              } text-gray-400`}
              checked={vendorOption === "favorite"}
              onClick={() => setVendorOption("favorite")}
            >
              Favourites
            </div>
          </div>
        </div>

        <div className="px-3 px-md-4 VCDiv vh-75 y-scroll vh-xs-65">
          {isLoading ? (
            <div className="justify-content-center d-flex">
              <div className="w-90 grid-template-columns-2 w-sm-100">
                {placeholder}
              </div>
            </div>
          ) : (
            <div>
              {vendorOption === "all" ? (
                <All vendors={returnedVendors} />
              ) : null}
              {vendorOption === "active" ? (
                <Active vendors={returnedVendors} />
              ) : null}
              {vendorOption === "favorite" ? (
                <Favorites vendors={returnedVendors} />
              ) : null}
            </div>
          )}
        </div>
        <Col className="p-0 d-md-none vh-10 w-100" xl={2} md={3} id="side-bar">
          <ul className="nav d-flex justify-content-around align-items-center h-100">
            <li className="nav-item">
              <NavLink
                to="/dashboard"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i className={`iconly-Home icli fs-3 align-middle`}></i>
                </div>
                <span className="align-middle text-smaller">Home</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/messagelist"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i className={`iconly-Chat icli fs-3 align-middle`}></i>
                </div>
                <span className="align-middle text-smaller">Messages</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/myvendors"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i
                    className="iconly-User3 icli fs-3 align-middle"
                    aria-hidden="true"
                  ></i>
                </div>
                <span className="align-middle text-smaller">My Vendors</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/profile"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i
                    className="fa fa-user-o w-100 fs-5 align-middle h-100 mb-2px"
                    aria-hidden="true"
                  ></i>
                </div>
                <span className="align-middle text-smaller">Profile</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/settings"
                className="nav-link text-dark"
                aria-current="page"
              >
                <div className="text-center">
                  <i className={`iconly-Setting icli fs-3 align-middle`}></i>
                </div>
                <span className="align-middle text-smaller">Settings</span>
              </NavLink>
            </li>
          </ul>
        </Col>
        <NotificationsCanvas show={show} handleClose={handleClose} />
      </Col>
    </BaseContainer>
  );
};
export default MyVendors;
