import _axios from "axios";
import Cookies from "universal-cookie";
// import { baseURL } from "./http-common";

const baseURL = process.env["REACT_APP_API_URL"];

const instance = _axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (request) => {
    const cookies = new Cookies();
    const jwt = cookies.get("jwt");
    if (request.headers) {
      request.headers.Authorization = `Bearer ${jwt}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

const handleApiSuccess = (res) => {
  return res;
};

const handleApiError = (err) => {
  let errorMessage = "";
  // request was manually cancelled in a `useEffect` hook
  if (_axios.isCancel(err)) {
    return; // fail silently
  }

  // listen for 401 unauthorized response and expired jwt to bounce the user back to the login page
  // if (
  //   err.response.data.message === "jwt expired" ||
  //   err.response.data.message !== "Please sign in again to continue"
  // ) {
  //   window.location.href = "/signin?role=customer";
  //   document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // }

  if (err.response) {
    const apiError = err.response.data;
    // client received an error response (5xx, 4xx)
    console.error(
      `Server returned code ${err.response.status}` +
        `body was: ${apiError.message}`,
      "data:",
      apiError
    );
    errorMessage = apiError.message;
  } else if (err.request) {
    // client never received a response, or request never left
    console.error("An error occurred:", err.message);
  } else {
    // anything else
    console.error("Well, that was unexpected");
  }

  return errorMessage;
  // throw Error(
  //   errorMessage ||
  //     "We couldn't complete your request. Please try again or check your internet connection."
  // );
};

export const Api = {
  get: (endpoint, config) =>
    instance.get(endpoint, config).then(handleApiSuccess).catch(handleApiError),

  post: (endpoint, data, config) =>
    instance
      .post(endpoint, data, config)
      .then(handleApiSuccess)
      .catch(handleApiError),

  put: (endpoint, data, config) =>
    instance
      .put(endpoint, data, config)
      .then(handleApiSuccess)
      .catch(handleApiError),

  patch: (endpoint, data, config) =>
    instance
      .patch(endpoint, data, config)
      .then(handleApiSuccess)
      .catch(handleApiError),

  delete: (endpoint, config) =>
    instance
      .delete(endpoint, config)
      .then(handleApiSuccess)
      .catch(handleApiError),
};
