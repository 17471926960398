import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";

export const PlusIcon = ({ onClick }) => {
  return (
    <div className="mb-4">
      <div>
        {" "}
        <AiFillPlusCircle className="plusIcon nextButton" onClick={onClick} />
      </div>
    </div>
  );
};
