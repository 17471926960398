import React from "react";

export default function CustomSelect({
  options,
  className,
  placeholder,
  label,
  iconImage,
  ...props
}) {
  // return(
  //     <div className={className}>
  //         <Select
  //             {...props}
  //             options={options}
  //             isSearchable={false}
  //             placeholder={placeholder}
  //          />
  //     </div>
  // )

  return (
    <React.Fragment>
      <label htmlFor={props.id || props.name} className="fs-14 mb-2">
        {label}
      </label>
      <div className="input-group mb-3">
        <span className="input-group-text input-group-text-0 ">
          {iconImage}
        </span>
        <select
          className="form-select form-select-0 ps-3 py-3 border-start-0"
          defaultValue=""
          name={props.name}
          required
          onChange={props.onChange}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {options &&
            options.length &&
            options.map((v) => <option value={v.value}>{v.label}</option>)}
        </select>
      </div>
    </React.Fragment>
  );
}
